<template>
  <div>
    <h3>Lista Interessati Esterni</h3>
    <table class="table table-striped dataTable" ref="tableInteressati">
      <thead>
        <tr>
          <th>Nome e Cognome</th>
          <th>Email</th>
          <th>Telefono</th>
          <th>Cellulare</th>
          <th>Data richiesta</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <aggiungi-interessato-esterno :token="token" :id_annuncio="id_annuncio" @aggiunto="updateInteressati()"></aggiungi-interessato-esterno>
  </div>
</template>
<script>
export default {
  props: ['token', 'id_annuncio'],
  data () {
    return {
      interessati: null,
      tableInteressati: null,
    };
  },
  mounted () {
    $.fn.dataTable.moment('DD-MM-YYYY');
    this.tableInteressati = $(this.$refs.tableInteressati).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row.px-2"<"#buttons.col-sm-6 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-6 col-12"f><"#pagination.col-sm-6 offset-sm-6 float-right col-12 mt-sm-1"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis','csv','excel'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.updateInteressati();
  },
  methods: {
    updateInteressati () {
      $.get(`/annuncio/${this.id_annuncio}/interessatiEsterni/json`, function (response) {
        this.interessati = response.interessati;
        this.updateTableInteressati();
      }.bind(this));
    },
    updateTableInteressati () {
      this.tableInteressati.clear();
      this.interessati.forEach(function (interessato) {
        this.tableInteressati.row.add([
          `${interessato.name} ${interessato.cognome}`,
          interessato.email,
          interessato.telefono,
          interessato.cellulare,
          moment(String(interessato.created_at)).format('DD-MM-YYYY'),
          `<button class="btn bg-primary rimuovi" data-id="${interessato.id}">Rimuovi</button>`,
        ]);
      }.bind(this));
      this.tableInteressati.draw();
      $('button.rimuovi').on('click', function (e) {
        this.rimuovi($(e.target).data('id'));
      }.bind(this));
    },
    rimuovi (id) {
      var data = {
        id_esterno: id,
        _token: this.token,
      };
      $.post(`/annuncio/${this.id_annuncio}/rimuoviInteressatoEsterno`, data).always(function () {
        this.updateInteressati();
      }.bind(this));
    },
  },
}
</script>
