<template>
  <div class="container">
    <div class="modal fade" role="dialog" aria-hidden="true" ref="modalCaricamento">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Caricamento</h5>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center">
              <div class="spinner-grow text-primary ml-auto mr-auto" role="status">
                <span class="sr-only">Caricamento</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <h1 class="col-12">Import Affitti</h1>
      <div class="col-12 col-sm-4">
        <label for="file">Seleziona un file</label>
        <input type="file" ref="fileImport" >
      </div>
      <div class="col-12 col-sm-4">
        <label for="importbutton">Conferma importazione</label>
        <button class="form-control btn btn-primary" :class="{ 'bg-success': importOk }" @click="importa()">Importa</button>
      </div>
      <div class="col-12 col-sm-4">
        <label for="send">Invia al server</label>
        <button type="button" class="form-control btn btn-primary" @click="invia()" :disabled="!inviaPronto">Invia</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="errors.length > 0">
        <h2>Errori riscontrati:</h2>
        <table class="table table-striped dataTable">
          <thead>
            <tr>
              <th>Colonna</th>
              <th>Descrizione Errore</th>
              <th>Numero Documento</th>
              <th>Codice Fiscale</th>
              <th>Data</th>
              <th>Imponibile IVA</th>
              <th>Imposta IVA</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="error, i in errors" :key="i">
              <td>{{ error.row+1 }}</td>
              <td class="text-left">{{ error.error }}</td>
              <td>{{ data[error.row].numero_documento }}</td>
              <td>{{ data[error.row].codice_fiscale }}</td>
              <td>{{ data[error.row].data }}</td>
              <td>{{ data[error.row].imponibile_iva }}</td>
              <td>{{ data[error.row].imposta_iva }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['token'],
  data () {
    return {
      errors: [],
      fr: null,
      privFileData: null,
      data: {},
      importOk: false,
    };
  },
  methods: {
    importa () {
      var file = $(this.$refs.fileImport)[0].files[0];
      this.fr = new FileReader();
      this.fr.onload = this.fileLoaded;
      this.fr.readAsBinaryString(file);
    },
    fileLoaded () {
      var table = XLSX.read(this.fr.result, {type:'binary'});
      var sheet = table.Sheets[table.SheetNames[0]];
      this.privFileData = XLSX.utils.sheet_to_json(sheet, {header:1, raw: false});
      this.parse();
    },
    parse () {
      var file = this.privFileData.slice(4);
      this.data = {};
      file.forEach(function (row) {
        var numero_documento = row[5];
        if (this.data[numero_documento] === undefined)
          this.data[numero_documento] = {numero_documento: numero_documento};
        var date = row[4];
        if (date !== undefined) {
          // var strdate = moment(date, 'MM/DD/YY').format('YYYY-MM-DD');
          var strdate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
          if (strdate == 'Invalid date')
            console.log(date);
          this.data[numero_documento]['data'] = strdate;
        }
        var imponibile = row[6];
        if (imponibile !== undefined)
          this.data[numero_documento]['imponibile_iva'] = imponibile;
        var imposta = row[7];
        if (imposta !== undefined)
          this.data[numero_documento]['imposta_iva'] = imposta;
        var codice_fiscale = row[9];
        if (codice_fiscale !== undefined)
          this.data[numero_documento]['codice_fiscale'] = codice_fiscale;

      }.bind(this));
      this.data = Object.entries(this.data).map((x) => x[1]);
      this.importOk = true;
    },
    invia () {
      var data = {
        fatture: JSON.stringify(this.data),
        _token: this.token,
      };
      this.apriModaleCaricamento();
      $.post('/import/fatture', data, function (response) {
        this.chiudiModaleCaricamento();
        window.location.href = "/pagamento/lista";
      }.bind(this)).fail(function (response) {
        this.errors = response.responseJSON.errors;
        this.chiudiModaleCaricamento();
      }.bind(this));
    },
    apriModaleCaricamento () {
      $(this.$refs.modalCaricamento).modal({backdrop: 'static', keyboard: false});
    },
    chiudiModaleCaricamento () {
      $(this.$refs.modalCaricamento).modal('hide');
    },
  },
  computed: {
    inviaPronto () {
      return this.importOk && this.condominio !== null;
    },
  },
}
</script>
