<template>
  <div>
    <modale-caricamento ref="modaleCaricamento"></modale-caricamento>
    <h1 class="m-0 text-dark mb-3">Medie spese condominiali/appartamento</h1>
    <table class="table table-striped dataTable" id="dataTable" ref="tableMedieSpese">
      <thead>
        <tr>
          <th>Condominio</th>
          <th>Gennaio</th>
          <th>Febbraio</th>
          <th>Marzo</th>
          <th>Aprile</th>
          <th>Maggio</th>
          <th>Giugno</th>
          <th>Luglio</th>
          <th>Agosto</th>
          <th>Settembre</th>
          <th>Ottobre</th>
          <th>Novembre</th>
          <th>Dicembre</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <h1 class="m-0 text-dark mb-3">Medie affitti/appartamento</h1>
    <table class="table table-striped dataTable" id="dataTable" ref="tableMedieAffitti">
      <thead>
        <tr>
          <th>Condominio</th>
          <th>Gennaio</th>
          <th>Febbraio</th>
          <th>Marzo</th>
          <th>Aprile</th>
          <th>Maggio</th>
          <th>Giugno</th>
          <th>Luglio</th>
          <th>Agosto</th>
          <th>Settembre</th>
          <th>Ottobre</th>
          <th>Novembre</th>
          <th>Dicembre</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>

    <h1 class="m-0 text-dark mb-3">Affitto medio annuo/mq</h1>
    <table class="table table-striped dataTable" id="dataTable" ref="tableAnnuo">
      <thead>
        <tr>
          <th>Condominio</th>
          <th>Spese/mq</th>
          <th>Affiti/mq</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      anno: moment().year(),
      mesi_spese: null,
      mesi_affitti: null,
      annuo_spese: null,
      annuo_affitti: null,
      tableMedieSpese: null,
      tableMedieAffitti: null,
      tableAnnuo: null,
    };
  },
  mounted () {
    this.tableMedieSpese = $(this.$refs.tableMedieSpese).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...'
        },
        dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.tableMedieAffitti = $(this.$refs.tableMedieAffitti).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...'
        },
        dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.tableAnnuo = $(this.$refs.tableAnnuo).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...'
        },
        dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.$refs.modaleCaricamento.apri();
    $.get('/statistiche/medie_generali/json', {anno: this.anno}, function (response) {
      this.mesi_spese = response.mesi_spese;
      this.mesi_affitti = response.mesi_affitti;
      this.annuo_spese = response.annuo_spese;
      this.annuo_affitti = response.annuo_affitti;
      this.$refs.modaleCaricamento.chiudi();
      this.updateTableMedie();
    }.bind(this));
  },
  methods: {
    updateTableMedie () {
      this.tableMedieSpese.clear();
      Object.keys(this.mesi_spese).forEach(function (key) {
        var record = this.mesi_spese[key];

        var linkCondominio = '';
        if (record.condominio)
          linkCondominio = `
          <a href="/condominio/${record.condominio.id}/singolo" target="_blank">${record.condominio.nome}</a>
          `;
        else
          linkCondominio = 'Generale';
        var row = [linkCondominio];
        for (var i = 1; i <= 12; i++) {
          row.push(Number(record[i]).toFixed(2) + '€');
        }
        this.tableMedieSpese.row.add(row);
      }.bind(this));
      this.tableMedieSpese.draw();

      this.tableMedieAffitti.clear();
      Object.keys(this.mesi_affitti).forEach(function (key) {
        var record = this.mesi_affitti[key];

        var linkCondominio = '';
        if (record.condominio)
          linkCondominio = `
          <a href="/condominio/${record.condominio.id}/singolo" target="_blank">${record.condominio.nome}</a>
          `;
        else
          linkCondominio = 'Generale';
        var row = [linkCondominio];
        for (var i = 1; i <= 12; i++) {
          row.push(Number(record[i]).toFixed(2) + '€');
        }
        this.tableMedieAffitti.row.add(row);
      }.bind(this));
      this.tableMedieAffitti.draw();

      this.tableAnnuo.clear();
      Object.keys(this.annuo_spese).forEach(function (key) {
        var row_spese = this.annuo_spese[key];
        var row_affitti = this.annuo_affitti[key];

        var linkCondominio = '';
        if (row_spese.condominio)
          linkCondominio = `
          <a href="/condominio/${row_spese.condominio.id}/singolo" target="_blank">${row_spese.condominio.nome}</a>
          `;
        else
          linkCondominio = 'Generale';
        this.tableAnnuo.row.add([
          linkCondominio,
          Number(row_spese.media).toFixed(2) + '€',
          Number(row_affitti.media).toFixed(2) + '€',
        ]);
      }.bind(this));
      this.tableAnnuo.draw();
    },
  },
}
</script>
