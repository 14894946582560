<template>
  <div class="row">
    <div class="col-6">
      <label for="select-condominio">Condominio</label>
      <select id="select-condominio" class="form-control" ref="selectCondominio">
        <option></option>
      </select>
    </div>
    <div class="col-6">
      <label for="select-unita-condominiale">Unita Condominiale</label>
      <select id="select-unita-condominiale" class="form-control" ref="selectUnitaCondominiale">
        <option></option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data () {
    return {
      condomini: [],
      unitaCondominiali: [],
      condominio: 0,
      unitaCondominiale: 0,
    };
  },
  mounted () {
    $.get('/condominio/lista/json', null, function (response) {
      var data = [];
      this.condomini = response.condomini;
      this.condomini.forEach(function (condominio) {
        data.push({
          id: condominio.id,
          text: condominio.nome,
        });
      });
      $(this.$refs.selectCondominio).select2({
        data: data,
        placeholder: "Selezionare un condominio",
      });
      $(this.$refs.selectCondominio).on('select2:select', function (e) {
        console.log(e);
        this.condominioChanged();
      }.bind(this));
      $(this.$refs.selectUnitaCondominiale).select2({
        data: [],
        placeholder: "Selezionare un'unità condominiale",
      });
    }.bind(this));
  },
  methods: {
    condominioChanged () {
      this.condominio = this.$refs.selectCondominio.value;
      $.get(`/condominio/${this.condominio}/unitaCondominiali/json`, null, function (response) {
        this.unitaCondominiali = response.unitaCondominiali;
        var data = [];
        this.unitaCondominiali.forEach(function (unita) {
          data.push({
            id: unita.id,
            text: unita.nome,
          });
        });
        $(this.$refs.selectUnitaCondominiale).empty().trigger('change');
        $(this.$refs.selectUnitaCondominiale).select2({
          data: data,
          placeholder: "Selezionare un'unità condominiale",
        });
        this.unitaCondominialeChanged();
        $(this.$refs.selectUnitaCondominiale).on('select2:select', function (e) {
          this.unitaCondominialeChanged();
        }.bind(this));
      }.bind(this));
    },
    unitaCondominialeChanged () {
      this.unitaCondominiale = this.$refs.selectUnitaCondominiale.value;
    },
  },
}
</script>
