<template>
  <input name="data" type="datepicker" :disabled="disabled" ref="daterangepicker">
</template>

<script>
export default {
  props: ['value', 'disabled', 'custom_label', 'custom_class'],
  data () {
    return {
    };
  },
  mounted () {
    this.initialize();
  },
  methods: {
    initialize () {
      var options = {
        singleDatePicker: true,
        showDropdowns: true,
        autoApply: true,
        locale: {
          format: 'DD-MM-YYYY'
        },
        //minYear: 2020,
        //maxYear: parseInt(moment().format('YYYY'),10)
      };
      if (this.value) {
        var itaDate = moment(String(this.value)).format('DD-MM-YYYY');
        options['startDate'] = itaDate;
      } else {
        options['autoUpdateInput'] = false;
      }

      var dr = this.$refs.daterangepicker;
      $(this.$refs.daterangepicker).daterangepicker(options, function (start, end, label) {
        var d = end.toISOString().slice(0,10);
        var itaDate = moment(String(d)).format('DD-MM-YYYY');
        $(dr).val(itaDate);
        this.$emit('input', d);
        /*
        $(dr).val(d);
        this.$emit('input', d);
        */
        // var years = moment().diff(start, 'years');
        //alert("You are " + years + " years old!");
      }.bind(this));
    },
    reset () {
      $(this.$refs.daterangepicker).val('');
    },
    setValue (value) {
      var d = moment(String(value)).format('DD-MM-YYYY')
      // $(this.$refs.daterangepicker).setStartDate(d);
      $(this.$refs.daterangepicker).val(d);
    },
  },
  computed: {
    clabel () {
      if (this.custom_label)
        return this.custom_label;
      return 'Data:';
    },
    cclass () {
      if (this.custom_class)
        return this.custom_class;
      return "col-12 col-sm-4";
    },
  },
}
</script>
