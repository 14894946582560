<template>
  <div>
    <div class="modal fade" role="dialog" aria-hidden="true" ref="modale">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Aggiunti Utente Interessato</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Chiudi">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <label>Selezionare un utente:</label>
                <select :class="formInputClass('id_utente')" ref="utenteSelect" >
                  <option></option>
                </select>
                <span class="invalid-feedback" role="alert" v-if="errors['id_utente'] !== undefined">
                  <strong>{{ errors['id_utente'][0] }}</strong>
                </span>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                <button type="button" class="btn bg-primary" @click="aggiungi()">Aggiungi</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn bg-primary w-100" @click="apriModale()">Aggiungi Utente Interno Interessato</button>
  </div>
</template>

<script>
export default {
  props: ['token', 'id_annuncio'],
  data () {
    return {
      utenti: null,
      errors: {},
    };
  },
  mounted () {
    $.get('/utente/lista/json', function (response) {
      this.utenti = response.utenti;
      var data = [];
      this.utenti.forEach(function (u) {
        data.push({
          id: u.id,
          text: u.preferred_name,
        });
      });
      $(this.$refs.utenteSelect).select2({
        data: data,
        placeholder: 'Selezionare un utente',
      });
    }.bind(this));
  },
  methods: {
    apriModale () {
      $(this.$refs.modale).modal('show');
    },
    aggiungi () {
      var utente = this.$refs.utenteSelect.value;
      var data = {
        id_utente: utente,
        _token: this.token,
      };
      $.post(`/annuncio/${this.id_annuncio}/interessatoAmministratore`, data, function () {
        $(this.$refs.modale).modal('hide');
        this.$emit('aggiunto');
      }.bind(this)).fail(function (response) {
        this.errors = response.responseJSON.errors;
      }.bind(this));

    },
    formInputClass (attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
  },
}
</script>
