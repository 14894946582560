<template>
  <div>
    <table class="table dataTable" id="dataTable" ref="tableAvvisi">
      <thead>
        <!--{{--FOREACH FATTURA NON PAGATA O PAGATA PARZIALMENTE, SOLO FATTURE AFFITTO--}}-->
        <tr>
          <th>Condominio</th>
          <th>Unità Immobiliare</th>
          <th>Conduttore</th>
          <th>Importo Complessivo</th>
          <!--{{--Somma fatture non pagate / importo da pagare restante --}}-->
          <th>N° Fatture</th>
          <!--{{--Affitti--}}-->
          <th>Giorni da ultimo pagamento</th>
          <th>Saldo Locazione</th>
          <th>Data Saldo Locazione</th>
          <th>Saldo Spese Condominiali</th>
          <th>Data Saldo Spese Condominiali</th>
          <!--{{--Giorni trascorsi dall'Ultima fattura pagata--}}-->
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <table class="table">
      <thead>
        <tr>
          <th>Totale</th>
          <th>Numero totale fatture</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ totale.toFixed(2) }}€</td>
          <td>{{ totale_fatture }}</td>
        </tr>
      </tbody>
    </table>
    <div>
      <canvas id="myChart" width="400" height="100"></canvas>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableAvvisi: null,
      totale: 0,
      totale_fatture: 0,
      morosi: {},
    };
  },
  mounted () {
    this.tableAvvisi = $(this.$refs.tableAvvisi).DataTable( {
      language: {
        url: '/js/dataTables/Italian.json',
        searchPlaceholder: 'Cerca...',
        buttons: { colvis: 'Colonne'}
      },
      dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
      buttons: ['colvis','excel','csv'], //Buttons
      "responsive": true,
      "ordering": true,
      "searching": true,
      "bLengthChange": false,
      "pageLength": 50
    });
    this.tableAvvisi.on('search.dt', function () {
      this.updateTotali();
    }.bind(this));
    $.get('/statistiche/morosita_condominio/json', function (response) {
      this.morosi = response.utenti;
      this.updateTable();
    }.bind(this));
  },
  methods: {
    updateTable () {
      this.tableAvvisi.clear();
      this.morosi.forEach(function (m) {
        var linkCondominio = '';
        if (m.condominio)
          linkCondominio = `
            <a href="/condominio/${m.condominio.id}/singolo" target="_blank">${ m.condominio.nome }</a>
          `;

        var linkUnita = '';
        if (m.unita)
          linkUnita = `
            <a href="/unitaCondominiale/${m.unita.id}/singolo" target="_blank">${ m.unita.nome }</a>
          `;
        var linkUtente = `
          <a href="/utente/${m.utente.id}/singolo" target="_blank">${m.utente.preferred_name}</a>
        `;
        this.tableAvvisi.row.add([
          linkCondominio,
          linkUnita,
          linkUtente,
          Number(m.importo_non_pagato).toFixed(2)+"€",
          m.rate_non_pagate,
          m.giorni_ultimo_pagamento,
          m.utente.saldo_locazione ? parseFloat(m.utente.saldo_locazione).toFixed(2)+"€" : '',
          m.utente.data_saldo_locazione ? moment(String(m.utente.data_saldo_locazione)).format('DD-MM-YYYY') : '',
          m.utente.saldo_spese_condominiali ? parseFloat(m.utente.saldo_spese_condominiali).toFixed(2)+"€" : '',
          m.utente.data_saldo_spese_condominiali ? moment(String(m.utente.data_saldo_spese_condominiali)).format('DD-MM-YYYY') : '',
        ]);
      }.bind(this));
      this.tableAvvisi.draw();
    },
    updateTotali () {
      var rows = this.tableAvvisi.rows({filter:'applied'}).data();
      var totale = 0;
      var totale_fatture = 0;
      rows.each(function (row) {
        totale += Number(row[3].substring(0, row[3].length-1));
        totale_fatture += Number(row[4]);
      }.bind(this));

      this.totale = totale;
      this.totale_fatture = totale_fatture;
    },
  },
}
</script>
