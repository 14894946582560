<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm">
        <button class="btn btn-secondary w-100 mb-2" :class="{ 'selected': filtro == -1 }" @click="filtra(-1)">Tutti</button>
      </div>
      <div class="col-12 col-sm">
        <button class="btn btn-secondary w-100 mb-2" :class="{ 'selected': filtro == 0 }" @click="filtra(0)">0-30</button>
      </div>
      <div class="col-12 col-sm">
        <button class="btn btn-secondary w-100 mb-2" :class="{ 'selected': filtro == 30 }" @click="filtra(30)">30-60</button>
      </div>
      <div class="col-12 col-sm">
        <button class="btn btn-secondary w-100 mb-2" :class="{ 'selected': filtro == 60 }" @click="filtra(60)">60-90</button>
      </div>
      <div class="col-12 col-sm">
        <button class="btn btn-secondary w-100 mb-2" :class="{ 'selected': filtro == 90 }" @click="filtra(90)">90-120</button>
      </div>
      <div class="col-12 col-sm">
        <button class="btn btn-secondary w-100 mb-2" :class="{ 'selected': filtro == 120 }" @click="filtra(120)">120-150</button>
      </div>
      <div class="col-12 col-sm">
        <button class="btn btn-secondary w-100 mb-2" :class="{ 'selected': filtro == 150 }" @click="filtra(150)">150-180</button>
      </div>
      <div class="col-12 col-sm">
        <button class="btn btn-secondary w-100 mb-2" :class="{ 'selected': filtro == 180 }" @click="filtra(180)">180+</button>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label for="">Data di inizio</label>
        <daterange-picker v-model="data_inizio"></daterange-picker>
      </div>
      <div class="col-6">
        <label for="">Data di fine</label>
        <daterange-picker v-model="data_fine"></daterange-picker>
      </div>
    </div>
    <h5 class="mt-4" v-if="filter == 'spese_condominio'">Lista spese condominiali scadute</h5>
    <h5 class="mt-4" v-else>Lista affitti scaduti</h5>
    <morosita-conduttore ref="morositaConduttore" :filter="filter"></morosita-conduttore>
    <h5 class="mt-4">Totale affitti e spese condominiali non pagati</h5>
    <morosita-conduttore-complessivo ref="morositaConduttoreComplessivo" :filter="filter"></morosita-conduttore-complessivo>
  </div>
</template>

<script>
export default {
  props: ['filter'],
  data () {
    return {
      filtro: -1,
      data_inizio: null,
      data_fine: null,
    };
  },
  mounted () {
  },
  methods: {
    filtra (giorni) {
      this.filtro = giorni;
      this.$refs.morositaConduttore.filtra(giorni);
      this.$refs.morositaConduttoreComplessivo.filtra(giorni);
    },
  },
  watch: {
    data_inizio () {
      this.$refs.morositaConduttore.data_inizio = this.data_inizio;
      this.$refs.morositaConduttore.page = 0;
      this.$refs.morositaConduttore.updateConduttori();

      this.$refs.morositaConduttoreComplessivo.data_inizio = this.data_inizio;
      this.$refs.morositaConduttoreComplessivo.updateConduttori();
    },
    data_fine () {
      this.$refs.morositaConduttore.data_fine = this.data_fine;
      this.$refs.morositaConduttore.page = 0;
      this.$refs.morositaConduttore.updateConduttori();

      this.$refs.morositaConduttoreComplessivo.data_fine = this.data_fine;
      this.$refs.morositaConduttoreComplessivo.updateConduttori();
    },
  },

}
</script>
