<template>
  <div class="row mb-3">
    <div class="col-12 col-sm-6">
      <label>Provincia</label>
      <select class="form-control" ref="selectProvincia">
        <option></option>
      </select>
    </div>
    <div class="col-12 col-sm-6">
      <label>Città</label>
      <select class="form-control" ref="selectCitta">
        <option></option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      province: [],
      cittas: [],
      provincia: '',
      citta: '',
    };
  },
  mounted () {
    $.get('/province/json', function (response) {
      this.province = response.province;
      var d = [];
      response.province.forEach(function (p) {
        d.push({
          id: p.id,
          text: p.nome,
        });
      });
      $(this.$refs.selectProvincia).select2({
        data: d,
        placeholder: 'Seleziona una provincia',
      });
      $(this.$refs.selectProvincia).on('select2:select', function () {
        this.provinciaChanged();
      }.bind(this));
      $(this.$refs.selectCitta).select2({
        data: [],
        placeholder: 'Seleziona una citta',
      });
    }.bind(this));
  },
  methods: {
    provinciaChanged () {
      var id = this.$refs.selectProvincia.value;
      this.provincia = this.province.filter((x) => x.id == id)[0].nome;
      $.get('/indirizzo/citta', {id_provincia: id}, function (response) {
        this.cittas = response.citta;
        var d = [{ id: 'none', text: 'Seleziona una citta', selected: true}];
        this.cittas.forEach(function (citta) {
          d.push({
            id: citta.id,
            text: citta.nome,
          });
        });
        $(this.$refs.selectCitta).empty().trigger('change');
        $(this.$refs.selectCitta).select2({
          data: d,
          placeholder: 'Seleziona una citta',
        });
        this.citta = '';
        $(this.$refs.selectCitta).on('select2:select', function () {
          this.cittaChanged();
        }.bind(this));
      }.bind(this));
    },
    cittaChanged () {
      if (this.$refs.selectCitta.value == 'none')
        this.citta = '';
      var id = this.$refs.selectCitta.value;
      this.citta = this.cittas.filter((x) => x.id == id)[0].nome;
    },
  },
}
</script>
