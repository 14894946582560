<template>
  <div class="container">
    <div class="row">
      <h1 class="m-0 text-dark my-3">Documenti Utente</h1>
    </div>

    <div class="row" v-if="canUpload">
      <div class="col-3">
        <aggiungi-documento-utente :token="token"></aggiungi-documento-utente>
      </div>
    </div>


    <div class="row">
      <div class="col-3">
        <label for="select-tipologia">Filtro Tipologia</label>
        <select class="form-control" v-model="selectTipologia" id="select-tipologia">
          <option value=""></option>
          <option v-for="tipo in tipologieDocumentoUtente" :key="tipo.id" :value="tipo.nome">{{ tipo.nome }}</option>
        </select>
      </div>
    </div>

    <!-- Datatable documenti condomini -->
    <table class="table table-striped dataTable" id="dataTable1" ref="tableDocumenti">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Download</th>
          <th>Utente</th>
          <th>Nucleo Familiare</th>
          <th>Scadenza</th>
          <th>Tipologia</th>
          <th>Data Caricamento</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <!-- /Datatable documenti condomini -->
  </div>
</template>
<script>
export default {
  props: ['token', 'id_ruolo'],
  data () {
    return {
      tableDocumenti: null,
      documenti: null,
      tipologieDocumentoUtente: [],
      selectTipologia: '',
      translations: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    $.get('/tipologieDocumentoUtente/json', function (response) {
      this.tipologieDocumentoUtente = response.tipologieDocumentoUtente;
    }.bind(this));
    $.ajax({
      url: '/documento/lista/utente/json',
      method: 'get',
      async: false,
      success: function (response) {
        console.log(response.documenti);
        this.documenti = response.documenti;
      }.bind(this),
    });
  },
  mounted () {
    $.fn.dataTable.moment('DD-MM-YYYY');
    this.tableDocumenti = $(this.$refs.tableDocumenti).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        //dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        dom: '<".row"<"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        //buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.updateTableDocumenti();
  },
  methods: {
    updateTableDocumenti () {
      this.tableDocumenti.clear();
      this.documenti.forEach(function (struct) {
        var doc = struct.documento;
        var utente = struct.utente;
        var linkDownload = `
        <a href="/documenti/utente/${doc.id}" target="_blank">Download</a>
        `;
        var linkUtente = `
        <a href="/utente/${utente.id}/singolo" target="_blank">${utente.preferred_name}</a>
        `;
        if (utente.nucleo_familiare)
          var linkNucleo = `
            <a href="/nucleoFamiliare/${utente.id_nucleo_familiare}/singolo" target="_blank">${utente.nucleo_familiare.nome}</a>
          `;
        else
          var linkNucleo = '';
        var scadenza = doc.scadenza != null ? moment(String(doc.scadenza)).format('DD-MM-YYYY') : '';
        var azioni = '';
        if (this.canDelete)
          azioni = `<button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever disable-documento" title="Elimina Documento" data-id="${doc.id}"></button>`;
        this.tableDocumenti.row.add([
          doc.nome,
          linkDownload,
          linkUtente,
          linkNucleo,
          scadenza,
          doc.tipologia.nome,
          moment(String(doc.created_at)).format('DD-MM-YYYY'),
          azioni,
        ]);
      }.bind(this));
      this.tableDocumenti.draw();
      $('button.disable-documento').on('click', function (e) {
        this.disabilita($(e.target).data('id'));
      }.bind(this));
    },
    disabilita (id) {
      if (confirm('Sei sicuro di voler procedere?')) {
        $.post(`/documenti/utente/${id}/disabilita`, {_token: this.token}, function () {
          window.location.reload();
        }.bind(this));
      }
    },
    __(word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  watch: {
    selectTipologia () {
      var col = this.tableDocumenti.columns().header().filter((x) => x.innerHTML == 'Tipologia')[0].cellIndex;
      this.tableDocumenti.column(col).search(this.selectTipologia).draw();
    },
  },
  computed: {
    canUpload () {
      return [1,6,3,4,5].includes(this.id_ruolo);
    },
    canDelete () {
      return [1,6].includes(this.id_ruolo);
    },
  },
}
</script>
