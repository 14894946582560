<template>
  <div>

    <div class="row">
        <div class="col-md-4 p-1">
            <div class="info-box bg-primary">
                <span class="info-box-icon"><i class="mdi mdi-account"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Totale unità condominiali:</span>
                    <span class="info-box-number">{{ info.totale }}</span>
                    <div class="progress">
                    <div class="progress-bar" style="width: 100%"></div>
                    </div>
                    <!--<span class="progress-description">-->
                    <!--{{-- New Users in last month: {{ $count }} --}}-->
                    <!--</span>-->
                </div>
            </div>
        </div>
        <div class="col-md-4 p-1">
            <div class="info-box bg-primary">
                <span class="info-box-icon"><i class="mdi mdi-account-check"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Unità da affittare</span>
                    <span class="info-box-number">{{ info.da_affittare }} / {{ info.totale }}</span>
                    <div class="progress">
                    <!--<div class="progress-bar" style="width: {{$count/$total*100}}%"></div>-->
                    <div class="progress-bar" :style="width_percentuale"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      info: {
        totale: 1,
        da_affittare: 0,
      },
    };
  },
  mounted () {
    $.get('/unitaCondominiale/info/json', function (response) {
      this.info = response.info;
    }.bind(this));
  },
  computed: {
    percentuale () {
      return (this.info.da_affittare/this.info.totale*100).toFixed(2);
    },
    width_percentuale () {
      return {
        width: this.percentuale + '%'
      };
    }
  },
}
</script>
