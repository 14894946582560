<template>
  <div class="container">
    <div class="row">
        <div class="col-md-4 p-1">
            <div class="info-box bg-primary">
                <span class="info-box-icon"><i class="mdi mdi-account"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Numero Appartamenti</span>
                    <span class="info-box-number">{{ infoUnita.totale }}</span>
                </div>
            </div>
        </div>
        <div class="col-md-4 p-1">
            <div class="info-box bg-primary">
                <span class="info-box-icon"><i class="mdi mdi-account-check"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Appartamenti affittati</span>
                    <span class="info-box-number">{{ infoUnita.totale-infoUnita.da_affittare }}</span>
                </div>
            </div>
        </div>
        <div class="col-md-4 p-1">
            <div class="info-box bg-primary">
                <span class="info-box-icon"><i class="mdi mdi-cancel"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Appartamenti da affittare</span>
                    <span class="info-box-number">{{ infoUnita.da_affittare }}</span>
                </div>
            </div>
        </div>
        <div class="col-md-4 p-1">
            <div class="info-box bg-primary">
                <span class="info-box-icon"><i class="mdi mdi-cancel"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Numero clienti</span>
                    <span class="info-box-number">{{ info.n_clienti }}</span>
                </div>
            </div>
        </div>
        <div class="col-md-4 p-1">
            <div class="info-box bg-primary">
                <span class="info-box-icon"><i class="mdi mdi-cancel"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Numero proprietari</span>
                    <span class="info-box-number">{{ info.n_proprietari }}</span>
                </div>
            </div>
        </div>
        <div class="col-md-4 p-1">
            <div class="info-box bg-primary">
                <span class="info-box-icon"><i class="mdi mdi-cancel"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Numero condomini</span>
                    <span class="info-box-number">{{ info.n_condomini }}</span>
                </div>
            </div>
        </div>
    </div>
    <medie-spese-condominiali></medie-spese-condominiali>
  </div>
</template>

<script>
export default {
  props: [],
  data () {
    return  {
      info: null,
      infoUnita: null,
    };
  },
  mounted () {
    $.get('/statistiche/generali/json', function (response) {
      this.info = response.info;
    }.bind(this));
    $.get('/unitaCondominiale/info/json', function (response) {
      this.infoUnita = response.info;
    }.bind(this));
  },
  methods: {
  },
}
</script>
