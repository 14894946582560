/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// AdminLTE
require('admin-lte/dist/js/adminlte');

// Chart.JS
var Chart = require('chart.js')


// Autosize
autosize = require('autosize');

// Vue.JS
window.Vue = require('vue');
window.XLSX = require('xlsx')

var DataTable = require('datatables.net');
require('datatables.net-bs4');

//DataTables Buttons
window.JSZip = require('jszip');//Serve per export excel html5
require('pdfmake'); //Serve per export pdf
//require('pdfkit/js/pdfkit.js'); //Serve per export pdf
window.pdfFonts = require('pdfmake/build/vfs_fonts.js'); //Serve per export pdf

window.pdfMake.vfs = window.pdfFonts.pdfMake.vfs;

require('datatables.net-plugins/sorting/datetime-moment.js');

require('datatables.net-buttons');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.min.js'); //CSV e COPY
require('datatables.net-buttons/js/buttons.colVis.min.js'); //COLVIS
//require('datatables.net-buttons/js/buttons.print.min.js'); //PRINT
//require('datatables.net-buttons/js/buttons.flash.min.js'); //Export for legacy browsers
//require('datatables.net-autofill');

// Select2
require('select2');

// Daterangepicker
daterangepicker = require('daterangepicker');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('crea-annuncio', require('./components/CreaAnnuncio.vue').default);
Vue.component('lista-annunci', require('./components/ListaAnnunci.vue').default);
Vue.component('annuncio-interno', require('./components/Annuncio.vue').default);

Vue.component('lista-interessati-interni', require('./components/ListaInteressatiInterni.vue').default);
Vue.component('lista-interessati-esterni', require('./components/ListaInteressatiEsterni.vue').default);
Vue.component('aggiungi-interessato-interno', require('./components/AggiungiInteressatoInterno.vue').default);
Vue.component('aggiungi-interessato-esterno', require('./components/AggiungiInteressatoEsterno.vue').default);


Vue.component('lista-condomini', require('./components/ListaCondomini.vue').default);
Vue.component('lista-utenti', require('./components/ListaUtenti.vue').default);
Vue.component('lista-collaboratori', require('./components/ListaCollaboratori.vue').default);
Vue.component('lista-nuclei-familiari', require('./components/ListaNucleiFamiliari.vue').default);
Vue.component('lista-ticket', require('./components/ListaTicket.vue').default);
Vue.component('scheda-info', require('./components/SchedaInfo.vue').default);

Vue.component('lista-unita-condominiali', require('./components/ListaUnitaCondominiali.vue').default);
Vue.component('scheda-info-unita', require('./components/SchedaInfoUnita.vue').default);
Vue.component('unita-condominiale', require('./components/UnitaCondominiale.vue').default);
Vue.component('gestore-immagini-unita-condominiale', require('./components/GestoreImmaginiUnitaCondominiale.vue').default);
Vue.component('affitti-unita-condominiale', require('./components/AffittiUnitaCondominiale.vue').default);
Vue.component('documenti-utente', require('./components/DocumentiUtente.vue').default);

Vue.component('import-alyante', require('./components/ImportAlyante.vue').default);
Vue.component('import-danea', require('./components/ImportDanea.vue').default);
Vue.component('import-danea2', require('./components/ImportDanea2.vue').default);
Vue.component('import-fatture', require('./components/ImportFatture.vue').default);
Vue.component('import-fatture2', require('./components/ImportFatture2.vue').default);
Vue.component('import-fatture3', require('./components/ImportFatture3.vue').default);

Vue.component('documenti-condominio-all', require('./components/DocumentiCondominioAll.vue').default);
Vue.component('documenti-unita-condominiale-all', require('./components/DocumentiUnitaCondominialeAll.vue').default);
Vue.component('documenti-utente-all', require('./components/DocumentiUtenteAll.vue').default);
Vue.component('documenti-immobili-all', require('./components/DocumentiImmobiliAll.vue').default);
Vue.component('aggiungi-documento-immobile', require('./components/AggiungiDocumentoImmobile.vue').default);
Vue.component('aggiungi-documento-utente', require('./components/AggiungiDocumentoUtente.vue').default);

Vue.component('utente', require('./components/Utente.vue').default);
Vue.component('lista-utenti-verifica', require('./components/ListaUtentiVerifica.vue').default);

Vue.component('lista-condomini-collaboratore', require('./components/CondominiCollaboratore.vue').default);

Vue.component('nucleo-familiare', require('./components/NucleoFamiliare.vue').default);
Vue.component('affitti-nucleo-familiare', require('./components/AffittiNucleoFamiliare.vue').default);
Vue.component('condominio', require('./components/Condominio.vue').default);
Vue.component('indirizzo', require('./components/Indirizzo.vue').default);
Vue.component('luogo-nascita', require('./components/LuogoNascita.vue').default);
Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('crea-utente-modale', require('./components/CreaUtenteModale.vue').default);

Vue.component('daterange-picker', require('./components/Daterangepicker.vue').default);
Vue.component('selettore-custom', require('./components/SelettoreCustom.vue').default);
Vue.component('select-unita-condominiale', require('./components/SelectUnitaCondominiale.vue').default);

Vue.component('tabella-documenti', require('./components/TabellaDocumenti.vue').default);
Vue.component('crea-ticket', require('./components/CreaTicket.vue').default);
Vue.component('view-ticket', require('./components/Ticket.vue').default);
Vue.component('tickets-collaboratore', require('./components/TicketsCollaboratore.vue').default);
Vue.component('aggiungi-collaboratore', require('./components/AggiungiCollaboratore.vue').default);
Vue.component('feedback-ticket', require('./components/FeedbackTicket.vue').default);

Vue.component('lista-rate-condominio', require('./components/ListaRateCondominio.vue').default);
Vue.component('pagamento', require('./components/Pagamento.vue').default);
Vue.component('dettaglio-pagato', require('./components/DettaglioPagato.vue').default);
Vue.component('lista-pagamenti', require('./components/ListaPagamenti.vue').default);


Vue.component('lista-annunci-pubblici', require('./components/ListaAnnunciPubblici.vue').default);
Vue.component('annuncio-pubblico', require('./components/AnnuncioPubblico.vue').default);

Vue.component('filtro-citta', require('./components/FiltroCitta.vue').default);
Vue.component('modale-caricamento', require('./components/ModaleCaricamento.vue').default);

Vue.component('ListaAvvisi', require('./components/ListaAvvisi.vue').default);

Vue.component('test-email', require('./components/TestEmail.vue').default);

Vue.component('morosita-condominio', require('./components/MorositaCondominio.vue').default);
Vue.component('statistiche-manutenzione', require('./components/StatisticheManutenzione.vue').default);
Vue.component('statistiche-commerciali', require('./components/StatisticheCommerciali.vue').default);
Vue.component('morosita-conduttore-all', require('./components/MorositaConduttoreAll.vue').default);
Vue.component('morosita-conduttore', require('./components/MorositaConduttore.vue').default);
Vue.component('morosita-conduttore-complessivo', require('./components/MorositaConduttoreComplessivo.vue').default);
Vue.component('statistiche-condomini', require('./components/StatisticheCondomini.vue').default);
Vue.component('medie-spese-condominiali', require('./components/MedieSpeseCondominiali.vue').default);
Vue.component('medie-generali', require('./components/MedieGenerali.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

window.app = new Vue({
    el: '#app',
});

