<template>
  <div class="container">
      <h1 class="m-0 mb-3">Lista Inserzioni</h1>

    <table class="table table-striped dataTable" ref="tableAnnunci">
      <thead>
        <tr>
          <th>Titolo</th>
          <th>Visite</th>
          <th>Interessati Interni</th>
          <th>Interessati Esterni</th>
          <th>Prezzo</th>
          <th>Condominio</th>
          <th>Unità Condominiale</th>
          <th>Indirizzo</th>
          <th>Tipologia</th>
          <th>Data Caricamento</th>
          <th>Nascosto</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['filter_unita_condominiale'],
  data () {
    return {
      tableAnnunci: null,
      annunci: null,
    };
  },
  mounted () {
    $.fn.dataTable.moment('DD-MM-YYYY');
    this.tableAnnunci = $(this.$refs.tableAnnunci).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row"<"#buttons2.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter2.col-sm-4.col-12"f><"#pagination2.col-sm-4 col-12"p><"#table2.col-12"t><"#length2"l><"#information2"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.updateAnnunci();
  },
  methods: {
    updateAnnunci () {
      $.get('/annuncio/lista/json', function (response) {
        this.annunci = response.annunci;
        this.updateTableAnnunci();
      }.bind(this));
    },
    updateTableAnnunci () {
      this.tableAnnunci.clear();
      this.annunciFiltered.forEach(function (ann) {
        var interessatiInterni = `${ann.utenti_interessati_interni.length}`;
        var interessatiEsterni = `${ann.utenti_interessati_esterni.length}`;
        var visite = `${ann.visite}`;
        var linkTitolo = `
        <a href="/annuncio/${ann.id}/singolo" target="_blank">${ann.titolo}</a>
        `;
        var linkCondominio = `
        <a href="/condominio/${ann.unita_condominiale.condominio.id}/singolo" target="_blank">${ann.unita_condominiale.condominio.nome}</a>
        `;
        var linkUnitaCondominiale = `
        <a href="/unitaCondominiale/${ann.unita_condominiale.id}/singolo" target="_blank">${ann.unita_condominiale.nome}</a>
        `;
        this.tableAnnunci.row.add([
          linkTitolo,
          visite,
          interessatiInterni,
          interessatiEsterni,
          Number(ann.prezzo).toFixed(2),
          linkCondominio,
          linkUnitaCondominiale,
          ann.unita_condominiale.condominio.indirizzo.summary,
          ann.tipologia.nome,
          moment(String(ann.created_at)).format('DD-MM-YYYY'),
          ann.visibile == 0 ? 'Nascosto' : 'Visibile',
        ]);
      }.bind(this));
      this.tableAnnunci.draw();
    },
  },
  computed: {
    annunciFiltered () {
      return this.annunci.filter(function (e) {
        if (this.filter_unita_condominiale != null)
          return e.id_unita_condominiale == this.filter_unita_condominiale;
        else
          return true;
      }.bind(this));
    },
  },
}
</script>
