<template>
<div class="container">
    <h1 class="m-0 text-dark mb-3" v-if="gestore_sociale">Lista Gestori Sociali</h1>
    <h1 class="m-0 text-dark mb-3" v-else>{{ __('translation.lista_utenti') }}</h1>

    <div class="row">
        <div class="col-md-4 p-1">
            <div class="info-box bg-primary">
                <span class="info-box-icon"><i class="mdi mdi-account"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Utenti Totali:</span>
                    <span class="info-box-number">{{utenti.length}}</span>
                    <!--<div class="progress">-->
                    <!--<div class="progress-bar" style="width: 70%"></div>-->
                    <!--</div>-->
                    <!--<span class="progress-description">-->
                    <!--New Users in last month: {{}}-->
                    <!--</span>-->
                </div>
            </div>
        </div>
        <!--
          -<div class="col-md-4 p-1">
          -    <div class="info-box bg-primary">
          -        <span class="info-box-icon"><i class="mdi mdi-account-check"></i></span>
          -        <div class="info-box-content">
          -            <span class="info-box-text">Utenti verificati</span>
          -            <span class="info-box-number">{{ utentiVerificati.length }} / {{utenti.length}}</span>
          -            <div class="progress">
          -            <div class="progress-bar" :style="'width: '+percentualeUtentiVerificati+'%'"></div>
          -            </div>
          -            <span class="progress-description">
          -            [>  {{ percentualeUtentiVerificati.toFixed(0) }}% del totale<]
          -            [>30% of Total<]
          -            </span>
          -        </div>
          -    </div>
          -</div>           -->
        <!--
          -<div class="col-md-4 p-1">
          -    <div class="info-box bg-primary">
          -        <span class="info-box-icon"><i class="mdi mdi-cancel"></i></span>
          -        <div class="info-box-content">
          -            <span class="info-box-text">Disabled/Blocked Users</span>
          -            <span class="info-box-number">{{}}</span>
          -            <div class="progress">
          -            <div class="progress-bar" style="width:50%"></div>
          -            </div>
          -            <span class="progress-description">
          -            Deleted Users in last month: {{}}
          -            </span>
          -        </div>
          -    </div>
          -</div>
        -->
    </div>

    <table class="table table-striped dataTable" id="dataTable" ref="tableUser">
        <thead>
            <tr>
                <th class="id">Id</th>
                <th class="nome">Nome</th>
                <th class="cognome">Cognome</th>
                <th class="ruolo">Ruolo</th>
                <th class="nucleo_familiare">Nucleo Familiare</th>
                <th class="ragione_sociale">Ragione Sociale</th>
                <th class="condominio">Condominio</th>
                <th class="telefono">Telefono</th>
                <th class="cellulare">Cellulare</th>
                <th class="email">Email</th>
                <th class="seconda_mail">Email Secondaria</th>
                <th class="pec">Pec</th>
                <th class="partita_iva">Partita Iva</th>
                <th class="note">Note</th>
                <th class="codice_fiscale">Codice Fiscale</th>
                <!--<th class="verificato">Verificato</th>-->
                <th class="data_nascita">Data Nascita</th>
                <th class="cittadinanza">Cittadinanza</th>
                <th class="creato_il">Creato il</th>
                <th class="azioni">Azioni</th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </table>
    <div class="row" v-if="canCreate">
        <div class="offset-sm-9 col-12 col-sm-3 mt-2">
            <a href="/utente/crea" class="btn bg-primary w-100">Aggiungi nuovo utente</a>
        </div>
    </div>
</div>
</template>
<script>
export default {
  props: ['token', 'id_ruolo_utente', 'gestore_sociale'],
  data () {
    return {
      translations: null,
      tableUser: null,
      utenti: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    this.requestUtenti();
  },
  mounted () {
    // $.fn.dataTable.moment('DD-MM-YYYY');
    var hiddenColumns = [0];
    if(this.gestore_sociale == true)
      hiddenColumns = [0,4,6];

    this.tableUser = $(this.$refs.tableUser).DataTable( {
    columnDefs: [
          //Nascondi colonna id
          { targets: hiddenColumns, visible: false},
          { targets: ["nome","cognome","email"], className: "text-left"},
        ],
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.tableUser.on('draw', function () {
      this.updateOnClick();
    }.bind(this));
    this.updateUtenti();
  },
  methods: {
    requestUtenti () {
      $.ajax({
        url: '/utente/lista/json',
        method: 'get',
        async: false,
        success: function (response) {
          this.utenti = response.utenti;
          console.log(response);
        }.bind(this),
      });
    },
    updateUtenti () {
      this.tableUser.clear();
      this.utenti.forEach(function (utente) {
        var gestore = ![4,5].includes(utente.id_ruolo_utente);
        if (this.gestore_sociale) {
          if (!gestore)
            return;
        } else {
          if (gestore)
            return;
        }


        if (utente.name)
          var link = `
          <a href="/utente/${utente.id}/singolo">${utente.name}</a>
          `;
        else
          var link = '';
        if (utente.ragione_sociale)
          var ragione_sociale_link = `
          <a href="/utente/${utente.id}/singolo">${utente.ragione_sociale}</a>
          `;
        else
          var ragione_sociale_link = '';
        var condominioLink = '';
        if (utente.condominio)
          condominioLink = `
          <a href="/condominio/${utente.condominio.id}/singolo" target="_blank">${utente.condominio.nome}</a>
        `;
        var dataNascita = utente.data_nascita !== null ? moment(String(utente.data_nascita)).format('DD-MM-YYYY') : '';
        var dataCreazione = utente.created_at !== null ? moment(String(utente.created_at)).format('DD-MM-YYYY') : '';
        var linkNucleoFamiliare = '';
        if (utente.nucleo_familiare)
          linkNucleoFamiliare = `
            <a href="/nucleoFamiliare/${utente.id_nucleo_familiare}/singolo" target="_blank">${utente.nucleo_familiare.nome}</a>
          `;
        var cittadinanza = '';
        if (utente.cittadinanza)
          cittadinanza = utente.cittadinanza.nome + ' ' + utente.cittadinanza.unicode;
        this.tableUser.row.add([
          utente.id,
          link,
          utente.cognome,
          utente.ruolo_utente.nome,
          linkNucleoFamiliare,
          ragione_sociale_link,
          condominioLink,
          utente.telefono,
          utente.cellulare,
          utente.email,
          utente.seconda_mail,
          utente.pec,
          utente.partita_iva,
          utente.note,
          utente.codice_fiscale,
          // utente.verificato == 0 ? 'No' : 'Sì',
          dataNascita,
          cittadinanza,
          dataCreazione,
          /*
          `<button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-account-check verifica" data-toggle="tooltip" title="Verifica Utente" data-id="${utente.id}"></button>
          <button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-account-cancel disable-button" data-toggle="tooltip" title="Disabilita Utente" data-id="${utente.id}"></button>`,
          */
          `
          <button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-account-cancel disable-button" data-toggle="tooltip" title="Disabilita Utente" data-id="${utente.id}"></button>
          `,
        ]);
      }.bind(this));
      this.tableUser.draw();
      this.updateOnClick();
    },
    updateOnClick () {
      $('button.verifica').off('click');
      $('button.disable-button').off('click');
      $('button.verifica').on('click', function (e) {
        this.verifica($(e.target).data('id'));
      }.bind(this));
      $('button.disable-button').on('click', function (e) {
        this.disable($(e.target).data('id'));
      }.bind(this));
    },
    verifica (id) {
      if (confirm('Sicuro di voler procedere?')) {
        $.post(`/utente/${id}/verifica`, {_token: this.token}, function () {
          this.requestUtenti();
          this.updateUtenti();
        }.bind(this));
      }
    },
    disable (id) {
      if (confirm('Sicuro di voler procedere?')) {
        $.post(`/utente/${id}/disabilita`, {_token: this.token}, function () {
          window.location.reload();
          this.requestUtenti();
          this.updateUtenti();
        }.bind(this));
      }
    },
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    utentiVerificati () {
      return this.utenti.filter(function (u) {
        return u.verificato == 1;
      });
    },
    percentualeUtentiVerificati () {
      return this.utentiVerificati.length/this.utenti.length*100;
    },
    canCreate () {
      return [1,6,8].includes(this.id_ruolo_utente);
    },
  },
}
</script>
