<template>
  <div class="row">
    <div class="col-12 col-sm-3">
      <button class="btn btn-primary" @click="openModaleCaricamento()">Carica Documento</button>
    </div>
    <div class="modal fade" role="dialog" aria-hidden="true" ref="modal">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Carica Documento</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form action="">
              <label for="nome_documento">Nome Documento</label>
              <input id="nome_documento" name="nome_documento" :class="formInputClassDoc('nome')" type="text" placeholder="Nome Documento" v-model="nomeDocumento" required>
              <span class="invalid-feedback" role="alert" v-if="docErrors['nome'] !== undefined">
                <strong>{{ docErrors['nome'][0] }}</strong>
              </span>

              <label for="tipo_documento" class="mt-4">Tipo Documento</label>
              <select id="tipo_documento" name="tipo_documento" :class="formInputClassDoc('tipologia')" type="file" v-model="tipoDocumentoSelezionato">
                <option v-for="tipo in tipologieDocumentoUtente" :key="tipo.id" :value="tipo.id">{{ tipo.nome }}</option>
              </select>
              <span class="invalid-feedback" role="alert" v-if="docErrors['tipologia'] !== undefined">
                <strong>{{ docErrors['tipologia'][0] }}</strong>
              </span>

              <label for="scadenza" class="mt-4">Scadenza</label>
              <daterange-picker v-model="scadenza" :class="formInputClassDoc('scadenza')"></daterange-picker>
              <!--<daterange-picker v-model="scadenza" class="mt-4" custom_label="Scadenza" custom_class="mt-4"></daterange-picker>-->
              <span class="invalid-feedback" role="alert" v-if="docErrors['scadenza'] !== undefined">
                <strong>{{ docErrors['scadenza'][0] }}</strong>
              </span>

              <label for="utente" class="mt-4 d-block">Utente</label>
              <select id="utente" name="utente" :class="formInputClassDoc('utente')" ref="selectUtente">
                <option></option>
              </select>
              <span class="invalid-feedback" role="alert" v-if="docErrors['utente'] !== undefined">
                <strong>{{ docErrors['utente'][0] }}</strong>
              </span>
              <br>

              <label for="file_documento" class="mt-4">Carica File</label>
              <input id="file_documento" name="file_documento" :class="formInputClassDoc('file')" type="file" ref="fileDocumento" required>
              <span class="invalid-feedback" role="alert" v-if="docErrors['file'] !== undefined">
                <strong>{{ docErrors['file'][0] }}</strong>
              </span>

            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
            <button type="button" class="btn btn-primary" @click="salva()">Salva</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['token'],
  data () {
    return {
      nomeDocumento: '',
      scadenza: null,
      utenteSelezionato: null,
      utenti: [],
      tipoDocumentoSelezionato: null,
      tipologieDocumentoUtente: null,
      docErrors: {},
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
  },
  mounted () {
    $.get('/tipologieDocumentoUtente/json', function (response) {
      this.tipologieDocumentoUtente = response.tipologieDocumentoUtente;
    }.bind(this));
    $.get('/utente/lista/json', function (response) {
      this.utenti = response.utenti;
      var d = [];
      this.utenti.forEach(function (u) {
        d.push({
          id: u.id,
          // text: u.name + ' ' + u.cognome,
          text: u.preferred_name,
        });
      });
      $(this.$refs.selectUtente).select2({
        data: d,
        placeholder: 'Seleziona un utente',
        width: 'element',
        allowClear: true,
      });
      $(this.$refs.selectUtente).on('select2:select', function () {
        this.utenteSelezionatoChanged(this.$refs.selectUtente.value);
      }.bind(this));
      $(this.$refs.selectUtente).on('select2:clear', function () {
        this.utenteSelezionatoChanged(null);
      }.bind(this));
    }.bind(this));
  },
  methods: {
    utenteSelezionatoChanged (value) {
      this.utenteSelezionato = value;
    },
    openModaleCaricamento () {
      $(this.$refs.modal).modal('show');
    },
    salva () {
      if (this.utenteSelezionato == null) {
        alert('Selezionare un utente');
        return;
      }
      var formData = new FormData();
      formData.append('file', this.$refs.fileDocumento.files[0]);
      formData.append('nome', this.nomeDocumento);
      formData.append('tipologia', this.tipoDocumentoSelezionato);
      if (this.scadenza != null)
        formData.append('scadenza', this.scadenza);
      axios.post('/utente/'+this.utenteSelezionato+'/uploadDocumento',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function () {
          $(this.$refs.modalDocumento).modal('hide');
          window.location.reload();
        }.bind(this)).catch(function (error) {
          if (error.response.status == 413)
            this.docErrors = {'file': ['File troppo grande.']};
          else
            this.docErrors = error.response.data.errors;
        }.bind(this));
    },
    formInputClassDoc (attr) {
      return { 'form-control': true, 'is-invalid': this.docErrors[attr] !== undefined };
    },
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
}
</script>
