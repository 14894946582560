<template>
  <div class="container">
    <div class="modal fade" role="dialog" aria-hidden="true" ref="modalCaricamento">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Caricamento</h5>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center">
              <div class="spinner-grow text-primary ml-auto mr-auto" role="status">
                <span class="sr-only">Caricamento</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <h1 class="col-12">Import Affitti</h1>
      <div class="col-12 col-sm-4">
        <label for="file">Seleziona un file</label>
        <input type="file" ref="fileImport" >
      </div>
      <div class="col-12 col-sm-4">
        <label for="importbutton">Conferma importazione</label>
        <button class="form-control btn btn-primary" :class="{ 'bg-success': importOk }" @click="importa()">Importa</button>
      </div>
      <div class="col-12 col-sm-4">
        <label for="send">Invia al server</label>
        <button type="button" class="form-control btn btn-primary" @click="invia()" :disabled="!inviaPronto">Invia</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="errors.length > 0">
        <h2>Errori riscontrati:</h2>
        <table class="table table-striped dataTable">
          <thead>
            <tr>
              <th>Colonna</th>
              <th>Descrizione Errore</th>
              <th>Numero Documento</th>
              <th>Codice Fiscale</th>
              <th>Data</th>
              <th>Imponibile IVA</th>
              <th>Imposta IVA</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="error, i in errors" :key="i">
              <td>{{ error.row+1 }}</td>
              <td class="text-left">{{ error.error }}</td>
              <td>{{ data[error.row].numero_documento }}</td>
              <td>{{ data[error.row].codice_fiscale }}</td>
              <td>{{ data[error.row].data }}</td>
              <td>{{ data[error.row].imponibile_iva }}</td>
              <td>{{ data[error.row].imposta_iva }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['token'],
  data () {
    return {
      errors: [],
      fr: null,
      privFileData: null,
      data: {},
      importOk: false,
    };
  },
  methods: {
    importa () {
      var file = $(this.$refs.fileImport)[0].files[0];
      this.fr = new FileReader();
      this.fr.onload = this.fileLoaded;
      this.fr.readAsBinaryString(file);
    },
    fileLoaded () {
      var table = XLSX.read(this.fr.result, {type:'binary'});
      var sheet = table.Sheets[table.SheetNames[0]];
      this.privFileData = XLSX.utils.sheet_to_json(sheet, {header:1, raw: false});
      this.parse();
    },
    parse () {
      var header = this.parseHeader(this.privFileData[0]);
      console.log('BEGIN HEADER');
      console.log(header);
      console.log('END HEADER');

      var file = this.privFileData.slice(1);
      this.data = [];
      file.forEach(function (row) {
        var d = {};
        var numero_documento = row[header["Numero documento"]];
        if (numero_documento == undefined)
          return;
        d['numero_documento'] = numero_documento + '';
        var date = row[header["Data documento"]];
        if (date !== undefined) {
          // var strdate = moment(date, 'MM/DD/YY').format('YYYY-MM-DD');
          var strdate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
          if (strdate == 'Invalid date')
            console.log(date);
          d['data'] = strdate;
        }

        var progetto = row[header["Progetto"]];
        if (progetto !== undefined)
          d['progetto'] = progetto;

        var imponibile = row[header["Totale imponibile"]];
        if (imponibile !== undefined)
          d['imponibile_iva'] = imponibile;
        var imposta = row[header["Totale IVA"]];
        if (imposta !== undefined)
          d['imposta_iva'] = imposta;
        var codice_fiscale = row[header["Codice Fiscale"]];
        if (codice_fiscale !== undefined)
          d['codice_fiscale'] = codice_fiscale;

        var data_pagamento = row[header["Data pagamento"]];
        if (data_pagamento !== undefined) {
          var strdate = moment(data_pagamento, 'DD/MM/YYYY').format('YYYY-MM-DD');
          if (strdate == 'Invalid date')
            console.log(data_pagamento);
          else
              d['data_pagamento'] = strdate;
        }
        var importo_pagato = row[header["Importo pagato"]];
        if (importo_pagato !== undefined) {
          if (d['codice_fiscale'] == 'BLDSFN60T66H294M')
            console.log(row);
          d['importo_pagato'] = importo_pagato;
        }

        if (Object.keys(d).length != 0) {
          this.data.push(d);
        }

        /*
            0 - Data registrazione
            1 - Ditta
            2 - Codice cliente
            3 - Ragione sociale
            4 - Progetto
            5 - Partita IVA
            6 - Codice Fiscale
            7 - Numero documento
            8 - Sezionale
            9 - Data documento
            10 - Condizione pagamento
            11 - Condizione di pagamento
            12 - Totale imponibile
            13 - Totale IVA
            14 - Totale documento
            15 - Numero riga
            16 - Data pagamento
            17 - Importo pagato
        */

      }.bind(this));
      // this.data = Object.entries(this.data).map((x) => x[1]);
      this.importOk = true;
    },
    parseHeader (header) {
      var a = {};
      header.forEach(function (el, key) {
        a[el] = key;
      });
      return a;
    },
    invia () {
      var data = {
        fatture: JSON.stringify(this.data),
        _token: this.token,
      };
      this.apriModaleCaricamento();
      $.post('/import/fatture', data, function (response) {
        this.chiudiModaleCaricamento();
        window.location.href = "/pagamento/lista";
      }.bind(this)).fail(function (response) {
        this.errors = response.responseJSON.errors;
        this.chiudiModaleCaricamento();
      }.bind(this));
    },
    apriModaleCaricamento () {
      $(this.$refs.modalCaricamento).modal({backdrop: 'static', keyboard: false});
    },
    chiudiModaleCaricamento () {
      $(this.$refs.modalCaricamento).modal('hide');
    },
  },
  computed: {
    inviaPronto () {
      return this.importOk && this.condominio !== null;
    },
  },
}
</script>
