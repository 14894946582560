<template>
  <div class="mt-2">
    <button type="button" class="btn btn-secondary w-100" @click="apriModale()" v-if="modifica">Modifica Collaboratore</button>
    <button type="button" class="btn btn-secondary w-100" @click="apriModale()" v-else>Aggiungi Collaboratore</button>

    <!-- Modal -->
    <div class="modal fade" id="modaleCollaboratore" role="dialog" aria-labelledby="modaleCollaboratoreLabel" aria-hidden="true" ref="modaleCollaboratore">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modaleCollaboratoreLabel" v-if="modifica">Modifica Collaboratore</h5>
            <h5 class="modal-title" id="modaleCollaboratoreLabel" v-else>Aggiungi Collaboratore</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <form>
              <div class="form-group">
                <label for="select-collaboratore">Collaboratore</label>
                <select id="select-collaboratore" class="form-control" ref="selectCollaboratore">
                  <option></option>
                </select>
                <span class="invalid-feedback" v-if="errors['id_collaboratore'] !== undefined">
                  <strong>{{ errors['id_collaboratore'][0] }}</strong>
                </span>
              </div>
            </form>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
            <button type="button" class="btn btn-primary" @click="aggiungi()" v-if="modifica">Modifica</button>
            <button type="button" class="btn btn-primary" @click="aggiungi()" v-else>Aggiungi</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['token', 'id_ticket', 'modifica'],
  data () {
    return {
      collaboratori: [],
      collaboratore: null,
      errors: {},
    };
  },
  mounted () {
    $.get('/collaboratore/lista/json', null, function (response) {
      var d = [];
      this.collaboratori = response.collaboratori;
      this.collaboratori.forEach(function (collaboratore) {
        d.push({
          id: collaboratore.id,
          text: collaboratore.name + ' ' + collaboratore.cognome,
        });
      });
      $(this.$refs.selectCollaboratore).select2({
        data: d,
        placeholder: 'Seleziona un collaboratore',
      });
      $(this.$refs.selectCollaboratore).on('select2:select', function (e) {
        this.collaboratore = $(this.$refs.selectCollaboratore).val();
      }.bind(this));
    }.bind(this));
    $(this.$refs.modaleCollaboratore).on('hidden.bs.modal', function () {
      this.modaleChiuso();
    }.bind(this));
  },
  methods: {
    aggiungi () {
      var data = {
        _token: this.token,
        id_collaboratore: this.collaboratore,
      };
      $.post(`/ticket/${this.id_ticket}}/collaboratore/aggiungi`, data, function (response) {
        window.location.reload();
      }).fail(function (response) {
        this.errors = response.responseJSON.errors;
      }.bind(this));
    },
    apriModale () {
      $(this.$refs.modaleCollaboratore).modal('show');
    },
    modaleChiuso () {
      $(this.$refs.selectCollaboratore).val('');
      $(this.$refs.selectCollaboratore).trigger('change');
    },
  },
}
</script>
