<template>
<div class="container">
    <h1 class="m-0 text-dark mb-3">{{ __('translation.nucleo_familiare_singolo') }}</h1>
    <form action="" class="mb-4">
        <div class="row">
            <div class="col-12 col-sm-8">
                <label for="nome">Nome</label>
                <input id="nome" name="nome" :class="formInputClass('nome')" type="text" v-model="nucleoFamiliare.nome" placeholder="Nome" :disabled="role == 'user'">
                <span class="invalid-feedback" role="alert" v-if="errors['nome'] !== undefined">
                  <strong>{{ errors['nome'][0] }}</strong>
                </span>
            </div>
            <div class="col-12 col-sm-6" v-if="!creazione">
                <label for="id">Id</label>
                <input id="id" name="id" class="form-control" type="text" placeholder="Id" :value="nucleoFamiliare.id" disabled>
            </div>
        </div>
        <template v-if="!creazione && nucleoFamiliare.unita_condominiale !== null && false">
          <h4 class="mt-2">Affitto corrente</h4>
          <div class="row">
            <div class="col-12 col-sm-6">
              <label for="condominio">
                <a :href="'/condominio/'+nucleoFamiliare.unita_condominiale.condominio.id+'/singolo'" target="_blank">Condominio</a>
              </label>
              <input type="text" class="form-control" :value="nucleoFamiliare.unita_condominiale.condominio.nome" id="condominio" disabled>
            </div>
            <div class="col-12 col-sm-6">
              <label for="unita-condominiale">
                <a :href="'/unitaCondominiale/'+nucleoFamiliare.unita_condominiale.id+'/singolo'" target="_blank">
                  Unita Condominiale
                </a>
              </label>
              <input type="text" class="form-control" :value="nucleoFamiliare.unita_condominiale.nome" id="unita-condominiale" disabled>
            </div>
            <div class="col-12 col-sm-6">
              <label for="data-inizio">Data Inizio Contratto</label>
              <input type="text" class="form-control" :value="toDate(nucleoFamiliare.unita_condominiale.pivot.data_inizio)" id="data-inizio" disabled>
            </div>
            <div class="col-12 col-sm-6">
              <label for="data-fine">Data Fine Contratto</label>
              <input type="text" class="form-control" :value="toDate(nucleoFamiliare.unita_condominiale.pivot.data_fine)" id="data-fine" disabled>
            </div>
          </div>
        </template>
        <template v-if="role != 'user'">
          <template v-if="!creazione">
            <h4 class="mt-2">Nuovo affitto</h4>
          </template>
          <select-unita-condominiale ref="selectUnitaCondominiale"></select-unita-condominiale>
          <div class="row">
            <div class="col-12 col-sm-6">
              <label for="data_inizio">Data Inizio Contratto</label>
              <daterange-picker id="data_inizio" v-model="data_inizio" :class="formInputClass('data_inizio')"></daterange-picker>
            </div>
            <div class="col-12 col-sm-6">
              <label for="data_fine">Data Fine Contratto</label>
              <daterange-picker id="data_fine" v-model="data_fine" :class="formInputClass('data_fine')"></daterange-picker>
              <span class="invalid-feedback" role="alert" v-if="errors['data_fine'] !== undefined">
                <strong>{{ errors['data_fine'][0] }}</strong>
              </span>
            </div>
          </div>
        </template>
    </form>
    <div class="row" v-if="role != 'user'">
        <div class="offset-sm-9 col-12 col-sm-3 mt-2">
            <button type="button" class="btn bg-primary w-100" @click="crea" v-if="creazione">Salva</button>
            <button type="button" class="btn bg-primary w-100" @click="salva" v-else>Salva</button>
        </div>
    </div>
    <template v-if="!creazione">

    <h4 class="mt-2">{{ __('translation.lista_membri_nucleo_familiare') }}</h4>

    <!-- Datatable membri nucleo -->
    <table class="table table-striped dataTable" id="dataTable" ref="tableComponenti">
        <thead>
            <tr>
                <th>Nome</th>
                <th>Cognome</th>
                <th>Ragione Sociale</th>
                <th>Email</th>
                <th>Data Creazione</th>
                <th v-if="role !== 'user'">Azioni</th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </table>
    <!-- /Datatable membri nucleo -->


    <div class="row" v-if="role != 'user'">
        <!-- Button trigger modal -->
        <div class="offset-sm-6 col-12 col-sm-3 mt-2">
            <button class="btn bg-primary w-100" data-toggle="modal" data-target="#inserisci_membro">Aggiungi utente esistente</button>
        </div>
        <div class="col-12 col-sm-3 mt-2">
          <button class="btn bg-primary w-100" @click="apriCreaUtenteModale">Aggiungi nuovo membro</button>
        </div>
        <crea-utente-modale :token="token" :id_nucleo_familiare="id_nucleo_familiare" ref="creaUtenteModale"></crea-utente-modale>
        <!-- Modal -->
        <div class="modal fade" id="inserisci_membro" role="dialog" aria-labelledby="inserisci_membro" aria-hidden="true" ref="modalComponente">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Aggiungi nuovo membro</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form action="">
                            <label for="nome_documento">Ricerca per Nome Cognome / Email</label>
                            <!--<input id="nome_documento" name="nome_documento" class="form-control" type="text" placeholder="Nome Cognome/Email" required>-->
                            <select class="form-control" type="text" ref="selectComponente">
                              <!--<option v-for="utente in utenti" :key="utente.id" :value="utente.id">{{ utente.name }}</option>-->
                            </select>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="aggiungiComponente">Aggiungi</button>
                        <button type="button" class="btn bg-secondary" data-dismiss="modal">Chiudi</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Modal -->
    </div>

    <affitti-nucleo-familiare :nucleo="nucleoFamiliare" :token="token" :id_ruolo_utente="id_ruolo_utente"></affitti-nucleo-familiare>
    </template>

</div>

</template>

<script>
export default {
  props: ['token', 'id_nucleo_familiare', 'role', 'creazione', 'id_ruolo_utente'],
  data () {
    return {
      errors: {},
      tableComponenti: null,
      translations: null,
      nucleoFamiliare: null,
      utenti: [],
      data_inizio: null,
      data_fine: null,
      selectComponente: null,
      //acconto_riscatto_accumulato: 0,
      //penale_rinuncia_versato: 0
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    
    if(!this.creazione) {
        $.ajax({
          url: '/utente/lista/json',
          method: 'get',
          async: false,
          success: function (response) {
            this.utenti = response.utenti;
          }.bind(this)
        });
    }
    if (this.creazione) {
      this.nucleoFamiliare = {
        nome: '',
        componenti: [],
      };
    } else {
      $.ajax({
        url: '/nucleoFamiliare/'+this.id_nucleo_familiare+'/json',
        method: 'get',
        async: false,
        success: function (response) {
          this.nucleoFamiliare = response.nucleoFamiliare;
        }.bind(this),
      });
    }
  },
  mounted () {
    if(!this.creazione) {
        $.fn.dataTable.moment('DD-MM-YYYY');
        this.tableComponenti = $(this.$refs.tableComponenti).DataTable( {
            language: {
              url: '/js/dataTables/Italian.json',
              searchPlaceholder: 'Cerca...',
              buttons: { colvis: 'Colonne'}
            },
            dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
            buttons: ['colvis','excel','csv'], //Buttons
            "responsive": true,
            "ordering": true,
            "searching": true,
            "bLengthChange": false,
            "pageLength": 50
        });
        this.updateComponenti();
        this.updateSelectComponenti();
    }
  },
  methods: {
    salva () {
      var data = {
        _token: this.token,
        nome: this.nucleoFamiliare.nome,
      };
      if (this.$refs.selectUnitaCondominiale.unitaCondominiale !== 0)
      {
        data['id_unita_condominiale'] = this.$refs.selectUnitaCondominiale.unitaCondominiale;
        if (this.data_inizio !== null)
          data['data_inizio'] = this.data_inizio;
        if (this.data_fine !== null)
          data['data_fine'] = this.data_fine;
      }
      $.post('/nucleoFamiliare/'+this.id_nucleo_familiare+'/modifica', data, function (response) {
        alert('Modifica effettuata');
        window.location.reload();
      }.bind(this)).fail(function (response) {
        this.errors = response.responseJSON.errors;
      }.bind(this));
    },
    crea () {
      var data = {
        _token: this.token,
        nome: this.nucleoFamiliare.nome,
      };
      if (this.$refs.selectUnitaCondominiale.unitaCondominiale !== 0)
      {
        data['id_unita_condominiale'] = this.$refs.selectUnitaCondominiale.unitaCondominiale;
        if (this.data_inizio !== null)
          data['data_inizio'] = this.data_inizio;
        if (this.data_fine !== null)
          data['data_fine'] = this.data_fine;
      }
      $.post('/nucleoFamiliare/crea', data, function (response) {
        alert('Creazione effettuata');
        window.location.replace("/nucleoFamiliare/"+response.nucleoFamiliare.id+"/singolo");
      }.bind(this)).fail(function (response) {
        this.errors = response.responseJSON.errors;
      }.bind(this));
    },
    updateComponenti () {
      $.ajax({
        url: '/nucleoFamiliare/'+this.id_nucleo_familiare+'/json',
        method: 'get',
        async: false,
        success: function (response) {
          this.nucleoFamiliare = response.nucleoFamiliare;
        }.bind(this),
      });
      this.tableComponenti.clear();
      this.nucleoFamiliare.componenti.forEach(function (comp) {
        var nameLink = '';
        if (comp.name)
          nameLink = `
          <a href="/utente/${comp.id}/singolo" target="_blank">${comp.name}</a>
          `;
        var cognomeLink = '';
        if (comp.cognome)
          cognomeLink = `
          <a href="/utente/${comp.id}/singolo" target="_blank">${comp.cognome}</a>
          `;
        var ragioneLink = '';
        if (comp.ragione_sociale)
          ragioneLink = `
          <a href="/utente/${comp.id}/singolo" target="_blank">${comp.ragione_sociale}</a>
          `;
        var row = [
          // comp.id,
          nameLink,
          cognomeLink,
          ragioneLink,
          comp.email,
          moment(String(comp.created_at)).format('DD-MM-YYYY'),
        ];
        if (this.role !== 'user')
          row.push(`<button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-account-minus rimuovi-button" data-id="${comp.id}"></button>`);
        this.tableComponenti.row.add(row);
      }.bind(this));
      this.tableComponenti.draw();
      $('button.rimuovi-button').on('click', function (e) {
        this.rimuoviComponente($(e.target).data('id'));
      }.bind(this));
    },
    apriCreaUtenteModale () {
      this.$refs.creaUtenteModale.apri();
    },
    rimuoviComponente (id) {
      if (confirm('Sei sicuro di voler procedere?')) {
        $.post(`/utente/${id}/rimuoviNucleo`, {_token: this.token}, function () {
          this.updateComponenti();
          this.updateSelectComponenti();
        }.bind(this));
      }
    },
    updateSelectComponenti () {
      var d = [];
      this.orfani.forEach(function (utente) {
        d.push({
          id: utente.id,
          // text: '' + utente.name + ' ' + utente.cognome,
          text: utente.preferred_name,
        });
      });
      $(this.$refs.selectComponente).select2({
        data: d,
        placeholder: 'Seleziona un utente',
        width: 'element',
        allowClear: true,
      });
    },
    aggiungiComponente () {
      $(this.$refs.modalComponente).modal('hide');
      var select = this.$refs.selectComponente.value;
      if (select == '') {
        alert('Selezionare un utente');
        return;
      }
      var data = {
        _token: this.token,
        id_user: select,
      };
      $.post('/nucleoFamiliare/' + this.id_nucleo_familiare + '/aggiungiComponente', data, function () {
        alert('Aggiunta effettuata con successo');
        this.updateComponenti();
      }.bind(this)).fail(function () {
        alert('Problema durante l\'aggiunta');
        window.location.reload();
      });
    },
    toDate (date) {
      if (date)
        return moment(String(date)).format('DD-MM-YYYY');
      return '';
    },
    formInputClass (attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    orfani () {
      return this.utenti.filter(function (u) {
        return u.id_ruolo_utente == 5 && u.id_nucleo_familiare === null;
      });
    },
  },
}
</script>
