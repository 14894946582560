<template>
  <div>
    <div class="modal fade" role="dialog" aria-hidden="true" ref="modale">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Aggiunti Utente Esterno Interessato</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Chiudi">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <label>Nome</label>
                  <input type="text" :class="formInputClass('name')" v-model="name">
                  <span class="invalid-feedback" role="alert" v-if="errors['name'] !== undefined">
                    <strong>{{ errors['name'][0] }}</strong>
                  </span>
                </div>

                <div class="col-12">
                  <label>Cognome</label>
                  <input type="text" :class="formInputClass('cognome')" v-model="cognome">
                  <span class="invalid-feedback" role="alert" v-if="errors['cognome'] !== undefined">
                    <strong>{{ errors['cognome'][0] }}</strong>
                  </span>
                </div>

                <div class="col-12">
                  <label>Email</label>
                  <input type="text" :class="formInputClass('email')" v-model="email">
                  <span class="invalid-feedback" role="alert" v-if="errors['email'] !== undefined">
                    <strong>{{ errors['email'][0] }}</strong>
                  </span>
                </div>

                <div class="col-12">
                  <label>Telefono</label>
                  <input type="text" :class="formInputClass('telefono')" v-model="telefono">
                  <span class="invalid-feedback" role="alert" v-if="errors['telefono'] !== undefined">
                    <strong>{{ errors['telefono'][0] }}</strong>
                  </span>
                </div>

                <div class="col-12">
                  <label>Cellulare</label>
                  <input type="text" :class="formInputClass('cellulare')" v-model="cellulare">
                  <span class="invalid-feedback" role="alert" v-if="errors['cellulare'] !== undefined">
                    <strong>{{ errors['cellulare'][0] }}</strong>
                  </span>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                <button type="button" class="btn bg-primary" @click="aggiungi()">Aggiungi</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn bg-primary w-100" @click="apriModale()">Aggiungi Utente Interessato</button>
  </div>
</template>

<script>
export default {
  props: ['token', 'id_annuncio'],
  data () {
    return {
      name: '',
      cognome: '',
      email: '',
      telefono: '',
      cellulare: '',
      errors: {},
    };
  },
  methods: {
    apriModale () {
      $(this.$refs.modale).modal('show');
    },
    aggiungi () {
      var data = {
        name: this.name,
        cognome: this.cognome,
        email: this.email,
        telefono: this.telefono,
        cellulare: this.cellulare,
        _token: this.token,
      };
      $.post(`/annuncio/${this.id_annuncio}/interessatoEsterno`, data, function () {
        $(this.$refs.modale).modal('hide');
        this.$emit('aggiunto');
      }.bind(this)).fail(function (response) {
        this.errors = response.responseJSON.errors;
      }.bind(this));

    },
    formInputClass (attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
  },
}
</script>
