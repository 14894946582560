<template>
  <div>

      <!-- Modal -->
      <div class="modal fade" role="dialog" aria-hidden="true" ref="modale">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Aggiungi condominio</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Chiudi">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <select ref="selectCondominio">
                <option value=""></option>
              </select>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
              <button type="button" class="btn btn-primary" @click="aggiungi">Aggiungi</button>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-striped" ref="tableCondomini">
          <thead>
              <tr>
                  <th>Condominio</th>
                  <th>Azioni</th>
              </tr>
          </thead>
          <tbody>
          </tbody>
      </table>
      <!-- Button trigger modal -->
      <button type="button" class="btn btn-primary" @click="apriModale">
        Aggiungi
      </button>
  </div>
</template>

<script>
export default {
  props: ['token', 'utente'],
  data () {
    return {
      condomini: null,
      condomini_assoc: null,
      table: null,
    };
  },
  beforeMount () {
    $.ajax({
      url: '/condominio/lista/json',
      method: 'get',
      async: true,
      success: function (response) {
        this.condomini = response.condomini;

        var d = [];
        this.condomini.forEach(function (c) {
          var dd = {
            id: c.id,
            text: c.nome,
          };
          d.push(dd);
        });

        $(this.$refs.selectCondominio).select2({
          data: d,
          placeholder: 'Selezionare un condominio',
        });
      }.bind(this)
    });
  },
  mounted () {
    this.table = $(this.$refs.tableCondomini).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.updateCondomini();
  },
  methods: {
    apriModale () {
      $(this.$refs.modale).modal('show');
    },
    updateCondomini () {
      $.get(`/collaboratore/${this.utente.id}/condomini/json`, function (response) {
        this.condomini_assoc = response.condomini;
        this.updateTable();
      }.bind(this));
    },
    updateTable () {
      this.table.clear();
      this.condomini_assoc.forEach(function (condominio) {
        var condominioLink = `
        <a href="/condominio/${condominio.id}/singolo" target="_blank">${condominio.nome}</a>
        `;
        this.table.row.add([
          condominioLink,
          `<button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-circle-off-outline disable-unita" data-id="${condominio.id}" ></button>`,
        ]);
      }.bind(this));
      this.table.draw();
      $('button.disable-unita').on('click', function (e) {
        this.disassociaCondominio($(e.target).data('id'));
      }.bind(this));
    },
    disassociaCondominio (id) {
        $.post(`/collaboratore/${this.utente.id}/disassociaCondominio/${id}`, {_token: this.token}, function () {
          this.resetSelect2();
          this.updateCondomini();
          $(this.$refs.selectCondominio).modal('hide');
        }.bind(this));
    },
    aggiungi () {
      var id_condominio = $(this.$refs.selectCondominio).val();
      if (id_condominio) {
        $.post(`/collaboratore/${this.utente.id}/associaCondominio/${id_condominio}`, {_token: this.token}, function () {
          this.resetSelect2();
          this.updateCondomini();
          $(this.$refs.selectCondominio).modal('hide');
        }.bind(this));
      }
    },
    resetSelect2 () {
      $(this.$refs.selectCondominio).val('').trigger('change');
    },
  },
}
</script>
