<template>
  <div>
    <div class="row">
      <div class="col-6">
        <label for="">Data di inizio</label>
        <daterange-picker v-model="dataInizio"></daterange-picker>
      </div>
      <div class="col-6">
        <label for="">Data di fine</label>
        <daterange-picker v-model="dataFine"></daterange-picker>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-home-floor-0"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Unità libere al "Data inizio"</span>
            <span class="info-box-number">{{ infos.libere }} unità</span>
          </div>
        </div>
      </div>
      <div class="col-md-4 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-home-floor-negative-1"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Unità liberate dal "Data Inizio" al "Data fine"</span>
            <span class="info-box-number">{{ infos.liberate }} unità</span>
          </div>
        </div>
      </div>
      <div class="col-md-4 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-home-floor-1"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Unità occupate dal "Data Inizio" al "Data fine"</span>
            <span class="info-box-number">{{ infos.occupate }} unità</span>
          </div>
        </div>
      </div>
    </div>

    <table class="table dataTable" id="dataTable" ref="tableAvvisi">
      <thead>
        <!--{{--FOREACH UNITA IMMOBILIARE VUOTA--}}-->
        <tr>
          <th>Condominio</th>
          <th>Unità Immobiliare</th>
          <th>Commerciale</th>
          <th>Tipo di annuncio creato</th>
          <!--{{--Vendita/Affitto/PattoRiscatto con link al rispettivo annuncio --}}-->
          <th>Contatore Visite</th>
          <th>Data fine contratto</th>
          <th>Data di creazione annuncio</th>
          <th>Tempo impiegato</th>
          <th>Data inizio nuovo contratto</th>
          <th>Tempo da annuncio a nuovo contratto</th>
          <th>Tempo da fine a inizio contratto</th>
          <!--{{--Data di creazione annuncio - Data di fine contratto --}}-->
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableAvvisi: null,
      unitaCondominiali: [],
      dataInizio: null,
      dataFine: null,
      infos: {
        libere: 0,
        liberate: 0,
        occupate: 0,
      },
    };
  },
  mounted () {
    // $.fn.dataTable.moment('DD-MM-YYYY');
    this.tableAvvisi = $(this.$refs.tableAvvisi).DataTable( {
      language: {
        url: '/js/dataTables/Italian.json',
        searchPlaceholder: 'Cerca...',
        buttons: { colvis: 'Colonne'}
      },
      dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
      buttons: ['colvis','excel','csv'], //Buttons
      "responsive": true,
      "ordering": true,
      "searching": true,
      "bLengthChange": false,
      "pageLength": 50
    });
    this.updateData();
  },
  methods: {
    updateData () {
      var data = {};
      if (this.dataInizio)
        data['dataInizio'] = this.dataInizio;
      if (this.dataFine)
        data['dataFine'] = this.dataFine;
      $.get('/statistiche/commerciali/json', data, function (response) {
        this.unitaCondominiali = response.unitaCondominiali;
        this.updateTable();
      }.bind(this));
      $.get('/statistiche/commerciali/infos/json', data, function (response) {
        this.infos = response;
      }.bind(this));
    },
    updateTable () {
      this.tableAvvisi.clear();
      this.unitaCondominiali.forEach(function (el) {
        var u = el['unita'];
        u.annuncio = el['annuncio'];
        var linkCondominio = `
          <a href="/condominio/${u.condominio.id}/singolo" target="_blank">${u.condominio.nome}</a>
        `;
        var linkUnita = `
          <a href="/unitaCondominiale/${u.id}/singolo" target="_blank">${u.nome}</a>
        `;
        var linkCommerciale = '';
        if (u.annuncio && u.annuncio.creatore)
          linkCommerciale = `
          <a href="/utente/${u.annuncio.creatore.id}/singolo" target="_blank">${u.annuncio.creatore.preferred_name}</a>
        `;
        var linkAnnuncio = '';
        if (u.annuncio)
          linkAnnuncio = `
            <a href="/annuncio/${u.annuncio.id}/singolo" target="_blank">${u.annuncio.tipologia.nome}</a>
          `;
        var dataFine = '';
        if (u.contratto && u.contratto.pivot.data_fine)
          dataFine = moment(String(u.contratto.pivot.data_fine)).format('DD/MM/YYYY');
        var dataInizio = '';
        if (u.nuovo_contratto && u.nuovo_contratto.pivot.data_inizio)
          dataInizio = moment(String(u.nuovo_contratto.pivot.data_inizio)).format('DD/MM/YYYY');
        var dataAnnuncio = '';
        if (u.data_annuncio)
          dataAnnuncio = moment(String(u.data_annuncio)).format('DD/MM/YYYY');
        this.tableAvvisi.row.add([
          linkCondominio,
          linkUnita,
          linkCommerciale,
          linkAnnuncio,
          u.annuncio ? u.annuncio.visite : '',
          //u.contratto ? moment(String(u.contratto.pivot.data_fine)).format('DD/MM/YYYY'): '',
          dataFine,
          dataAnnuncio,
          u.tempo_impiegato ? u.tempo_impiegato : '',
          dataInizio,
          u.tempo_nuovo_contratto ? u.tempo_nuovo_contratto : '',
          u.tempo_inutilizzo ? u.tempo_inutilizzo : '',
        ]);
      }.bind(this));
      this.tableAvvisi.draw();
    },
  },
  watch: {
    dataInizio () {
      this.updateData();
    },
    dataFine () {
      this.updateData();
    },
  },
}
</script>
