<template>
  <div>
    <table class="table table-striped dataTable" id="dataTable" ref="tableMedie">
      <thead>
        <tr>
          <th>Condominio</th>
          <th>Media Spese Condominiali</th>
          <th>Numero appartamenti</th>
          <th>Numero appartamenti da affittare</th>
          <th>Numero appartamenti affittati</th>
          <th>Numero utenti</th>
          <th>Media feedback</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      medie: null,
      tableMedie: null,
    };
  },
  mounted () {
    this.tableMedie = $(this.$refs.tableMedie).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.updateMedie();
  },
  methods: {
    updateMedie () {
      $.get('/statistiche/mediaCondomini/json', function (response) {
        this.medie = response.medie;
        this.updateTableMedie();
      }.bind(this));
    },
    updateTableMedie () {
      this.tableMedie.clear();
      this.medie.forEach(function (m) {
        var media = '0';
        if (m.media_spese_condominiali)
          media = Number(m.media_spese_condominiali).toFixed(2);
        var linkCondominio = `
        <a href="/condominio/${m.condominio.id}/singolo" target="_blank">${m.condominio.nome}</a>
        `;
        var media_feedback = '';
        if (m.media_feedback)
          media_feedback = Number(media_feedback).toFixed(2);
        this.tableMedie.row.add([
          linkCondominio,
          media,
          m.numero_appartamenti,
          m.numero_appartamenti-m.appartamenti_affittati,
          m.appartamenti_affittati,
          m.utenti,
          media_feedback
        ]);
      }.bind(this));
      this.tableMedie.draw();
    },
  },
}
</script>
