<template>
  <div class="container text-center">
      <h3>Grazie per il tuo feedback!</h3>
      <h4>In caso volessi cambiare la valutazione clicca su uno dei pulsanti sottostanti.</h4>
      <div style="display: flex;justify-content:center;">
          <a href="#" style="max-width:20%;" title="Molto Negativa" @click="feedback(1)">
              <img src="/img/feedback/1.png" id="feedback1" class="feedback" style="max-width:100%;">
          </a>
          <a href="#" style="max-width:20%;" title="Negativa" @click="feedback(2)">
              <img src="/img/feedback/2.png" id="feedback2" class="feedback" style="max-width:100%;">
          </a>
          <a href="#" style="max-width:20%;" title="Neutra" @click="feedback(3)">
              <img src="/img/feedback/3.png" id="feedback3" class="feedback" style="max-width:100%;">
          </a>
          <a href="#" style="max-width:20%;" title="Positiva" @click="feedback(4)">
              <img src="/img/feedback/4.png" id="feedback4" class="feedback" style="max-width:100%;">
          </a>
          <a href="#" style="max-width:20%;" title="Molto Positiva" @click="feedback(5)">
              <img src="/img/feedback/5.png" id="feedback5" class="feedback" style="max-width:100%;">
          </a>
      </div>
      <h4 class="my-4">oppure</h4>
      <div>
          <a href="/home" class="btn btn-secondary">Torna alla home</a>
      </div>
  </div>
</template>

<script>
export default {
  props: ["token", "feedback_get", "id_ticket"],
  data () {
    return {
    };
  },
  mounted () {
    if (this.feedback_get != 0)
      this.feedback(this.feedback_get);
  },
  methods: {
    feedback (voto) {
      $('.feedback').removeClass('active');
      $('#feedback'+voto).addClass('active');
      var data = {
        _token: this.token,
        feedback: voto,
      };
      $.post('/ticket/'+this.id_ticket+'/feedback', data);
    },
  },
}
</script>
