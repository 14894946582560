<template>
  <div class="container">
    <div class="row">
      <h1 class="m-0 text-dark my-3">{{ titleString }}</h1>
    </div>

    <aggiungi-documento-immobile :token="token" :filter="filter" v-if="canUpload"></aggiungi-documento-immobile>

    <div class="row mb-2">
      <div class="col-12 col-sm-3">
        <label for="select-condominio">Condominio</label>
        <select id="select-condominio" class="form-control" ref="selectCondominio">
          <option></option>
        </select>
      </div>
      <div class="col-12 col-sm-3">
        <label for="select-unita-condominiale">Unità Condominiale</label>
        <select id="select-unita-condominiale" class="form-control" ref="selectUnitaCondominiale">
          <option></option>
        </select>
      </div>
    </div>


    <!-- Datatable documenti condomini -->
    <table class="table table-striped dataTable" id="dataTable1" ref="tableDocumenti">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Condominio</th>
          <th>Unità Condominiale</th>
          <th>Download</th>
          <th>Tipologia</th>
          <th>Data Caricamento</th>
          <th>Visibilità</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <!-- /Datatable documenti condomini -->
  </div>
</template>
<script>
export default {
  props: ['token', 'filter', 'id_ruolo'],
  data () {
    return {
      filterCondominio:  '',
      filterUnitaCondominiale:  '',
      tableDocumenti: null,
      condomini: [],
      unitaCondominiali: [],
      documenti: null,
      documentiUC: null,
      translations: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    $.ajax({
      url: '/documento/lista/condominio/json',
      method: 'get',
      async: false,
      success: function (response) {
        this.documenti = response.documenti;

      }.bind(this),
    });
    $.ajax({
      url: '/documento/lista/unitaCondominiale/json',
      method: 'get',
      async: false,
      success: function (response) {
        this.documentiUC = response.documenti;
      }.bind(this),
    });
  },
  mounted () {
    $.fn.dataTable.moment('DD-MM-YYYY');
    this.setupSelectCondomini();
    this.setupSelectUnitaCondominiali();
    this.tableDocumenti = $(this.$refs.tableDocumenti).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        //dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        dom: '<".row"<"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        //buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.updateTableDocumenti();
  },
  methods: {
    setupSelectCondomini () {
      $.ajax({
        url: '/condominio/lista/json',
        method: 'get',
        async: false,
        success: function (response) {
          this.condomini = response.condomini;
          var d = [];
          this.condomini.forEach(function (c) {
            d.push({
              id: c.nome,
              text: c.nome,
            });
          });
          $(this.$refs.selectCondominio).select2({
            data: d,
            placeholder: 'Selezionare un condominio',
            width: 'element',
            allowClear: true,
          });
          $(this.$refs.selectCondominio).on('select2:select', function () {
            this.condominioChanged(this.$refs.selectCondominio.value);
          }.bind(this));
          $(this.$refs.selectCondominio).on('select2:clear', function () {
            this.condominioChanged('');
          }.bind(this));
        }.bind(this),
      });
    },
    setupSelectUnitaCondominiali () {
      $.ajax({
        url: '/unitaCondominiale/lista/json',
        method: 'get',
        async: false,
        success: function (response) {
          this.unitaCondominiali = response.unitaCondominiali;
          var d = [];
          this.unitaCondominiali.forEach(function (c) {
            d.push({
              id: c.nome,
              text: c.nome,
            });
          });
          $(this.$refs.selectUnitaCondominiale).select2({
            data: d,
            placeholder: 'Selezionare una unità condominiale',
            width: 'element',
            allowClear: true,
          });
          $(this.$refs.selectUnitaCondominiale).on('select2:select', function () {
            this.unitaCondominialeChanged(this.$refs.selectUnitaCondominiale.value);
          }.bind(this));
          $(this.$refs.selectUnitaCondominiale).on('select2:clear', function () {
            this.unitaCondominialeChanged('');
          }.bind(this));
        }.bind(this),
      });
    },
    condominioChanged (value) {
      this.filterCondominio = value;
    },
    unitaCondominialeChanged (value) {
      this.filterUnitaCondominiale = value;
    },
    updateTableDocumenti () {
      this.tableDocumenti.clear();
      this.documenti.forEach(function (struct) {
        var doc = struct.documento;
        var condominio = struct.condominio;
        var linkDownload = `
        <a href="/documenti/condominio/${doc.id}" target="_blank">Download</a>
        `;
        var linkCondominio = `
        <a href="/condominio/${condominio.id}/singolo" target="_blank">${condominio.nome}</a>
        `;
        var azioni = '';
        if ([1,6].includes(this.id_ruolo))
          azioni = `<button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever disable-documento" title="Elimina Documento" data-placement="top" data-id="${doc.id}"></button>`;
        this.tableDocumenti.row.add([
          doc.nome,
          linkCondominio,
          '',
          linkDownload,
          doc.tipologia.nome,
          moment(String(doc.created_at)).format('DD-MM-YYYY'),
          doc.privato ? 'Non visibile' : 'Visibile',
          azioni,
        ]);
      }.bind(this));
      this.documentiUC.forEach(function (struct) {
        var doc = struct.documento;
        var unita = struct.unita_condominiale;
        var linkDownload = `
        <a href="/documenti/condominio/${doc.id}" target="_blank">Download</a>
        `;
        var linkCondominio = `
        <a href="/condominio/${unita.condominio.id}/singolo" target="_blank">${unita.condominio.nome}</a>
        `;
        var linkUnita = `
        <a href="/unitaCondominiale/${unita.id}/singolo" target="_blank">${unita.nome}</a>
        `;
        var azioni = '';
        var visStr = doc.privato ? 'Rendi visibile' : 'Rendi non visibile';
        if (doc.id_tipologia_documento_immobiliare == 1) // catastale
        {
          if ([1,6].includes(this.id_ruolo))
            azioni = `
              <button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever toggle-visibilita" title="${visStr}}" data-id="${doc.id}"></button>
              <button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever disable-documento" title="Elimina Documento" data-id="${doc.id}"></button>`;
        }
        else
        {
          if ([1,6,8].includes(this.id_ruolo))
            azioni = `
              <button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever toggle-visibilita" title="${visStr}" data-id="${doc.id}"></button>
              <button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever disable-documento" title="Elimina Documento" data-id="${doc.id}"></button>`;
        }
        this.tableDocumenti.row.add([
          doc.nome,
          linkCondominio,
          linkUnita,
          linkDownload,
          doc.tipologia.nome,
          moment(String(doc.created_at)).format('DD-MM-YYYY'),
          doc.privato ? 'Non visibile' : 'Visibile',
          azioni,
        ]);
      }.bind(this));
      var coltipo = this.tableDocumenti.columns().header().filter((x) => x.innerHTML == 'Tipologia')[0].cellIndex;
      if (this.filter == 'catastale')
        this.tableDocumenti.column(coltipo).search('catastale');
      if (this.filter == 'urbanistico')
        this.tableDocumenti.column(coltipo).search('documento');
      this.tableDocumenti.draw();
      $('button.disable-documento').on('click', function (e) {
        this.disableDocumento($(e.target).data('id'));
      }.bind(this));

      $('button.toggle-visibilita').on('click', function (e) {
        this.toggleVisibilita($(e.target).data('id'));
      }.bind(this));
    },
    disableDocumento (id) {
      if (confirm('Sei sicuro di voler procedere?')) {
        $.post(`/documenti/condominio/${id}/disabilita`, {_token: this.token}, function (response) {
          window.location.reload();
        }.bind(this));
      }
    },
    toggleVisibilita (id) {
      if (confirm('Sei sicuro di voler procedere?')) {
        $.post(`/documenti/condominio/${id}/toggleVisibilita`, {_token: this.token}, function (response) {
          window.location.reload();
        }.bind(this));
      }
    },
    __(word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    titleString () {
      if (this.filter) {
        if (this.filter == 'catastale')
          return 'Documenti Catastali';
        if (this.filter == 'urbanistico')
          return 'Documenti';
      }
      else
        return 'Documenti Immobili';
    },
    canUpload () {
      if ([1,6,8].includes(this.id_ruolo))
        return true;
      if (this.id_ruolo == 2) {
        return this.filter == 'catastale';
      }
      return false;
    },
  },
  watch: {
    filterCondominio () {
      if (this.tableDocumenti) {
        var col = this.tableDocumenti.columns().header().filter((x) => x.innerHTML == 'Condominio')[0].cellIndex;
        this.tableDocumenti.column(col).search(this.filterCondominio).draw();
      }
    },
    filterUnitaCondominiale () {
      if (this.tableDocumenti) {
        var col = this.tableDocumenti.columns().header().filter((x) => x.innerHTML == 'Unità Condominiale')[0].cellIndex;
        this.tableDocumenti.column(col).search(this.filterUnitaCondominiale).draw();
      }
    },
  }
}
</script>
