<template>
  <div class="container">
    <h1 class="m-0 text-dark mb-3">{{ __('translation.lista_nuclei_familiari') }}</h1>
    <!--{{-- <h2>Condominio {{ $count }} - Amministratore {{ $count }}</h2> --}}-->

    <div class="row">
        <div class="col-md-4 p-1">
            <div class="info-box bg-primary">
                <span class="info-box-icon"><i class="mdi mdi-account"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Condomini gestiti:</span>
                    <span class="info-box-number">{{ ncondomini }}</span>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-4 p-1">
            <div class="info-box bg-primary">
                <span class="info-box-icon"><i class="mdi mdi-account-check"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Utenti verificati</span>
                    <span class="info-box-number">{{ nverificati }}</span>
                </div>
            </div>
        </div> -->
        <div class="col-md-4 p-1">
            <div class="info-box bg-primary">
                <span class="info-box-icon"><i class="mdi mdi-cancel"></i></span>
                <div class="info-box-content">
                    <span class="info-box-text">Utenti disabilitati</span>
                    <span class="info-box-number">{{ nbloccati }}</span>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      ncondomini: 0,
      nverificati: 0,
      nbloccati: 0,
      nutenti: 0,
      translations: null
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    $.ajax({
      url: '/schedaInfo/json',
      method: 'get',
      async: false,
      success: function (response) {
        this.ncondomini = response.ncondomini;
        this.nverificati = response.nverificati;
        this.nbloccati = response.nbloccati;
        this.nutenti = response.nutenti;
      }.bind(this),
    });
  },
  methods: {
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
  },
}
</script>
