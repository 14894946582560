<template>
  <div class="container">
    <h1 class="m-0 text-dark mb-3">Inserzione - {{ annuncio.unita_condominiale.nome }}</h1>

    <div class="row pb-4">
      <div class="col-12 col-sm-6">
        <div class="main-carousel" v-if="annuncio.foto.length > 0">
          <img v-for="foto in annuncio.foto" :key="foto.id" :alt="foto.nome" :data-flickity-lazyload="'/foto/'+foto.id" class="carousel-image">
        </div>
        <div v-else>
          <img src="/img/img_missing.jpg" class="thumbnail w-100">
        </div>
      </div>

      <div class="col-12 col-sm-6">
        <form action="" class="row">
            <div class="col-12 col-sm-8">
                <label for="titolo">Titolo</label>
                <input id="titolo" name="titolo" class="form-control" :class="formInputClass('titolo')" type="text" value="" placeholder="Titolo"  v-model="annuncio.titolo" :disabled="!canModify" >
                <span class="invalid-feedback" role="alert" v-if="errors['titolo'] !== undefined">
                  <strong>{{ errors['titolo'][0] }}</strong>
                </span>
            </div>
            <div class="col-12 col-sm-6">
              <label for="prezzo">Prezzo</label>
              <input id="prezzo" name="prezzo" class="form-control" :class="formInputClass('prezzo')" type="number" value="" placeholder="Prezzo"  v-model="annuncio.prezzo" :disabled="!canModify" >
                <span class="invalid-feedback" role="alert" v-if="errors['prezzo'] !== undefined">
                  <strong>{{ errors['prezzo'][0] }}</strong>
                </span>
            </div>
            <div class="col-12 col-sm-6">
              <label for="tipologia">Tipologia</label>
              <select id="tipologia" name="tipologia" class="form-control" :class="formInputClass('id_tipologia_annuncio')" type="number" value="" placeholder="Tipologia"  v-model="annuncio.id_tipologia_annuncio" :disabled="!canModify" >
                <option v-for="t in tipologie" :key="t.id" :value="t.id">{{ t.nome }}</option>
              </select>
              <span class="invalid-feedback" role="alert" v-if="errors['id_tipologia_annuncio'] !== undefined">
                <strong>{{ errors['id_tipologia_annuncio'][0] }}</strong>
              </span>
            </div>
            <div class="col-12 mb-2">
                <label for="descrizione">Descrizione</label>
                <textarea name="descrizione" :class="formInputClass('descrizione')" v-model="annuncio.descrizione" cols="30" rows="10" :disabled="!canModify" ></textarea>
                <!--<input :class="formInputClass('descrizione')" type="text" value="" placeholder="Descrizione" v-model="annuncio.descrizione">-->
                <span class="invalid-feedback" role="alert" v-if="errors['descrizione'] !== undefined">
                    <strong>{{ errors['descrizione'][0] }}</strong>
                </span>
            </div>
            <template v-if="canModify">
              <div class="offset-sm-6 col-6 col-sm-3">
                  <button type="button" class="btn bg-primary w-100" @click="salva">Salva</button>
              </div>
              <div class="col-6 col-sm-3" v-if="annuncio.visibile">
                  <button type="button" class="btn bg-primary w-100" @click="nascondi">Nascondi</button>
              </div>
              <div class="col-6 col-sm-3" v-else>
                  <button type="button" class="btn bg-primary w-100" @click="mostra">Rendi visibile</button>
              </div>
            </template>
        </form>
      </div>
    </div>

    <!-- Tabelle -->
    <hr class="pt-4 mt-4">
    <div class="row">
      <div class="col-12 col-sm-6">
        <a class="btn btn-secondary w-100 mb-4" :href="'/annunci/'+annuncio.id+'/singolo'">Visualizza inserzione pubblica</a>
      </div>
      <div class="col-12 col-sm-6">
        <a class="btn btn-secondary w-100 mb-4" :href="'/unitaCondominiale/'+annuncio.unita_condominiale.id+'/singolo'">Vai all'unità immobiliare</a>
      </div>
      <div class="col-12 col-sm-6 mb-4">
        <lista-interessati-interni :token="token" :id_annuncio="id_annuncio"></lista-interessati-interni>
      </div>
      <div class="col-12 col-sm-6 mb-4">
        <lista-interessati-esterni :token="token" :id_annuncio="id_annuncio"></lista-interessati-esterni>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['token', 'id_annuncio', 'id_ruolo'],
  data () {
    return {
      annuncio: null,
      errors: {},
      tipologie: null,
      translations: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    $.get('/annuncio/tipologie/json', function (response) {
      this.tipologie = response.tipologie;
    }.bind(this));
    $.ajax({
      url: `/annuncio/${this.id_annuncio}/json`,
      method: 'get',
      async: false,
      success: function (response) {
        this.annuncio = response.annuncio;
      }.bind(this),
    });
  },
  methods: {
    salva () {
      var data = {
        _token: this.token,
        titolo: this.annuncio.titolo,
        descrizione: this.annuncio.descrizione,
        prezzo: this.annuncio.prezzo,
        prezzo_visibile: 1,
        errors: {},
        id_tipologia_annuncio: this.annuncio.id_tipologia_annuncio,
      };
      $.post(`/annuncio/${this.id_annuncio}/modifica`, data, function (response) {
        alert('Modifica effettuata');
        window.location.reload();
      }).fail(function (response) {
        this.errors = response.responseJSON.errors;
      }.bind(this));
    },
    nascondi () {
      var data = {
        _token: this.token
      };
      $.post(`/annuncio/${this.id_annuncio}/nascondi`, data).always(function () {
        window.location.reload();
      });
    },
    mostra () {
      var data = {
        _token: this.token
      };
      $.post(`/annuncio/${this.id_annuncio}/mostra`, data).always(function () {
        window.location.reload();
      });
    },
    formInputClass (attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    canModify () {
      return [1,6,8].includes(this.id_ruolo);
    },
  },
}
</script>
