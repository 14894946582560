<template>
  <div>
    <h4>Lista Tickets</h4>

    <!-- Datatable documenti -->
    <table class="table table-striped dataTable" id="dataTable" ref="tableDocumenti">
      <thead>
        <tr>
          <th>Id</th>
          <th>Titolo</th>
          <th>Creatore</th>
          <th>Destinatario</th>
          <th>Condominio</th>
          <th>Unità Condominiale</th>
          <th>Stato</th>
          <th>N. Risposte</th>
          <th>Creato il</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['collaboratore', 'token'],
  data () {
    return {
      tickets: [],
      tableDocumenti: null,
    };
  },
  mounted () {
    $.fn.dataTable.moment('DD-MM-YYYY');
    this.tableDocumenti = $(this.$refs.tableDocumenti).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.updateTickets();
  },
  methods: {
    updateTickets () {
      $.get(`/collaboratore/${this.collaboratore.id}/tickets/json`, function (response) {
        this.tickets = response.tickets;
        this.tableDocumenti.clear();
        this.tickets.forEach(function (ticket) {
          var linkTitolo = `
            <a href="/ticket/${ticket.id}/singolo" target="_blank">${ticket.titolo}</a>
          `;
          this.tableDocumenti.row.add([
            ticket.id,
            linkTitolo,
            ticket.creatore.name + ' ' + ticket.creatore.cognome,
            ticket.destinatario.name + ' ' + ticket.destinatario.cognome,
            ticket.condominio.nome,
            ticket.unita_condominiale.nome,
            ticket.stato.nome,
            ticket.posts.length,
            moment(String(ticket.created_at)).format('DD-MM-YYYY'),
          `<button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever disable-documento" title="Elimina Ticket" data-id="${ticket.id}"></button>`,
          ]);
        }.bind(this));
        this.tableDocumenti.draw();
        $('button.disable-documento').on('click', function (e) {
          this.rimuoviCollaboratore($(e.target).data('id'));
        }.bind(this));
      }.bind(this));
    },
    rimuoviCollaboratore (id_ticket) {
      $.post(`/ticket/${id_ticket}/collaboratore/rimuovi`, {
        _token: this.token,
      }, function () {
        alert('Rimozione effettuata');
        window.location.reload();
      }).fail(function () {
        alert('Permessi non sufficienti');
      });
    },
  },
}
</script>
