<template>
  <div>
    <div class="row">
      <div class="col-6">
        <label for="">Data di inizio</label>
        <daterange-picker v-model="dataInizio"></daterange-picker>
      </div>
      <div class="col-6">
        <label for="">Data di fine</label>
        <daterange-picker v-model="dataFine"></daterange-picker>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-map-marker-alert"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Ticket aperti</span>
            <span class="info-box-number">{{ infos.aperti }} ticket</span>
          </div>
        </div>
      </div>
      <div class="col-md-3 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-map-marker-check"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Ticket risolti</span>
            <span class="info-box-number">{{ infos.risolti }} ticket</span>
          </div>
        </div>
      </div>
      <div class="col-md-3 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-clock-check"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Durata media</span>
            <!--{{--Tempo medio che passa dall'apertura del ticket alla chiusura,-->
            <!--contare ovviamente solo ticket aperti e chiusi all'interno dell'intervallo date selezionato--}}-->
            <span class="info-box-number">{{ infos.durata_media }} giorni</span>
          </div>
        </div>
      </div>
      <div class="col-md-3 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-clock-alert"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Tempo medio di lavorazione</span>
            <!--{{--Tempo medio in giorni che passa dall'apertura del ticket all'assegnazione di un collaboratore--}}-->
            <span class="info-box-number">{{ infos.tempo_medio_lavorazione }} giorni</span>
          </div>
        </div>
      </div>
    </div>

    <table class="table dataTable" id="dataTable" ref="tableAvvisi">
      <thead>
        <!--{{--FOREACH UNITA IMMOBILIARE VUOTA--}}-->
        <tr>
          <th>Condominio</th>
          <th>Unità Immobiliare</th>
          <th>Data apertura ticket</th>
          <th>Stato ticket</th>
          <th>Utente</th>
          <!--{{--Utente che ha aperto il ticket --}}-->
          <th>Facility Manager</th>
          <th>Collaboratore assegnato</th>
          <th>Data assegnazione collaboratore</th>
          <th>Data in lavorazione</th>
          <th>Data chiusura ticket</th>
          <th>Tempo assegnazione (giorni)</th>
          <!--{{--Data di creazione ticket - Data in lavorazione --}}-->
          <th>Tempo risoluzione (giorni)</th>
          <!--{{--Data in lavorazione - Data di chiusura ticket --}}-->
          <th>Tempo complessivo (giorni)</th>
          <!--{{--Data di creazione ticket - Data di chiusura ticket --}}-->
          <th>Feedback</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dataInizio: null,
      dataFine: null,
      manutenzioni: [],
      tableAvvisi: null,
      infos: {
        'aperti': 0,
        'risolti': 0,
        'durata_media': 0,
        'tempo_medio_assegnazione': 0,
      },
    };
  },
  mounted () {
    $.fn.dataTable.moment('DD-MM-YYYY');
    this.tableAvvisi = $(this.$refs.tableAvvisi).DataTable( {
      language: {
        url: '/js/dataTables/Italian.json',
        searchPlaceholder: 'Cerca...',
        buttons: { colvis: 'Colonne'}
      },
      dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
      buttons: ['colvis','excel','csv'], //Buttons
      "responsive": true,
      "ordering": true,
      "searching": true,
      "bLengthChange": false,
      "pageLength": 50
    });
    this.updateData();
  },
  methods: {
    updateData () {
      var data = {};
      if (this.dataInizio != null)
        data['dataInizio'] = this.dataInizio;
      if (this.dataFine != null)
        data['dataFine'] = this.dataFine;
      $.get('/statistiche/manutenzione/json', data, function (response) {
        this.manutenzioni = response.manutenzioni;
        this.infos = response.infos;
        this.updateTable();
      }.bind(this))
    },
    updateTable () {
      this.tableAvvisi.clear();
      this.manutenzioni.forEach(function (m) {
        var linkCollaboratore = '';
        var dataCollaboratore = '';
        if (m.collaboratore) {
          linkCollaboratore = this.linkUtente(m.collaboratore);
          dataCollaboratore = moment(String(m.data_aggiunta_collaboratore)).format('DD-MM-YYYY');
        }
        var linkCondominio = `
        <a href="/condominio/${m.condominio.id}/singolo" target="_blank">${m.condominio.nome}</a>
        `;
        var linkUnita = `
        <a href="/unitaCondominiale/${m.unita_condominiale.id}/singolo" target="_blank">${m.unita_condominiale.nome}</a>
        `;
        var linkStato = `
        <a href="/ticket/${m.id}/singolo" target="_blank">${m.stato.nome}</a>
        `;
        var feedback = "Nessun Feedback";
        if(m.feedback!=null)
        {
          feedback = `<img src="/img/feedback/${m.feedback}.png" style="max-width:100%; max-height:1.5rem;">`;
        }
        var data_chiusura = '';
        if (m.data_chiusura)
          data_chiusura = moment(String(m.data_chiusura)).format('DD-MM-YYYY');
        var data_in_lavorazione = '';
        if (m.data_in_lavorazione)
          data_in_lavorazione = moment(String(m.data_in_lavorazione)).format('DD-MM-YYYY');
        this.tableAvvisi.row.add([
          linkCondominio,
          linkUnita,
          moment(String(m.created_at)).format('DD-MM-YYYY'),
          linkStato,
          this.linkUtente(m.creatore),
          this.linkUtente(m.destinatario),
          linkCollaboratore,
          dataCollaboratore,
          data_in_lavorazione,
          data_chiusura,
          m.tempo_lavorazione,
          m.tempo_lavorazione_risoluzione,
          m.tempo_complessivo,
          feedback,
        ]);
      }.bind(this));
      this.tableAvvisi.draw();
    },
    linkUtente (u) {
      return `
      <a href="/utente/${u.id}/singolo" target="_blank">${u.preferred_name}</a>
      `;
    },
  },
  watch: {
    dataInizio () {
      this.updateData();
    },
    dataFine () {
      this.updateData();
    },
  },
}
</script>
