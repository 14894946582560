<template>
<div class="container">
    <h1 class="m-0 text-dark mb-3">{{ __('translation.lista_utenti') }}</h1>
    <button type="button" class="btn btn-primary" @click="salva()">Segna come verificati</button>

    <table class="table table-striped dataTable" id="dataTable" ref="tableUser">
        <thead>
            <tr>
                <th>Verifica</th>
                <th>Id</th>
                <th>Nucleo Familiare</th>
                <th>Nome</th>
                <th>Cognome</th>
                <th>Telefono</th>
                <th>Email</th>
                <th>Verificato</th>
                <th>Data Nascita</th>
                <th>Creato il</th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </table>
</div>
</template>
<script>
export default {
  props: ['token'],
  data () {
    return {
      translations: null,
      tableUser: null,
      utenti: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
  },
  mounted () {
    this.tableUser = $(this.$refs.tableUser).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.updateUtenti();
  },
  methods: {
    updateUtenti () {
      $.ajax({
        url: '/utente/lista/json',
        method: 'get',
        async: false,
        success: function (response) {
          this.utenti = response.utenti;
        }.bind(this),
      });
      this.tableUser.clear();
      this.utentiNonVerificati.forEach(function (utente) {
        if (utente.nucleo_familiare)
          var linkNucleo = `
          <a href="/nucleoFamiliare/${utente.id_nucleo_familiare}/singolo" target="_blank">${utente.nucleo_familiare.nome}</a>
          `;
        else
          var linkNucleo = `
          Nessuno
          `;
        var link = `
        <a href="/utente/${utente.id}/singolo">${utente.name}</a>
        `;
        var check = `
        <input type="checkbox" class="form-control" value="${utente.id}" name="checks[]">
        `;
        this.tableUser.row.add([
          check,
          utente.id,
          linkNucleo,
          link,
          utente.cognome,
          utente.telefono,
          utente.email,
          utente.verificato == 0 ? 'No' : 'Sì',
          utente.data_nascita,
          utente.created_at,
        ]);
      }.bind(this));
      this.tableUser.draw();
    },
    getUtentiChecked () {
      var a = $('input[name="checks[]"]').map(function () {
        if ($(this).prop('checked'))
          return $(this).val();
        else
          return -1;
      }).get();
      return a.filter(function (e) {
        return e != -1;
      });
    },
    salva () {
      var data = {
        _token: this.token,
        utenti: [],
      };
      this.getUtentiChecked().forEach(function (id) {
        data['utenti'].push({id: id});
      });
      $.post('/utente/massVerifica', data, function () {
        alert('Verifiche effettuate');
        this.updateUtenti();
      }.bind(this)).fail(function () {
        alert('Problema durante le verifiche');
      });
    },
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    utentiVerificati () {
      return this.utenti.filter(function (u) {
        return u.verificato == 1;
      });
    },
    utentiNonVerificati () {
      return this.utenti.filter(function (u) {
        return u.verificato == 0 && u.attivo == 1;
      });
    },
    percentualeUtentiVerificati () {
      return this.utentiVerificati.length/this.utenti.length*100;
    },
  },
}
</script>
