<template>
  <div class="modal fade" role="dialog" aria-hidden="true" ref="modalCaricamento">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Caricamento</h5>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-center">
            <div class="spinner-grow text-primary ml-auto mr-auto" role="status">
              <span class="sr-only">Caricamento</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      opened: false,
      toClose: false,
    };
  },
  methods: {
    apri () {
      $(this.$refs.modalCaricamento).modal({backdrop: 'static', keyboard: false});
    },
    chiudi () {
      setTimeout(function () {
        $(this.$refs.modalCaricamento).modal('hide');
      }.bind(this), 500);
    },
  },
}
</script>
