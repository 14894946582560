<template>
  <div class="row">
    <div class="col-12 col-sm-4">
      <label for="regione">Regione</label>
      <select name="regione" :class="formInputClass('regione')" v-model="regione" @change="regioneChanged" :disabled="disabled">
        <option v-for="regione in regioni" :key="regione.id" :value="regione.id">{{ regione.nome }}</option>
      </select>
      <span class="invalid-feedback" role="alert" v-if="errors['regione'] !== undefined">
        <strong>{{ errors['regione'][0] }}</strong>
      </span>
    </div>
    <div class="col-12 col-sm-4">
      <label for="provincia">Provincia</label>
      <select name="provincia" class="form-control" v-model="provincia" @change="provinciaChanged" :disabled="disabled">
        <option v-for="provincia in province" :key="provincia.id" :value="provincia.id">{{ provincia.nome }}</option>
      </select>
    </div>
    <div class="col-12 col-sm-4">
      <label for="citta">Città</label>
      <select name="citta" :class="formInputClass('id_citta')" v-model="citta" @change="cittaChanged" :disabled="disabled">
        <option v-for="citta in cittas" :key="citta.id" :value="citta.id">{{ citta.nome }}</option>
      </select>
      <span class="invalid-feedback" role="alert" v-if="errors['id_citta'] !== undefined">
        <strong>{{ errors['id_citta'][0] }}</strong>
      </span>
    </div>
    <div class="col-12 col-sm-4">
      <label for="cap">CAP</label>
      <select name="cap" :class="formInputClass('id_cap')" v-model="cap" :disabled="disabled">
        <option v-for="cap in caps" :key="cap.id" :value="cap.id">{{ cap.numero }}</option>
      </select>
      <span class="invalid-feedback" role="alert" v-if="errors['id_cap'] !== undefined">
        <strong>{{ errors['id_cap'][0] }}</strong>
      </span>
    </div>
    <div class="col-12 col-sm-8">
      <label for="indirizzo">Indirizzo</label>
      <input name="indirizzo" :class="formInputClass('indirizzo')" type="text" placeholder="Indirizzo" v-model="via" :disabled="disabled">
      <span class="invalid-feedback" role="alert" v-if="errors['indirizzo'] !== undefined">
        <strong>{{ errors['indirizzo'][0] }}</strong>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['token', 'indirizzo', 'creazione', 'disabled'],
  data () {
    return {
      errors: {},
      regioni: null,
      regione: null,
      province: null,
      provincia: null,
      cittas: null,
      citta: null,
      via: null,
      caps: null,
      cap: null,
    };
  },
  beforeMount () {
    this.loadRegioni(false);
  },
  mounted () {
    if (!this.creazione && this.indirizzo.id) {
      $.get('/indirizzo/getInfo', {id_indirizzo: this.indirizzo.id}, function (response) {
        this.regione = response.id_regione;
        this.via = response.indirizzo;
        this.loadProvince(false);
        this.provincia = response.id_provincia;
        this.loadCitta(false);
        this.citta = response.id_citta;
        this.loadCap(false);
        this.cap = response.id_cap;
      }.bind(this)).fail(function () {
        this.loadFailed();
      }.bind(this));
    }
  },
  methods: {
    loadFailed () {
      this.regione = null;
      this.via = null;
      this.provincia = null;
      this.citta = null;
      this.cap = null;
    },
    loadRegioni (async=true) {
      $.ajax({
        url: '/indirizzo/regioni',
        method: 'get',
        aync: async,
        success: function (response) {
          this.regioni = response.regioni;
        }.bind(this),
      });
    },
    loadProvince (async=true) {
      $.ajax({
        url: '/indirizzo/province',
        method: 'get',
        data: {
          id_regione: this.regione,
        },
        aync: async,
        success: function (response) {
          this.province = response.province;
        }.bind(this),
      });
    },
    loadCitta (async=true) {
      $.ajax({
        url: '/indirizzo/citta',
        method: 'get',
        data: {
          id_provincia: this.provincia,
        },
        aync: async,
        success: function (response) {
          this.cittas = response.citta;
        }.bind(this),
      });
    },
    loadCap (async=true) {
      $.ajax({
        url: '/indirizzo/cap',
        method: 'get',
        data: {
          id_citta: this.citta,
        },
        aync: async,
        success: function (response) {
          this.caps = response.caps;
        }.bind(this),
      });
    },
    regioneChanged () {
      this.loadProvince(true);
      this.cittas = null;
      this.cap = null;
    },
    provinciaChanged () {
      this.loadCitta(true);
      this.cap = null;
    },
    cittaChanged () {
      this.loadCap(true);
    },
    salva () {
      var data = {
        _token: this.token,
        indirizzo: this.via,
        id_citta: this.citta,
        id_cap: this.cap,
      };
      if (!this.creazione && this.indirizzo.id != undefined) { // Modifica
        if (this.isEmpty)
          return true;
        var success = false;
        data['id_indirizzo'] = this.indirizzo.id;
        $.ajax({
          url: '/indirizzo/modifica',
          method: 'POST',
          data: data,
          async: false,
          success: function () {
            success = true
          },
        }).fail(function (response) {
          this.errors = response.responseJSON.errors;
          success = false;
        }.bind(this));
        return success;
      } else { // Creazione
        if (this.isEmpty)
          return this.creazione ? 0 : true;
        var id = null;
        var req = $.ajax({
          url: '/indirizzo/crea',
          method: 'POST',
          data: data,
          async: false,
          success: function (response) {
            id = response.id;
            this.errors = {};
          }.bind(this),
        });
        req.fail(function (response) {
            this.errors = response.responseJSON.errors;
        }.bind(this))
        return id;
      }
    },
    formInputClass (attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
  },
  computed: {
    isEmpty () {
      return ((this.via == null || this.via == '') && this.citta == null && this.cap == null);
    },
    isRequired () {
      if (this.obbligatorio === false)
        return false;
      else
        return true;
    },
  },
}
</script>
