<template>
  <div class="container">
    <div class="modal fade" role="dialog" aria-hidden="true" ref="modalCaricamento">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Caricamento</h5>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center">
              <div class="spinner-grow text-primary ml-auto mr-auto" role="status">
                <span class="sr-only">Caricamento</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <h1 class="col-12">Import Affitti</h1>
      <div class="col-12 col-sm-4">
        <label for="file">Seleziona un file</label>
        <input type="file" ref="fileImport" >
      </div>
      <div class="col-12 col-sm-4">
        <label for="importbutton">Conferma importazione</label>
        <button class="form-control btn btn-primary" :class="{ 'bg-success': importOk }" @click="importa()">Importa</button>
      </div>
      <div class="col-12 col-sm-4">
        <label for="send">Invia al server</label>
        <button type="button" class="form-control btn btn-primary" @click="invia()" :disabled="!inviaPronto">Invia</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="errors.length > 0">
        <h2>Errori riscontrati:</h2>
        <table class="table table-striped dataTable">
          <thead>
            <tr>
              <th>Colonna</th>
              <th>Descrizione Errore</th>
              <th>Numero Documento</th>
              <th>Codice Fiscale</th>
              <th>Data</th>
              <th>Imponibile IVA</th>
              <th>Imposta IVA</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="error, i in errors" :key="i">
              <td>{{ error.row+1 }}</td>
              <td class="text-left">{{ error.error }}</td>
              <td>{{ data[error.row].numero_documento }}</td>
              <td>{{ data[error.row].codice_fiscale }}</td>
              <td>{{ data[error.row].data }}</td>
              <td>{{ data[error.row].imponibile_iva }}</td>
              <td>{{ data[error.row].imposta_iva }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['token'],
  data () {
    return {
      errors: [],
      fr: null,
      privFileData: null,
      data: {},
      importOk: false,
    };
  },
  methods: {
    importa () {
      var file = $(this.$refs.fileImport)[0].files[0];
      this.fr = new FileReader();
      this.fr.onload = this.fileLoaded;
      this.fr.readAsBinaryString(file);
    },
    fileLoaded () {
      var table = XLSX.read(this.fr.result, {type:'binary'});
      console.log(table);
      var sheet = table.Sheets[table.SheetNames[0]];
      this.privFileData = XLSX.utils.sheet_to_json(sheet, {header:1, raw: true, defval: null});
      console.log(this.privFileData);
      this.parse();
    },
    parse () {
      var header = this.parseHeader(this.privFileData[0]);

      var file = this.privFileData.slice(1);
      this.data = {};
      var insert = function (k1, k2, k3, v) {
        if (!(k1 in this.data))
          this.data[k1] = {};
        if (!(k2 in this.data[k1]))
          this.data[k1][k2] = {};
        this.data[k1][k2][k3] = v;
      }.bind(this);
      var get = function (k1, k2, k3, v) {
        return (this.data[k1] && this.data[k1][k2]) && this.data[k1][k2][k3];
      }.bind(this);
      file.forEach(function (row) {
        var numero_documento = row[header["Numero documento"]];
        if (numero_documento == undefined)
          return;
        numero_documento = numero_documento + '';
        var date = row[header["Data documento"]];
        if (date !== undefined) {
          // var strdate = moment(date, 'MM/DD/YY').format('YYYY-MM-DD');
          var strdate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
          if (strdate == 'Invalid date') {
            console.log(row);
          }
          date = strdate;
        }
        else
          return;

        var progetto = row[header["Progetto"]];
        if (progetto === undefined)
          progetto = ' ';

        var d = get(numero_documento, date, progetto);
        if (undefined === d)
          d = {
            numero_documento: numero_documento,
            data: date,
            progetto: progetto,
          };


        var imponibile = row[header["Imponibile"]];
        if (imponibile !== undefined && !isNaN(parseFloat(imponibile)))
          if (undefined !== d['imponibile_iva'])
            d['imponibile_iva'] += parseFloat(imponibile);
          else
            d['imponibile_iva'] = parseFloat(imponibile);

        var descrizione = row[header["Descrizione"]];
        if (descrizione !== undefined)
          if (undefined !== d['descrizione'])
            d['descrizione'] += descrizione + '\n';
          else
            d['descrizione'] = descrizione + '\n';

        var imponibile = row[header["Totale imponibile"]];
        if (imponibile !== undefined && !isNaN(parseFloat(imponibile)))
          if (undefined !== d['imponibile_iva'])
            d['imponibile_iva'] += parseFloat(imponibile);
          else
            d['imponibile_iva'] = parseFloat(imponibile);

        var imposta = row[header["IVA"]];
        if (imposta !== undefined && !isNaN(parseFloat(imposta)))
          if (undefined !== d['imposta_iva'])
            d['imposta_iva'] += parseFloat(imposta);
          else
            d['imposta_iva'] = parseFloat(imposta);

        var imposta = row[header["Totale IVA"]];
        if (imposta !== undefined && !isNaN(parseFloat(imposta)))
          if (undefined !== d['imposta_iva'])
            d['imposta_iva'] += parseFloat(imposta);
          else
            d['imposta_iva'] = parseFloat(imposta);

        var codice_fiscale = row[header["Codice Fiscale"]];
        if (codice_fiscale !== undefined)
          d['codice_fiscale'] = codice_fiscale;

        var data_pagamento = row[header["Data pagamento"]];
        if (data_pagamento !== undefined) {
          var strdate = moment(data_pagamento, 'DD/MM/YYYY').format('YYYY-MM-DD');
          if (strdate == 'Invalid date') {
            console.log(data_pagamento);
          }
          else
              d['data_pagamento'] = strdate;
        }
        var importo_pagato = row[header["Importo pagato"]];
        if (importo_pagato !== undefined && !isNaN(parseFloat(importo_pagato)))
          d['importo_pagato'] = importo_pagato;

        var dettaglio_pagato = row[header["Dettaglio pagato"]];
        if (dettaglio_pagato !== undefined && dettaglio_pagato != '-')
          d['dettaglio_pagato'] = this.parseDettaglioPagato(dettaglio_pagato);

        if (Object.keys(d).length != 0) {
          insert(numero_documento, date, progetto, d);
          // this.data.push(d);
        }

        /*
            0 - Data registrazione
            1 - Ditta
            2 - Codice cliente
            3 - Ragione sociale
            4 - Progetto
            5 - Partita IVA
            6 - Codice Fiscale
            7 - Numero documento
            8 - Sezionale
            9 - Data documento
            10 - Condizione pagamento
            11 - Condizione di pagamento
            12 - Totale imponibile
            13 - Totale IVA
            14 - Totale documento
            15 - Numero riga
            16 - Data pagamento
            17 - Importo pagato
        */

      }.bind(this));
      var accumulator = [];
      Object.entries(this.data).forEach(function (x) {
        Object.entries(x[1]).forEach(function (y) {
          Object.entries(y[1]).forEach(function (z) {
            accumulator = accumulator.concat(z[1]);
          });
        });
      });
      this.data = accumulator;
      //this.data = Object.entries(this.data).map((x) => x[1]);
      //// this.data = this.data.map(x => Object.entries(x).map(y => y[1])[0]);
      //this.data = this.data.map(x => Object.entries(x)[0][1]);
      //this.data = this.data.map(x => Object.entries(x)[0][1]);
      this.importOk = true;
    },
    parseHeader (header) {
      var a = {};
      header.forEach(function (el, key) {
        a[el] = key;
      });
      return a;
    },
    parseDettaglioPagato (dettaglioPagato) {

      const regex = /(\d*\.\d*) \((\d{1,2}\/\d{1,2}\/\d{1,4})\)/gm;
      const str = dettaglioPagato;
      let m;
      var dettagli = [];

      while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.

        var dett = {};
        m.forEach((match, groupIndex) => {
          if (groupIndex == 1)
            dett['importo'] = parseFloat(match);
          else if (groupIndex == 2)
            dett['data'] = moment(match, 'DD/MM/YYYY').format('YYYY-MM-DD');
          // console.log(`Found match, group ${groupIndex}: ${match}`);
        });
        dettagli.push(dett);
      }
      return dettagli;
    },
    invia () {
      var data = {
        fatture: JSON.stringify(this.data),
        _token: this.token,
      };
      console.log(data['fatture']);
      this.apriModaleCaricamento();
      $.post('/import/fatture', data, function (response) {
        this.chiudiModaleCaricamento();
        window.location.href = "/pagamento/lista";
      }.bind(this)).fail(function (response) {
        this.errors = response.responseJSON.errors;
        this.chiudiModaleCaricamento();
      }.bind(this));
    },
    apriModaleCaricamento () {
      $(this.$refs.modalCaricamento).modal({backdrop: 'static', keyboard: false});
    },
    chiudiModaleCaricamento () {
      $(this.$refs.modalCaricamento).modal('hide');
    },
  },
  computed: {
    inviaPronto () {
      return this.importOk && this.condominio !== null;
    },
  },
}
</script>
