<template>
<div class="container">
  <h1 class="m-0 text-dark mb-3">Segnala un guasto</h1>
  <div class="row">
    <div class="col-12">
      <form action="" class="row">

        <div class="col-12" v-if="selezioneUnitaCondominiale">
          <select-unita-condominiale ref="selectUnitaCondominiale"></select-unita-condominiale>
        </div>

        <div class="col-12">
          <label for="titolo">Titolo</label>
          <input id="titolo" name="titolo" class="form-control" :class="formInputClass('titolo')" type="text" value="" v-model="titolo" placeholder="Titolo">
          <span class="invalid-feedback" role="alert" v-if="errors['titolo'] !== undefined">
            <strong>{{ errors['titolo'][0] }}</strong>
          </span>
        </div>
        <!--<div class="col-2">
          <label for="data">Data</label>
          <input id="data" name="data" class="form-control" :class="formInputClass('data')" type="text" value="" placeholder="Data" disabled>
          <span class="invalid-feedback" role="alert" v-if="errors['data'] !== undefined">
            <strong>{{ errors['data'][0] }}</strong>
          </span>
        </div>-->
        <div class="col-12">
          <label for="descrizione">Descrizione</label>
          <!--<input id="descrizione" name="descrizione" class="form-control" :class="formInputClass('testo')" type="text" value="" v-model="testo" placeholder="Descrizione">-->
          <textarea name="descrizione" :class="formInputClass('testo')" v-model="testo" cols="30" rows="10"></textarea>
          <span class="invalid-feedback" role="alert" v-if="errors['testo'] !== undefined">
            <strong>{{ errors['testo'][0] }}</strong>
          </span>
        </div>
        <div class="col-12 row mt-2 mx-0">
          <div class="offset-sm-9 col-12 col-sm-3 mt-2">
            <button type="button" class="btn bg-primary w-100" @click="salva()">Invia</button>
          </div>
        </div>
      </form>
      <h4 class="text-dark">Aggiungi immagini per identificare meglio il problema (Opzionale)</h4>
    </div>
    <div id="uppy-upload" class="col-12">
    </div>
    <!--<tabella-documenti ref="tabellaDocumenti" title="Allega Documenti"></tabella-documenti>-->
  </div>
</div>
</template>

<script>
const Uppy = require('@uppy/core');
const Dashboard = require('@uppy/dashboard');
const Italiano = require('@uppy/locales/lib/it_IT');

export default {
  props: ['token', 'id_ruolo_utente'],
  data () {
    return {
      errors: {},
      titolo: '',
      testo: '',
      translations: null,
      uppy: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
  },
  mounted () {
    this.uppy = Uppy({
      autoProceed: false,
      locale: Italiano,
      restrictions: {
        allowedFileTypes: ['image/*', 'application/pdf'],
      }
    }).use(Dashboard, {
      hideUploadButton: true,
      inline: true,
      height: 300,
      target: '#uppy-upload',
      trigger: '#aggiungi-immagine',
    });
  },
  methods: {
    salva () {
      var formData = new FormData();
      var files = this.uppy.getFiles().map((x) => x.data).filter((x) => x.type == 'application/pdf');
      files.forEach(function (f, i) {
        formData.append(`files[${i}]`, f);
      });
      var foto = this.uppy.getFiles().map((x) => x.data).filter((x) => x.type.startsWith('image'));
      foto.forEach(function (f, i) {
        formData.append(`foto[${i}]`, f);
      });
      formData.append('titolo', this.titolo);
      formData.append('testo', this.testo);
      if (this.selezioneUnitaCondominiale)
      {
        var un = this.$refs.selectUnitaCondominiale.unitaCondominiale;
        if (un == 0)
        {
          alert('Selezionare un\'unità condominiale');
          return;
        }
        formData.append('id_unita_condominiale', un);
      }
      formData.append('_token', this.token);
      axios.post('/ticket/crea',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function (response) {
          var id = response.data.ticket.id;
          window.location.href = `/ticket/${id}/singolo`;
        }.bind(this)).catch(function (error) {
          if (error.response.status == 413) {
            this.errors = {'file': ['File troppo grande.']};
            alert('File troppo grande.');
          }
          else
            this.errors = error.response.data.errors;
        }.bind(this));

    },
    formInputClass(attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
    __(word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    selezioneUnitaCondominiale() {
      return [2,3,6,8].includes(this.id_ruolo_utente);
    },
  },
}
</script>
