<template>
  <div>
      <h4>{{ __('translation.lista_affitti') }}</h4>

      <!-- Datatable documenti -->
      <table class="table table-striped dataTable" id="dataTable" ref="tableAffitti">
          <thead>
              <tr>
                  <th>Condominio</th>
                  <th>Unità Condominiale</th>
                  <th>Data Inizio</th>
                  <th>Data Fine</th>
                  <th>In corso</th>
                  <th>Deposito Cauzionale</th>
                  <th>Data Deposito Cauzionale</th>
                  <th>Azioni</th>
              </tr>
          </thead>
          <tbody>
          </tbody>
      </table>
      <!-- /Datatable documenti -->

    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" ref="modaleDepositoCauzionale">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Deposito Cauzionale</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-12">
              <label for="importo">Importo</label>
              <input type="number" v-model="deposito" name="importo" :class="formInputClass('importo')">
              <span class="invalid-feedback" role="alert" v-if="errors['importo'] !== undefined">
                <strong>{{ errors['importo'][0] }}</strong>
              </span>
            </div>
            <div class="col-12">
              <label for="data_deposito">Data</label>
              <daterange-picker v-model="data_deposito" name="data_deposito" :class="formInputClass('data')" ref="daterange"></daterange-picker>
              <span class="invalid-feedback" role="alert" v-if="errors['data'] !== undefined">
                <strong>{{ errors['data'][0] }}</strong>
              </span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
            <button type="button" class="btn btn-primary" @click="salvaDepositoCauzionale()">Salva</button>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" ref="modaleDateContratto">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Date contratto</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-12">
              <label for="data_inizio">Data inizio</label>
              <input type="date" v-model="data_inizio" name="data_inizio" class="form-control" >
              <!--<daterange-picker v-model="data_inizio" name="data_inizio" class="form-control"></daterange-picker>-->
              <span class="invalid-feedback" role="alert" v-if="errors['data_inizio'] !== undefined">
                <strong>{{ errors['data_inizio'][0] }}</strong>
              </span>
            </div>
            <div class="col-12">
              <label for="data_fine">Data Fine</label>
              <input type="date" v-model="data_fine" name="data_fine" class="form-control" >
              <!--<daterange-picker v-model="data_fine" name="data_fine" class="form-control"></daterange-picker>-->
              <span class="invalid-feedback" role="alert" v-if="errors['data_fine'] !== undefined">
                <strong>{{ errors['data_fine'][0] }}</strong>
              </span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
            <button type="button" class="btn btn-primary" @click="salvaDateContratto()">Salva</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['token', 'nucleo', 'id_ruolo_utente'],
  data () {
    return {
      errors: {},
      affitti: null,
      tableAffitti: null,
      translations: null,
      deposito: 0,
      data_deposito: null,
      id_affitto_corrente: 0,
      data_inizio: null,
      data_fine: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
  },
  mounted () {
    this.tableAffitti = $(this.$refs.tableAffitti).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.updateAffitti();
    $(this.$refs.modaleDepositoCauzionale).on('hidden.bs.modal', function () {
      this.modaleClosed();
    }.bind(this));
    // this.updateTableAffitti();
  },
  methods: {
    updateAffitti () {
      $.ajax({
        url: `/nucleoFamiliare/${this.nucleo.id}/affitti/json`,
        method: 'get',
        async: true,
        success: function (response) {
          this.affitti = response.affitti;
          this.updateTableAffitti();
        }.bind(this),
      });
    },
    updateTableAffitti () {
      this.tableAffitti.clear();
      this.affitti.forEach(function (af) {
        var linkUnita = `
        <a href="/unitaCondominiale/${af.id}/singolo" target="_blank">${af.nome}</a>
        `;
        var linkCondominio = `
        <a href="/condominio/${af.condominio.id}/singolo" target="_blank">${af.condominio.nome}</a>
        `;
        var azioni = '';
        if (af.pivot.in_corso == 1 && this.canEndAffitto)
          azioni = `
          <button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever disable-affitto" title="Chiudi affitto" data-id="${af.id}"></button>
          <button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-puzzle-edit modifica-deposito" title="Modifica deposito" data-id="${af.id}" data-importo="${af.pivot.deposito_cauzionale}" data-data="${af.pivot.data_deposito_cauzionale}"></button>
          </button> <button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-folder-edit modifica-contratto" title="Modifica date contratto" data-id="${af.id}" data-data_inizio="${af.pivot.data_inizio}" data-data_fine="${af.pivot.data_fine}"></button>
          `;

        var dataInizio = '';
        if (af.pivot.data_inizio)
          dataInizio = moment(String(af.pivot.data_inizio)).format('DD-MM-YYYY');
        var dataFine = '';
        if (af.pivot.data_fine)
          dataFine = moment(String(af.pivot.data_fine)).format('DD-MM-YYYY');

        this.tableAffitti.row.add([
          linkCondominio,
          linkUnita,
          dataInizio,
          dataFine,
          af.pivot.in_corso ? 'Sì' : 'No',
          af.pivot.deposito_cauzionale ? parseFloat(af.pivot.deposito_cauzionale).toFixed(2) : 0,
          af.pivot.data_deposito_cauzionale ? moment(String(af.pivot.data_deposito_cauzionale)).format('DD-MM-YYYY') : '',
          azioni,
        ]);
      }.bind(this));
      this.tableAffitti.draw();
      $('button.disable-affitto').on('click', function (e) {
        this.disabilita($(e.target).data('id'));
      }.bind(this));
      $('button.modifica-deposito').on('click', function (e) {
        var id = $(e.target).data('id');
        var importo = $(e.target).data('importo');
        var data = $(e.target).data('data');
        this.modificaDeposito(id, importo, data);
      }.bind(this));
      $('button.modifica-contratto').on('click', function (e) {
        var id = $(e.target).data('id');
        var data_inizio = $(e.target).data('data_inizio');
        var data_fine = $(e.target).data('data_fine');
        this.modificaContratto(id, data_inizio, data_fine);
      }.bind(this));
    },
    disabilita (id) {
      if (confirm("Sei sicuro?")) {
        $.post(`/nucleoFamiliare/${this.nucleo.id}/disassociaUnitaCondominiale/${id}`, {_token: this.token}, function () {
          this.updateAffitti();
        }.bind(this));
      }
    },
    modificaDeposito (id, importo, data) {
      this.deposito = importo;
      this.data_deposito = data;
      this.$refs.daterange.setValue(data);
      this.id_affitto_corrente = id;
      $(this.$refs.modaleDepositoCauzionale).modal('show');
    },
    modaleClosed () {
    },
    salvaDepositoCauzionale () {
      var data = {
        _token: this.token,
        importo: this.deposito,
        data: this.data_deposito,
      };
      $.post(`/nucleoFamiliare/${this.nucleo.id}/deposito_cauzionale/${this.id_affitto_corrente}`, data, function () {
        window.location.reload();
      }.bind(this)).fail(function (response) {
        this.errors = response.responseJSON.errors;
      }.bind(this));
    },
    modificaContratto (id, data_inizio, data_fine) {
      this.data_inizio = data_inizio;
      this.data_fine = data_fine;
      this.id_affitto_corrente = id;
      $(this.$refs.modaleDateContratto).modal('show');
    },
    salvaDateContratto () {
      var data = {
        _token: this.token,
        data_inizio: this.data_inizio,
        data_fine: this.data_fine,
      };
      $.post(`/nucleoFamiliare/${this.nucleo.id}/modifica/${this.id_affitto_corrente}`, data, function () {
        window.location.reload();
      }.bind(this)).fail(function (response) {
        this.errors = response.responseJSON.errors;
      }.bind(this));
    },
    formInputClass (attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    canEndAffitto () {
      return [1,6].includes(this.id_ruolo_utente);
    },
  },
}
</script>
