<template>
  <div class="container">
  <h1 class="m-0 mb-3">Lista Collaboratori</h1>

    <div class="row">
      <div class="col-12 col-sm-3">
        <label for="filtroCondominio">Condominio</label>
        <select class="form-control" name="filtroCondominio" v-model="filtroCondominio">
          <option value=""></option>
          <option v-for="condominio in condomini" :key="condominio.id" :value="condominio.id">{{ condominio.nome }}</option>
        </select>
      </div>
    </div>

    <table class="table table-striped dataTable" id="dataTable" ref="tableCollaboratori">
      <thead>
        <tr>
          <th>Id</th>
          <th>Nome</th>
          <th>Cognome</th>
          <th>Condomini</th>
          <th>Telefono</th>
          <th>Professione</th>
          <th>Email</th>
          <th>N. Tickets</th>
          <th>Data Nascita</th>
          <th>Creato il</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <div class="row" v-if="canCreate">
        <div class="offset-sm-9 col-12 col-sm-3 mt-2">
            <a href="/collaboratore/crea" class="btn bg-primary w-100">Aggiungi nuovo collaboratore</a>
        </div>
    </div>
  </div>

</template>

<script>
export default {
  props: ['token', 'id_ruolo_utente'],
  data () {
    return {
      translations: null,
      collaboratori: null,
      filtroCondominio: '',
      condomini: [],
      tableCollaboratori: null,
    };
  },
  mounted () {
    // $.fn.dataTable.moment('DD-MM-YYYY');
    $.get('/condominio/lista/json', function (response){
      this.condomini = response.condomini;
    }.bind(this));
    $.get('/collaboratore/lista/json', function (response) {
      this.collaboratori = response.collaboratori;
      this.tableCollaboratori = $(this.$refs.tableCollaboratori).DataTable({
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
      });
      this.updateCollaboratori();
    }.bind(this));
  },
  methods: {
    updateCollaboratori () {
      this.tableCollaboratori.clear();
      this.collaboratori.forEach(function (col) {
        var linkNome = `
        <a href="/collaboratore/${col.id}/singolo" target="_blank">${col.name}</a>
        `;
        var professione = col.professione;
        if (professione != null)
          professione = professione.nome;
        else
          professione = '';
        /*
        if (col.condominio_collaboratore)
          var linkCondominio = `
          <a href="/condominio/${col.id_condominio_collaboratore}/singolo" target="_blank">${col.condominio_collaboratore.nome}</a>
          `;
        else
          var linkCondominio = '';
          */
        // col.condomini
        var condomini = [];
        col.condomini_collaboratore.forEach(function (condominio) {
          condomini.push(`
          <a href="/condominio/${condominio.id}/singolo" target="_blank">${condominio.nome}</a>
            `);
        });
        var data_nascita = '';
        if (col.data_nascita)
          data_nascita = moment(String(col.data_nascita)).format('DD-MM-YYYY');
        condomini = condomini.join(',');
        var btn = this.id_ruolo_utente == 7 ? `` : `<button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever disable-documento" title="Elimina Collaboratore" data-id="${col.id}"></button>`;
        this.tableCollaboratori.row.add([
          col.id,
          linkNome,
          col.cognome,
          condomini,
          col.telefono,
          professione,
          col.email,
          col.tickets_collaboratore.length,
          data_nascita,
          moment(String(col.created_at)).format('DD-MM-YYYY'),
          btn,
        ]);
      }.bind(this));
      this.tableCollaboratori.draw();
      $('button.disable-documento').on('click', function (e) {
        this.disabilita($(e.target).data('id'));
      }.bind(this));
    },
    disabilita (id_collaboratore) {
      $.post(`/utente/${id_collaboratore}/disabilita`, {_token: this.token}, function () {
        alert('Eliminazione effettuata');
        window.location.reload();
      }).fail(function () {
        alert('Errore durante l\'eliminazione');
      });
    },
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  watch: {
    filtroCondominio () {
      var col = this.tableCollaboratori.columns().header().filter((x) => x.innerHTML == 'Condomini')[0].cellIndex;
      this.tableCollaboratori.column(col).search(this.filtroCondominio).draw();
    },
  },
  computed: {
    canCreate () {
      return [1,6,8].includes(this.id_ruolo_utente);
    },
  },
}
</script>
