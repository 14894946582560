<template>
  <div class="mx-4 mx-sm-0 my-2 my-sm-0">
    <h4>{{ __('translation.lista_documenti') }}</h4>
    <!-- Datatable documenti -->
    <table class="table table-striped dataTable" id="dataTable" ref="tableDocumenti">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Scadenza</th>
          <th>Tipologia</th>
          <th>Download</th>
          <th>Data Caricamento</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <!-- /Datatable documenti -->

    <div class="row">
      <!-- Button trigger modal -->
      <div class="offset-sm-9 col-12 col-sm-3 mt-2" v-if="canUpload">
        <button class="btn bg-primary w-100" data-toggle="modal" data-target="#carica_documento">Carica nuovo documento</button>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="carica_documento" role="dialog" aria-labelledby="carica_documento" aria-hidden="true" ref="modalDocumento">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Carica nuovo documento</h5>
              <button type="button" class="close" aria-label="Close" @click="chiudi()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form action="">
                <label for="nome_documento">Nome Documento</label>
                <input id="nome_documento" name="nome_documento" :class="formInputClassDoc('nome')" type="text" placeholder="Nome Documento" v-model="nomeDocumento">
                <span class="invalid-feedback" role="alert" v-if="docErrors['nome'] !== undefined">
                  <strong>{{ docErrors['nome'][0] }}</strong>
                </span>

                <label for="tipo_documento" class="mt-4">Tipo Documento</label>
                <select id="tipo_documento" name="tipo_documento" :class="formInputClassDoc('tipologia')" type="file" v-model="tipoDocumentoSelezionato">
                  <option v-for="tipo in tipologieDocumentoUtente" :key="tipo.id" :value="tipo.id">{{ tipo.nome }}</option>
                </select>
                <span class="invalid-feedback" role="alert" v-if="docErrors['tipologia'] !== undefined">
                  <strong>{{ docErrors['tipologia'][0] }}</strong>
                </span>

                <label for="scadenza" class="mt-4">Scadenza</label>
                <daterange-picker v-model="scadenza" :class="formInputClassDoc('scadenza')" ref="scadenza"></daterange-picker>
                <span class="invalid-feedback" role="alert" v-if="docErrors['scadenza'] !== undefined">
                  <strong>{{ docErrors['scadenza'][0] }}</strong>
                </span>

                <label for="file_documento" class="mt-4">Carica File</label>
                <input id="file_documento" name="file_documento" :class="formInputClassDoc('file')" type="file" ref="fileDocumento">
                <span class="invalid-feedback" role="alert" v-if="docErrors['file'] !== undefined">
                  <strong>{{ docErrors['file'][0] }}</strong>
                </span>

              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="chiudi()">Chiudi</button>
              <button type="button" class="btn bg-primary" @click="salvaDocumento">Salva</button>
            </div>
          </div>
        </div>
      </div>
      <!-- /Modal -->
    </div>

  </div>
</template>
<script>
export default {
  props: ['token', 'utente', 'id_ruolo'],
  data () {
    return {
      docErrors: {},
      tableDocumenti: null,
      tipologieDocumentoUtente: null,
      tipoDocumentoSelezionato: null,
      nomeDocumento: '',
      documenti: null,
      scadenza: null,
      translations: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    $.get('/tipologieDocumentoUtente/json', function (response) {
      this.tipologieDocumentoUtente = response.tipologieDocumentoUtente;
    }.bind(this));
  },
  mounted () {
    // $.fn.dataTable.moment('DD-MM-YYYY');
    this.tableDocumenti = $(this.$refs.tableDocumenti).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.updateDocumenti();
  },
  methods: {
    chiudi () {
      $(this.$refs.modalDocumento).modal('hide');
      this.nomeDocumento = '';
      this.scadenza = null;
      this.$refs.scadenza.reset();
      this.tipoDocumentoSelezionato = null;
      $(this.$refs.fileDocumento).val('');
    },
    updateDocumenti () {
      $.get('/utente/'+this.utente.id+'/documenti/json', null, function (response) {
        this.documenti = response.documenti;
        this.updateTableDocumenti();
      }.bind(this));
    },
    updateTableDocumenti () {
      this.tableDocumenti.clear();
      this.documenti.forEach(function (doc) {
        var linkDownload = `
        <a href="/documenti/utente/${doc.id}" target="_blank">Download</a>
        `;
        var azioni = '';
        if (this.canDelete)
          azioni = `<button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever disable-documento" title="Elimina Documento" data-id="${doc.id}"></button>`;
        var created_at = moment(String(doc.created_at)).format('DD-MM-YYYY');
        if (doc.scadenza)
          var scadenza = moment(String(doc.scadenza)).format('DD-MM-YYYY');
        else
          var scadenza = '';
        this.tableDocumenti.row.add([
          // doc.id,
          doc.nome,
          scadenza,
          doc.tipologia.nome,
          linkDownload,
          created_at,
          azioni,
        ]);
      }.bind(this));
      this.tableDocumenti.draw();
      $('button.disable-documento').on('click', function (e) {
        this.disabilita($(e.target).data('id'));
      }.bind(this));
    },
    disabilita (id) {
      if (confirm('Sei sicuro di voler procedere?')) {
        $.post(`/documenti/utente/${id}/disabilita`, {_token: this.token}, function () {
          this.updateDocumenti();
        }.bind(this));
      }
    },
    salvaDocumento () {
      var formData = new FormData();
      formData.append('file', this.$refs.fileDocumento.files[0]);
      formData.append('nome', this.nomeDocumento);
      formData.append('tipologia', this.tipoDocumentoSelezionato);
      if (this.scadenza != null)
        formData.append('scadenza', this.scadenza);
      axios.post('/utente/'+this.utente.id+'/uploadDocumento',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function () {
          $(this.$refs.modalDocumento).modal('hide');
          alert('Documento salvato');
          this.nomeDocumento = '';
          this.scadenza = null;
          this.$refs.scadenza.reset();
          this.tipoDocumentoSelezionato = null;
          $(this.$refs.fileDocumento).val('');
          this.updateDocumenti();
          this.docErrors = {};
          // window.location.reload();
        }.bind(this)).catch(function (error) {
          if (error.response.status == 413)
            this.docErrors = {'file': ['File troppo grande.']};
          else
            this.docErrors = error.response.data.errors;
        }.bind(this));
    },
    formInputClassDoc (attr) {
      return { 'form-control': true, 'is-invalid': this.docErrors[attr] !== undefined };
    },
    __(word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    canUpload () {
      return [1,6,3,4,5].includes(this.id_ruolo);
    },
    canDelete () {
      return [1,6].includes(this.id_ruolo);
    },
  },
}
</script>
