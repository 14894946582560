<template>
<div class="container">
    <h1 class="m-0 text-dark mb-3">{{ __('translation.ticket_singolo') }}</h1>
    <div class="row">
        <div class="col-12 col-sm-3 img-container">
        <!--<img src="http://placehold.it/300x300/BBB/fff?text=Immagine" class="w-100" alt="Immagine Ticket">-->
          <div class="main-carousel" v-if="ticket.foto.length > 0">
              <img v-for="foto in ticket.foto" :key="foto.id" :alt="foto.nome" :data-flickity-lazyload="'/foto/'+foto.id" class="carousel-image">
          </div>
          <div v-else>
              <img src="/img/img_missing.jpg" class="thumbnail w-100">
          </div>
        </div>
        <div class="col-12 col-sm-9">
            <form action="" class="row">
                <div class="col-12 col-sm-12">
                    <label for="titolo">Titolo</label>
                    <input id="titolo" name="titolo" class="form-control" :class="formInputClass('titolo')" type="text" value="" placeholder="Titolo" v-model="ticket.titolo" disabled>
                    <span class="invalid-feedback" role="alert" v-if="errors['titolo'] !== undefined">
                      <strong>{{ errors['titolo'][0] }}</strong>
                    </span>
                </div>
                <div class="col-12 col-sm-6">
                    <label for="data">Data e ora creazione</label>
                    <input id="data" name="data" class="form-control" :class="formInputClass('data')" type="text" :value="ticketCreated" placeholder="Data" disabled>
                    <span class="invalid-feedback" role="alert" v-if="errors['data'] !== undefined">
                      <strong>{{ errors['data'][0] }}</strong>
                    </span>
                </div>
                <div class="col-12 col-sm-6">
                    <label for="stato">Stato</label>
                    <select name="stato" id="stato" class="form-control" :class="formInputClass('stato')" v-model="id_stato" :disabled="role == 'user' || !canModifyState">
                      <option v-for="stato in stati" :key="stato.id" :value="stato.id">{{ stato.nome }}</option>
                        <!--<option value="0">Aperto</option>-->
                        <!--<option value="1">In lavorazione</option>-->
                        <!--<option value="2">Chiuso</option>-->
                    </select>
                    <span class="invalid-feedback" role="alert" v-if="errors['stato'] !== undefined">
                      <strong>{{ errors['stato'][0] }}</strong>
                    </span>
                </div>
                <div class="col-12">
                  <label for="descrizione">Descrizione</label>
                  <textarea id="descrizione" name="descrizione" class="form-control" :class="formInputClass('testo')" type="text" value="" v-model="ticket.testo" placeholder="Descrizione" disabled></textarea>
                  <span class="invalid-feedback" role="alert" v-if="errors['testo'] !== undefined">
                    <strong>{{ errors['testo'][0] }}</strong>
                  </span>
                </div>

                <div class="col-12 col-sm-6">
                  <label for="condominio">
                    <a :href="'/condominio/'+ticket.unita_condominiale.condominio.id+'/singolo'" target="_blank">Condominio</a>
                  </label>
                  <input id="condominio" name="condominio" class="form-control" type="text" v-model="ticket.unita_condominiale.condominio.nome" disabled>
                </div>

                <div class="col-12 col-sm-6">
                  <label for="unita_condominiale">
                    <a :href="'/unitaCondominiale/'+ticket.unita_condominiale.id+'/singolo'" target="_blank">Unita Condominiale</a>
                  </label>
                  <input id="unita_condominiale" name="unita_condominiale" class="form-control" type="text" v-model="ticket.unita_condominiale.nome" disabled>
                </div>

                <div class="col-12 col-sm-6">
                  <label for="creatore">
                    <a :href="linkCreatore" target="_blank">Creato da</a>
                  </label>
                  <input id="creatore" name="creatore" class="form-control" type="text" :value="creatore" disabled>
                </div>

                <div class="col-12 col-sm-6">
                  <label for="telefono">Telefono</label>
                  <input id="telefono" name="telefono" class="form-control" type="text" :value="telefono" disabled>
                </div>

                <div class="col-12 col-sm-6">
                  <label for="destinatario">
                    <a :href="linkDestinatario" target="_blank">Destinatario</a>
                  </label>
                  <input id="destinatario" name="destinatario" class="form-control" type="text" :value="destinatario" disabled>
                </div>

                <div class="col-12 col-sm-6">
                  <label for="collaboratore">
                    <a :href="linkCollaboratore" target="_blank">Collaboratore</a>
                  </label>
                  <input id="collaboratore" name="collaboratore" class="form-control" type="text" :value="collaboratore" disabled>
                </div>

                <div class="col-12">
                    <label for="note">Note</label>
                    <input id="note" name="note" class="form-control" :class="formInputClass('note')" type="text" v-model="note" placeholder="Note" :disabled="role == 'user'">
                    <span class="invalid-feedback" role="alert" v-if="errors['note'] !== undefined">
                      <strong>{{ errors['note'][0] }}</strong>
                    </span>
                </div>
                <div class="col-12" v-if="role != 'user'">
                    <label for="note_gestore_sociale">Note per il Gestore Sociale</label>
                      <textarea rows="5" id="note_gestore_sociale" name="note_gestore_sociale" :class="formInputClass('note_gestore_sociale')" type="text" placeholder="Note per il Gestore Sociale" v-model="note_gestore_sociale" :disabled="role == 'user'"></textarea>
                    <span class="invalid-feedback" role="alert" v-if="errors['note_gestore_sociale'] !== undefined">
                      <strong>{{ errors['note_gestore_sociale'][0] }}</strong>
                    </span>
                </div>
                <div class="col-12 col-sm-3 mt-2" v-if="role != 'user' && canModifyState">
                  <button type="button" class="btn btn-secondary w-100" @click="salvaModifiche()">Salva Modifiche</button>
                </div>
                <div class="col-12 col-sm-3 mt-2" v-if="role != 'user' && !ticket.is_meeting_aperto && canVideo">
                  <button type="button" class="btn btn-secondary w-100" @click="apriMeeting()">Apri la stanza Videochat</button>
                </div>
                <div class="col-12 col-sm-3 mt-2" v-if="ticket.is_meeting_aperto">
                  <a :href="'/ticket/'+id_ticket+'/redirectMeeting'" target="_blank" class="btn btn-secondary w-100">Entra nella stanza Videochat</a>
                </div>
                <aggiungi-collaboratore :token="token" :id_ticket="id_ticket" :modifica="ticket.collaboratore != null" class="col-12 col-sm-3" v-if="role != 'user' && canCollaboratore"></aggiungi-collaboratore>
                <div class="col-12 col-sm-3 mt-2" v-if="ticket.di_condominio == 0 && canModifyState">
                  <button type="button" class="btn btn-secondary w-100" @click="rendiDiCondominio()">Rendi ticket di condominio</button>
                </div>
                <div class="col-12 col-sm-3 mt-2" v-else-if="ticket.di_condominio == 1 && canModifyState">
                  <button type="button" class="btn btn-secondary w-100" @click="rendiDiCondominio()">Rendi ticket non di condominio</button>
                </div>
            </form>
        </div>
    </div>
    <hr class="mt-4">
    <div class="row mt-4 conversation">
        <div v-for="post in ticket.posts" :key="post.id" :class="{'col-10 col-sm-9': true, sent: post.id_utente == id_utente, received: post.id_utente != id_utente, bubble:true}">
          <h5 class="header"><a :href="'/utente/'+post.utente.id+'/singolo'" target="_blank">{{ post.utente.name }} {{ post.utente.cognome}}</a></h5>
          <h6 class="text">{{ post.testo }}</h6>
          <p class="date">{{ postCreated(post) }}</p>
          <p class="attachments">
            <a v-for="doc in post.documenti" :key="doc.id" :href="'/ticket/documento/'+doc.id+'/download'">
              <i class="mdi mdi-file" data-toggle="tooltip" :title="doc.nome"></i>
            </a>
            <a v-for="foto in post.foto" :key="foto.id" :href="'/foto/'+foto.id" target="_blank">
              <img class="thumbnail" :src="'/foto/'+foto.id" data-toggle="tooltip" :title="foto.nome">
            </a>
          </p>
        </div>
    </div>
    <!--<template v-if="ticket.id_stato != 3 && canSend">-->
    <div :class="{ 'd-none': !canSend }">
      <div class="row">
        <textarea name="ticketNewComment" id="ticketNewComment" class="col-12" :class="formInputClassTesto('testo')" v-model="testoRisposta"></textarea>
        <span class="invalid-feedback col-12" role="alert" v-if="errors['testo'] !== undefined">
          <strong>{{ errors['testo'][0] }}</strong>
        </span>
        <!-- File Upload -->
        <div class="col-12 col-sm-3 offset-sm-4 mt-2 py-sm-2 text-center text-sm-right">
          <!--<span>{{ numeroFiles }} File selezionati</span>-->
          <span>{{ fileSelezionati }}</span>
        </div>
        <div class="col-12 col-sm-1 mt-2" data-toggle="tooltip" data-placement="top" title="Allega un file">
          <button class="btn bg-secondary w-100" type="button" id="aggiungi-immagine" data-toggle="modal" data-target="#modaleImmagine">
            <span class="d-sm-none">Allega un file</span>
            <i class="mdi mdi-paperclip"></i>
          </button>
        </div>
        <button type="button" class="col-12 col-sm-4 btn bg-primary mt-2" @click="rispondi()" :disabled="testoRisposta.length == 0">Invia</button>
      </div>


    </div>
    <!-- Modal -->
    <div class="modal fade" id="modaleImmagine" role="dialog" aria-labelledby="modaleImmagineLabel" aria-hidden="true" ref="modaleImmagine">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modaleImmagineLabel">Allega uno o più file</h5>
          </div>
          <div class="modal-body" id="modaleImmagine-body">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">Conferma</button>
          </div>
        </div>
      </div>
    </div>
    <h1 class="m-0 text-dark my-3">Documenti inviati nella chat</h1>
    <div class="row">
      <!-- Datatable documenti -->
      <div class="table-responsive">
        <table class="table table-striped dataTable" ref="tableDocumenti">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Inviato da</th>
              <th>In Data</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>
      <!-- /Datatable documenti -->
    </div>
    <!--</template>-->
</div>
</template>
<script>
const Uppy = require('@uppy/core');
const Dashboard = require('@uppy/dashboard');
const Italiano = require('@uppy/locales/lib/it_IT');

export default {
  props: ['token', 'id_ticket', 'id_utente', 'role', 'id_ruolo'],
  data () {
    return {
      id_stato: 0,
      ticket: null,
      testoRisposta: '',
      errors: {},
      note: '',
      note_gestore_sociale: '',
      stati: [],
      uppy: null,
      numeroFiles: 0,
      translations: null,
      tableDocumenti: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    $.get('/statiticket/json', function (response) {
      this.stati = response.stati;
    }.bind(this));
    this.loadTicket(false);
  },
  mounted () {
    $.fn.dataTable.moment('DD-MM-YYYY');
    this.uppy = Uppy({
      autoProceed: false,
      locale: Italiano,
      restrictions: {
        allowedFileTypes: ['image/*', 'application/pdf'],
      }
    }).use(Dashboard, {
      hideUploadButton: true,
      inline: true,
      target: '#modaleImmagine-body',
      trigger: '#aggiungi-immagine',
    });
    this.tableDocumenti = $(this.$refs.tableDocumenti).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row"<"#buttons2.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter2.col-sm-4.col-12"f><"#pagination2.col-sm-4 col-12"p><"#table2.col-12"t><"#length2"l><"#information2"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.updateTableDocumenti();
    $(this.$refs.modaleImmagine).on('hide.bs.modal', function () {
      this.updateNumeroFiles();
    }.bind(this));
    setInterval(function () {
      this.loadTicket(true, true);
    }.bind(this), 500);
  },
  methods: {
    postCreated (post) {
      return moment(post.created_at).format('DD-MM-YYYY hh:mm');
    },
    updateNumeroFiles () {
      if (this.uppy)
        this.numeroFiles = this.uppy.getFiles().length;
      return 0;
    },
    updateTableDocumenti () {
      this.tableDocumenti.clear();
      this.ticket.all_documenti.forEach(function (doc) {
        var nomeLink = `
        <a href="/ticket/documento/${doc.id}/download" target="_blank">${doc.nome}</a>
        `;
        this.tableDocumenti.row.add([
          nomeLink,
          doc.creatore.preferred_name,
          // doc.creatore.name + ' ' + doc.creatore.cognome,
          moment(String(doc.created_at)).format('DD-MM-YYYY'),
        ]);
      }.bind(this));
      this.ticket.all_foto.forEach(function (foto) {
        var nomeLink = `
        <a href="/foto/${foto.id}" target="_blank">${foto.nome}</a>
        `;
        this.tableDocumenti.row.add([
          nomeLink,
          foto.creatore.preferred_name,
          moment(String(foto.created_at)).format('DD-MM-YYYY'),
        ]);
      }.bind(this));
      this.tableDocumenti.draw();
    },
    salvaModifiche () {
      var data = {
        _token: this.token,
        note: this.note,
        note_gestore_sociale: this.note_gestore_sociale,
        id_stato: this.id_stato,
      };
      $.post(`/ticket/${this.ticket.id}/modifica`, data, function () {
        alert('Modifiche effettuate');
        window.location.reload();
      }.bind(this)).fail(function (response) {
        this.errors = response.responseJSON.errors;
      }.bind(this));
    },
    rispondi () {
      var formData = new FormData();
      var files = this.uppy.getFiles().map((x) => x.data).filter((x) => x.type == 'application/pdf');
      files.forEach(function (f, i) {
        formData.append(`files[${i}]`, f);
      });
      var foto = this.uppy.getFiles().map((x) => x.data).filter((x) => x.type.startsWith('image'));
      foto.forEach(function (f, i) {
        formData.append(`foto[${i}]`, f);
      });
      formData.append('testo', this.testoRisposta);
      formData.append('_token', this.token);

      axios.post(`/ticket/${this.id_ticket}/rispondi`,
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function (response) {
          this.testoRisposta = '';
          var fs = this.uppy.getFiles().map((x) => x.id);
          this.uppy.removeFiles(fs);
          this.updateNumeroFiles();
          this.loadTicket(false);
          this.updateTableDocumenti();
          this.docErrors = {};
        }.bind(this)).catch(function (error) {
          if (error.response.status == 413) {
            this.docErrors = {'file': ['File troppo grande.']};
            alert('File troppo grande.');
          }
          else
            this.docErrors = error.response.data.errors;
        }.bind(this));
    },
    loadTicket (async = true, loadDocumenti = false) {
      $.ajax({
        url: `/ticket/${this.id_ticket}/json`,
        method: 'get',
        async: async,
        success: function (response) {
          this.ticket = response.ticket;
          if (!async) {
            this.note = this.ticket.note;
            this.note_gestore_sociale = this.ticket.note_gestore_sociale,
            this.id_stato = this.ticket.id_stato;
          }
          if (loadDocumenti)
            this.updateTableDocumenti();
        }.bind(this),
      });
    },
    formInputClass(attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
    formInputClassTesto(attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined, 'col-12': true};
    },
    apriMeeting () {
      $.post(`/ticket/${this.id_ticket}/creaMeeting`, { _token: this.token }, function (response) {
        this.ticket.is_meeting_aperto = true;
      }.bind(this)).fail(function () {
        alert('Impossibile aprire la stanza');
      });
    },
    rendiDiCondominio () {
      $.post(`/ticket/${this.id_ticket}/dicondominio`, { _token: this.token }, function (response) {
        // alert('Il ticket è stato reso un ticket di condominio!');
        window.location.reload();
      }.bind(this));
    },
    __(word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    creatore () {
      var c = this.ticket.creatore;
      if (c)
        return c.preferred_name;
      return '';
    },
    linkCreatore () {
      var c = this.ticket.creatore;
      if (c)
        return '/utente/'+c.id+'/singolo';
      return '#';
    },
    telefono () {
      var c = this.ticket.creatore;
      if (!c)
        return '';
      if(this.ticket.di_condominio)
      {
        if(this.canModifyState || c.id == this.id_utente)
        {
          if (c.telefono)
            return c.telefono;
          if (c.cellulare)
            return c.cellulare;
        }
      }
      else
      {
        if (c.telefono)
          return c.telefono;
        if (c.cellulare)
          return c.cellulare;
      }
      return '';
    },
    destinatario () {
      var c = this.ticket.destinatario;
      if (c)
        return c.preferred_name;
      return '';
    },
    linkDestinatario () {
      var c = this.ticket.destinatario;
      if (c)
        return '/utente/'+c.id+'/singolo';
      return '#';
    },
    collaboratore () {
      var c = this.ticket.collaboratore;
      if (c)
        return c.preferred_name;
      return '';
    },
    linkCollaboratore () {
      var c = this.ticket.collaboratore;
      if (c)
        return '/utente/'+c.id+'/singolo';
      return '#';
    },
    canSend () {
      if (this.role == 'user') {
        return this.ticket.posts.length > 0 && this.ticket.id_stato != 3 && this.ticket.di_condominio == 0;
      }
      return true;
    },
    canModifyState () {
      return [1,2,6].includes(this.id_ruolo);
    },
    canVideo () {
      return [2,3,6].includes(this.id_ruolo);
    },
    canCollaboratore () {
      return [1,2,3,6].includes(this.id_ruolo);
    },
    ticketCreated () {
      return moment(this.ticket.created_at).format('DD-MM-YYYY hh:mm');
    },
    fileSelezionati () {
      if (this.numeroFiles == 1)
        return '1 File selezionato';
      return this.numeroFiles + ' File selezionati';
    },
  },
}
</script>
