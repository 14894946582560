a<template>
    <table class="table table-striped dataTable" id="dataTable" ref="dataTable">
        <thead>
            <tr>
                <th>Foglio</th>
                <th>Particella</th>
                <th>Nome</th>
                <th>Subalterno</th>
                <th>Interno</th>
                <th>Piano</th>
                <th>Condominio</th>
                <th>Tipologia</th>
                <th>Amministratore</th>
                <th>Proprietario</th>
                <th>Nucleo Familiare</th>
                <th>Locatario</th>
                <th>Stato</th>
                <th>Data Creazione</th>
                <th>Azioni</th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </table>
</template>

<script>
export default {
  props: ['token', 'id_ruolo_utente'],
  data () {
    return {
      dataTable: null,
      unitaCondominiali: null,
    };
  },
  beforeMount () {
    $.ajax({
      method: 'get',
      url: '/unitaCondominiale/lista/json',
      async: false,
      success: function (response) {
        this.unitaCondominiali = response.unitaCondominiali;
      }.bind(this)
    });
  },
  mounted () {
    $.fn.dataTable.moment('DD-MM-YYYY');
    this.dataTable = $(this.$refs.dataTable).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.updateUnitaCondominiali();
  },
  methods: {
    updateUnitaCondominiali () {
      this.dataTable.clear();
      this.unitaCondominiali.forEach(function (unita) {
        var condominioLink = `
          <a href="/condominio/${unita.condominio.id}/singolo" target="_blank">${unita.condominio.nome}</a>
        `;
        var unitaLink = `
          <a href="/unitaCondominiale/${unita.id}/singolo" target="_blank">${unita.nome}</a>
        `;

        //Amministratore
        var amministratore = unita.condominio.amministratore;
        if (amministratore !== undefined)
          var amministratoreString = `<a href="/utente/${amministratore.id}/singolo">${amministratore.preferred_name}</a>`;
        else
          var amministratoreString = '';
        //Proprietario
        var proprietario = unita.proprietario;
        if (proprietario !== undefined)
          var proprietarioString = `<a href="/utente/${proprietario.id}/singolo">${proprietario.preferred_name}</a>`;
        else
          var proprietarioString = '';
        //NucleoFamiliare
        var nucleo = unita.nucleo;
        if (nucleo !== undefined && nucleo != null)
          var nucleoString = `<a href="/nucleoFamiliare/${nucleo.id}/singolo">${nucleo.nome}</a>`;
        else
          var nucleoString = '';
        //Locatario
        var locatarioString = '';
        if (nucleo !== undefined && nucleo != null) {
          var locatario = unita.nucleo.locatario;
          if (locatario !== undefined)
            locatarioString = `<a href="/utente/${locatario.id}/singolo">${locatario.preferred_name}</a>`;
        }
        //Azioni
        var azioni = '';
        if (this.canDisable)
          azioni = `<button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-circle-off-outline disable-unita" data-id="${unita.id}" data-toggle="tooltip" title="Disabilita Unità" ></button>`;
        //Popola Tabella
        this.dataTable.row.add([
          unita.foglio,
          unita.particella,
          unitaLink,
          unita.subalterno,
          unita.interno,
          unita.piano,
          condominioLink,
          unita.tipologia.nome,
          amministratoreString,
          proprietarioString,
          nucleoString,
          locatarioString,
          unita.stato.nome,
          moment(String(unita.created_at)).format('DD/MM/YYYY HH:mm'),
          //`<a class="btn btn-secondary px-1 py-0 mdi mdi-circle-off-outline" data-toggle="tooltip" title="Disabilita Unità" href="/disable/${unita.id}"></a>`,
          azioni,
        ]);
      }.bind(this));
      this.dataTable.draw();
      $('button.disable-unita').on('click', function (e) {
        this.disableUnita($(e.target).data('id'));
      }.bind(this));
    },
    disableUnita (id) {
      if (confirm('Sei sicuro di voler procedere?')) {
        $.post(`/unitaCondominiale/${id}/disabilita`, {_token: this.token}, function () {
          $.get('/unitaCondominiale/lista/json', function (response) {
            window.location.reload();
            this.unitaCondominiali = response.unitaCondominiali;
            this.updateUnitaCondominiali();
          }.bind(this));
        }.bind(this)).fail(function () {
          alert('Permessi non sufficienti');
        });
        // Ricarico la lista di unità condominiali
      }
    },
  },
  computed: {
    canDisable () {
      return [1,6,8].includes(this.id_ruolo_utente);
    },
  },
}
</script>
