<template>
  <div class="row">
    <div class="col-12 col-sm-3">
      <button class="btn btn-primary w-100" @click="openModaleCaricamento()">Carica Documento</button>
    </div>
    <div class="modal fade" role="dialog" aria-hidden="true" ref="modale">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Caricamento Documento</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form action="">
              <label for="nome_documento">Nome Documento</label>
              <input id="nome_documento" name="nome_documento" :class="formInputClassDoc('nome')" type="text" placeholder="Nome Documento" v-model="nomeDocumento">
              <span class="invalid-feedback" role="alert" v-if="docErrors['nome'] !== undefined">
                <strong>{{ docErrors['nome'][0] }}</strong>
              </span>

              <template v-if="tipologiaFilter === null">
                <label for="tipo_documento" class="mt-4">Tipo Documento</label>
                <select id="tipo_documento" name="tipo_documento" :class="formInputClassDoc('tipologia')" v-model="tipoDocumentoSelezionato">
                  <option v-for="tipo in tipologieDocumentoImmobiliare" :key="tipo.id" :value="tipo.id">{{ tipo.nome }}</option>
                </select>
                <span class="invalid-feedback" role="alert" v-if="docErrors['tipologia'] !== undefined">
                  <strong>{{ docErrors['tipologia'][0] }}</strong>
                </span>
              </template>

              <label for="privato" class="mt-4">Visibilità</label>
              <select id="privato" name="privato" :class="formInputClassDoc('privato')" type="file" v-model="documentoPrivato">
                <option :value="false">Visibile</option>
                <option :value="true">Non visibile</option>
              </select>
              <span class="invalid-feedback" role="alert" v-if="docErrors['privato'] !== undefined">
                <strong>{{ docErrors['privato'][0] }}</strong>
              </span>


              <label for="condominio" class="mt-4 d-block">Condominio</label>
              <!--<select id="condominio" name="condominio" :class="formInputClassDoc('condominio')" ref="selectCondominio">-->
              <select id="condominio" name="condominio" class="form-control" ref="selectCondominio">
                <option></option>
              </select>
              <!--
                 -<span class="invalid-feedback" role="alert" v-if="docErrors['condominio'] !== undefined">
                 -  <strong>{{ docErrors['condominio'][0] }}</strong>
                 -</span>
                 -->
              <br>

              <label for="unita_condominiale" class="mt-4">Unità Condominiale</label>
              <select id="unita_condominiale" name="unita_condominiale" class="form-control" ref="selectUnitaCondominiale">
                <option></option>
              </select>
              <!--
                 -<span class="invalid-feedback" role="alert" v-if="docErrors['unita_condominiale'] !== undefined">
                 -  <strong>{{ docErrors['unita_condominiale'][0] }}</strong>
                 -</span>
                 -->
              <br>


              <label for="file_documento" class="mt-4">Carica File</label>
              <input id="file_documento" name="file_documento" :class="formInputClassDoc('file')" type="file" ref="fileDocumento">
              <span class="invalid-feedback" role="alert" v-if="docErrors['file'] !== undefined">
                <strong>{{ docErrors['file'][0] }}</strong>
              </span>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="chiudiModale()">Chiudi</button>
            <button type="button" class="btn btn-primary" @click="salva()">Salva</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['token', 'filter'],
  data () {
    return {
      docErrors: {},
      nomeDocumento: '',
      condominioSelezionato: null,
      condominioSelezionatoFreeze: false,
      unitaCondominialeSelezionato: null,
      condomini: [],
      unitaCondominiali: [],
      tipoDocumentoSelezionato: null,
      tipologieDocumentoImmobiliare: [],
      documentoPrivato: null,
      file: null,
    };
  },
  mounted () {
    $.get('/tipologieDocumentoImmobiliare/json', function (response) {
      this.tipologieDocumentoImmobiliare = response.tipologieDocumentoImmobiliare;
      this.$nextTick(function () {
        if(this.tipologiaFilter != null)
        {
          this.tipoDocumentoSelezionato = this.tipologiaFilter.id;
        }
        
      }.bind(this));
    }.bind(this));
    $.get('/condominio/lista/json', null, function (response) {
      this.condomini = response.condomini;
      var d = [];
      this.condomini.forEach(function (c) {
        d.push({
          id: c.id,
          text: c.nome,
        });
      });
      $(this.$refs.selectCondominio).select2({
        data: d,
        placeholder: 'Seleziona un condominio',
        width: '100%',
        allowClear: true,
      });
      $(this.$refs.selectCondominio).on('select2:select', function () {
        this.condominioSelezionatoChanged(this.$refs.selectCondominio.value);
      }.bind(this));
      $(this.$refs.selectCondominio).on('select2:clear', function () {
        this.condominioSelezionatoChanged(null);
      }.bind(this));
    }.bind(this));

    $.get('/unitaCondominiale/lista/json', null, function (response) {
      this.unitaCondominiali = response.unitaCondominiali;
      var d = [];
      this.unitaCondominiali.forEach(function (c) {
        d.push({
          id: c.id,
          text: c.nome,
        });
      });
      $(this.$refs.selectUnitaCondominiale).select2({
        data: d,
        placeholder: 'Seleziona una unità condominiale',
        width: '100%',
        allowClear: true,
      });
      $(this.$refs.selectUnitaCondominiale).on('select2:select', function () {
        this.unitaCondominialeSelezionatoChanged(this.$refs.selectUnitaCondominiale.value);
      }.bind(this));
      $(this.$refs.selectUnitaCondominiale).on('select2:clear', function () {
        this.unitaCondominialeSelezionatoChanged(null);
      }.bind(this));
    }.bind(this));

    $(this.$refs.modale).on('hidden.bs.modal', function (e) {
      this.onModalClose(e);
    }.bind(this));
  },
  methods: {
    onModalClose (e) {
      this.resetCampi();
    },
    resetCampi () {
      this.nomeDocumento = '';
      this.$refs.fileDocumento.value = "";
      this.condominioSelezionato = null;
      this.unitaCondominialeSelezionato = null;
      this.tipoDocumentoSelezionato = null;
      $(this.$refs.selectCondominio).val('').trigger('change');
      $(this.$refs.selectUnitaCondominiale).val('').trigger('change');
      this.documentoPrivato = null;
    },
    condominioSelezionatoChanged (value) {
      if (this.condominioSelezionatoFreeze) {
        return;
      }

      this.condominioSelezionato = value;
      var d = [];
      if (value != null) { // Abbiamo modificato il condominio, cambio la lista di possibili unita a quella filtrata
        this.unitaCondominiali.filter((x) => x.id_condominio == this.condominioSelezionato).forEach(function (u) {
          d.push({
            id: u.id,
            text: u.nome,
          });
        });
      } else { // Abbiamo cancellato il condominio, resetto la lista di possibili unita a quella completa
        this.unitaCondominiali.forEach(function (u) {
          d.push({
            id: u.id,
            text: u.nome,
          });
        });
      }
      this.unitaCondominialeSelezionato = null;
      $(this.$refs.selectUnitaCondominiale).select2('destroy').empty().select2({
        data: d,
        placeholder: 'Seleziona una unità condominiale',
        width: 'element',
        allowClear: true,
      });
      $(this.$refs.selectUnitaCondominiale).on('select2:select', function () {
        this.unitaCondominialeSelezionatoChanged(this.$refs.selectUnitaCondominiale.value);
      }.bind(this));
      $(this.$refs.selectUnitaCondominiale).on('select2:clear', function () {
        this.unitaCondominialeSelezionatoChanged(null);
      }.bind(this));
      $(this.$refs.selectUnitaCondominiale).val('').trigger('change');
    },
    unitaCondominialeSelezionatoChanged (value) {
      this.unitaCondominialeSelezionato = value;
      if (value != null) { // Abbiamo cambiato l'unità, cambio il valore selezionato del condominio
        this.condominioSelezionatoFreeze = true;
        var unita = this.unitaCondominiali.filter((x) => x.id == value)[0];
        $(this.$refs.selectCondominio).val(unita.id_condominio).trigger('change');
        this.condominioSelezionato = unita.id_condominio;
        this.condominioSelezionatoFreeze = false;
      }
    },
    openModaleCaricamento () {
      $(this.$refs.modale).modal('show');
    },
    chiudiModale () {
      $(this.$refs.modale).modal('hide');
    },
    salva () {
      if (this.condominioSelezionato == null) {
        this.docErrors['condominio'] = ['Selezionare un condominio'];
        this.docErrors = this.docErrors; // TODO Non funziona
        alert('Selezionare un condominio'); // Fix temporaneo
        return;
      }
      var formData = new FormData();
      formData.append('file', this.$refs.fileDocumento.files[0]);
      formData.append('nome', this.nomeDocumento);
      formData.append('_token', this.token);
      formData.append('tipologia', this.tipoDocumentoSelezionato);
      formData.append('privato', this.documentoPrivato ? 1 : 0);
      var url = '';
      if (this.unitaCondominialeSelezionato != null)
        url = '/unitaCondominiale/'+this.unitaCondominialeSelezionato+'/uploadDocumento';
      else
        url = '/condominio/'+this.condominioSelezionato+'/uploadDocumento';
      axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function () {
          window.location.reload();
        }.bind(this)).catch(function (error) {
          if (error.response.status == 413)
            this.docErrors = {'file': ['File troppo grande.']};
          else if (error.response.status == 403)
            this.docErrors = {'file': ['L\'utente non ha i permessi necessari per l\'upload']};
          else
            this.docErrors = error.response.data.errors;
        }.bind(this));
    },
    formInputClassDoc (attr) {
      var invalid = this.docErrors[attr] !== undefined;
      return { 'form-control': true, 'is-invalid': invalid};
    },
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    tipologiaFilter () {
      var f = this.tipologieDocumentoImmobiliare.filter((x) => x.nome == this.filter);
      if (f.length > 0)
        return f[0];
      return null;
    },
  },
}
</script>
