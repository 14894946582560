<template>
  <div>
    <modale-caricamento ref="modaleCaricamento"></modale-caricamento>

    <input type="text" v-model="cerca" class="form-control col-sm-6 my-2 col-12 pr-sm-4 d-none" placeholder="Cerca">
    <table class="table dataTable" id="dataTable" ref="dataTable">
      <thead>
        <!--{{--FOREACH FATTURA NON PAGATA O PAGATA PARZIALMENTE, SIA FATTURE AFFITTO CHE SPESE CONDOMINIALI--}}-->
        <tr>
          <th>Condominio</th>
          <th>Unità Immobiliare</th>
          <th>Conduttore</th>
          <th>{{ colNames[0] }}</th>
          <th>{{ colNames[1] }}</th>
          <th>{{ colNames[2] }}</th>
          <th>{{ colNames[3] }}</th>
          <th>{{ colNames[4] }}</th>
          <th>Giorni Ritardo</th>
          <!--{{--DA 0 a 30 mettimi per favore una classe che si chiama "rit_0-30", da 30 a 60 "rit_30-60",  --}}-->
          <!--{{-- da 60 a 90 "rit_60-90" e 90 in su "rit_90-plus",  --}}-->
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <table>
      <thead>
        <tr>
          <th>Totale non pagato</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ totale.toFixed(2) }}€</td>
        </tr>
      </tbody>
    </table>
    <nav class="d-none">
      <ul class="pagination">
        <li :class="{'page-item': true, 'disabled': page == 0}"><a class="page-link" href="#" @click="changePage(page-1)">Precedente</a></li>
        <li v-for="i in pages" :key="i" :class="{'page-item': true, 'active': i == page}">
          <a class="page-link" href="#" @click="changePage(i)">{{ i+1 }}</a>
        </li>
        <li :class="{'page-item': true, 'disabled': page == numero_pagine-1}"><a class="page-link" href="#" @click="changePage(page+1)">Seguente</a></li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: ['filter'],
  data () {
    return {
      cerca: '',
      conduttori: null,
      dataTable: null,
      data_fine: null,
      data_inizio: null,
      filtro: -1,
      numero_pagine: 0,
      totale: 0,
      page: 0,
    };
  },
  mounted () {
    this.dataTable = $(this.$refs.dataTable).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        // dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#table.col-12"t><"#length"l>>',
        dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis', 'csv', 'excel'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 10,
    });
    this.dataTable.on('search.dt', function () {
      this.updateTotali();
    }.bind(this));
    this.updateConduttori();
  },
  methods: {
    updateConduttori () {
      var data = {
        page: this.page,
        filtro: this.filtro,
        cerca: this.cerca,
      };
      if (this.filter)
        data['filtro_spesa'] = this.filter;
      if (this.data_inizio)
        data['data_inizio'] = this.data_inizio;
      if (this.data_fine)
        data['data_fine'] = this.data_fine;

      this.$refs.modaleCaricamento.apri();
      $.get('/statistiche/morosita_conduttore/json', data, function (response) {
        this.conduttori = response.pagamenti;
        this.numero_pagine = response.numero_pagine;
        this.updateTableConduttori();
        console.log('chiudimodale');
        this.$refs.modaleCaricamento.chiudi();
      }.bind(this));
    },
    updateTableConduttori () {
      this.dataTable.clear();
      this.conduttori.forEach(function (c) {
        var linkCondominio = '';
        if (c.condominio)
          linkCondominio = `<a href="/condominio/${c.condominio.id}/singolo" target="_blank">${c.condominio.nome}</a>`;
        var linkUnita = '';
        if (c.unita)
          linkUnita = `<a href="/unitaCondominiale/${c.unita.id}/singolo" target="_blank">${c.unita.nome}</a>`;
        var linkConduttore = `
        <a href="/utente/${c.pagamento.destinatario.id}/singolo" target="_blank">${c.pagamento.destinatario.preferred_name}</a>
        `;
        this.dataTable.row.add([
          linkCondominio,
          linkUnita,
          linkConduttore,
          c.pagamento.numero_documento,
          c.pagamento.rata == 0 ? 'Canone Affitto' : 'Spesa Condominiale',
          Number(c.pagamento.importo).toFixed(2)+"€",
          Number(c.pagamento.importo-c.pagamento.acconto1).toFixed(2)+"€",
          moment(String(c.pagamento.data)).format('DD-MM-YYYY'),
          c.pagamento.giorni_ritardo,
        ]);
      }.bind(this));
      this.dataTable.draw();
    },
    updateTotali () {
      var rows = this.dataTable.rows({filter:'applied'}).data();
      var totale = 0;
      rows.each(function (row) {
        totale += Number(row[6].substring(0, row[6].length-1));
      }.bind(this));

      this.totale = totale;
    },
    filtra (giorni) {
      this.filtro = giorni;
      this.page = 0;
      this.updateConduttori();
    },
    changePage (page) {
      this.page = page;
      this.updateConduttori();
    },
  },
  computed: {
    pages () {
      var ps = [0];
      var min = this.page <= 3 ? 1 : this.page-3;
      var max = this.page >= this.numero_pagine-3 ? this.numero_pagine-1 : this.page+3;
      for (var i = min; i < max; i++)
        ps.push(i);
      ps.push(this.numero_pagine-1);
      return ps;
    },
    colNames () {
      if (this.filter == 'spese_condominio')
        return [
          'N° Rata',
          'Tipo Rata',
          'Importo Rata',
          'Importo Rata non pagato',
          'Data Rata',
        ];
      return [
        'N° Fattura',
        'Tipo Fattura',
        'Importo Fattura',
        'Importo Fattura non pagato',
        'Data Fattura',
      ];
    },
  },
  watch: {
    cerca () {
      this.page = 0;
      this.updateConduttori();
    },
  },
}
</script>
