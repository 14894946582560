<template>
  <div>
    <table class="table dataTable" id="dataTable" ref="dataTable">
      <thead>
        <!--{{--FOREACH FATTURA NON PAGATA O PAGATA PARZIALMENTE, SIA FATTURE AFFITTO CHE SPESE CONDOMINIALI--}}-->
        <tr>
          <th>Condominio</th>
          <th>Unità Condominiale</th>
          <th>Conduttore</th>
          <th>Complessivo Canoni</th>
          <th>Complessivo Spese di Condominio</th>
          <th>Saldo Locazione</th>
          <th>Data Saldo Locazione</th>
          <th>Saldo Spese Condominiali</th>
          <th>Data Saldo Spese Condominiali</th>
          <!--{{--DA 0 a 30 mettimi per favore una classe che si chiama "rit_0-30", da 30 a 60 "rit_30-60",  --}}-->
          <!--{{-- da 60 a 90 "rit_60-90" e 90 in su "rit_90-plus",  --}}-->
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['filter'],
  data () {
    return {
      dataTable: null,
      conduttori: null,
      data_inizio: null,
      data_fine: null,
      filtro: -1,
    };
  },
  mounted () {
    this.dataTable = $(this.$refs.dataTable).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 10,
    });
    this.updateConduttori();
  },
  methods: {
    updateConduttori () {
      var data = {
        filtro: this.filtro,
      };
      if (this.data_inizio)
        data['data_inizio'] = this.data_inizio;
      if (this.data_fine)
        data['data_fine'] = this.data_fine;
      $.get('/statistiche/morosita_conduttore/complessivo/json', data, function (response) {
        this.conduttori = response.conduttori;
        this.updateTableConduttori();
      }.bind(this));
    },
    updateTableConduttori () {
      this.dataTable.clear();
      this.conduttori.forEach(function (c) {
        var linkConduttore = `
        <a href="/pagamento/lista?id_user=${c.conduttore.id}" target="_blank">${c.conduttore.preferred_name}</a>
        `;
        var linkUnita = '';
        var linkCondominio = '';
        if (c.unita_condominiale) {
          linkUnita = `
          <a href="/unitaCondominiale/${c.unita_condominiale.id}/singolo" target="_blank">${c.unita_condominiale.nome}</a>
          `;
          linkCondominio = `
          <a href="/condominio/${c.condominio.id}/singolo" target="_blank">${c.condominio.nome}</a>
          `;
        }
        this.dataTable.row.add([
          linkCondominio,
          linkUnita,
          linkConduttore,
          Number(c.complessivo_canoni).toFixed(2)+"€",
          Number(c.complessivo_spese).toFixed(2)+"€",
          c.conduttore.saldo_locazione ? parseFloat(c.conduttore.saldo_locazione).toFixed(2) : '',
          c.conduttore.data_saldo_locazione ? moment(String(c.conduttore.data_saldo_locazione)).format('DD-MM-YYYY') : '',
          c.conduttore.saldo_spese_condominiali ? parseFloat(c.conduttore.saldo_spese_condominiali).toFixed(2) : '',
          c.conduttore.data_saldo_spese_condominiali ? moment(String(c.conduttore.data_saldo_spese_condominiali)).format('DD-MM-YYYY') : '',
        ]);
      }.bind(this));
      this.dataTable.draw();
    },
    filtra (giorni) {
      this.filtro = giorni;
      this.updateConduttori();
    },
  },
}
</script>
