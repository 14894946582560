<template>
  <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" ref="modale">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Aggiungi nuovo membro</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <label for="nome">Nome</label>
              <input type="text" v-model="nome" name="nome" :class="formInputClass('name')" required>
              <span class="invalid-feedback" role="alert" v-if="errors['name'] !== undefined">
                <strong>{{ errors['name'][0] }}</strong>
              </span>
            </div>
            <div class="col-12">
              <label for="cognome">Cognome</label>
              <input type="text" v-model="cognome" name="cognome" :class="formInputClass('cognome')" required>
              <span class="invalid-feedback" role="alert" v-if="errors['cognome'] !== undefined">
                <strong>{{ errors['cognome'][0] }}</strong>
              </span>
            </div>
            <div class="col-12">
              <label for="email">Email</label>
              <input type="text" v-model="email" name="email" :class="formInputClass('email')" required>
              <span class="invalid-feedback" role="alert" v-if="errors['email'] !== undefined">
                <strong>{{ errors['email'][0] }}</strong>
              </span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
          <button type="button" class="btn btn-primary" @click="salva">Salva</button>
        </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  props: ['token', 'id_nucleo_familiare'],
  data () {
    return {
      nome: '',
      cognome: '',
      email: '',
      errors: {},
    };
  },
  mounted () {
  },
  methods: {
    salva () {
      var data = {
        _token: this.token,
        name: this.nome,
        cognome: this.cognome,
        email: this.email,
        id_nucleo_familiare: parseInt(this.id_nucleo_familiare),
        id_ruolo_utente: 5,
      };
      axios.post('/utente/crea',
        data).then(function (response) {
          alert('Utente Salvato');
          window.location.reload();
        }.bind(this)).catch(function (error) {
          this.errors = error.response.data.errors;
        }.bind(this));

    },
    apri () {
      $(this.$refs.modale).modal('show');
    },
    chiudi () {
      $(this.$refs.modale).modal('hide');
    },
    formInputClass (attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
  },
}
</script>
