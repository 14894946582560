<template>
  <div class="container">
    <table class="table table-striped dataTable" id="dataTable" ref="tableLista">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Descrizione</th>
          <th>Numero Membri</th>
          <th>Condominio</th>
          <th>Unità condominiale</th>
          <th>Data inizio</th>
          <th>Data fine</th>
          <th>In corso</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <div class="row" v-if="canCreate">
      <div class="offset-sm-9 col-12 col-sm-3 mt-2">
        <a href="/nucleoFamiliare/crea" class="btn bg-primary w-100 text-dark">Aggiungi nuovo nucleo familiare</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id_ruolo_utente', 'token'],
  data () {
    return {
      nucleiFamiliari: null,
      translations: null,
      tableLista: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    $.ajax({
      url: '/nucleoFamiliare/lista/json',
      method: 'get',
      async: false,
      success: function (response) {
        this.nucleiFamiliari = response.nucleiFamiliari;
      }.bind(this),
    });
  },
  mounted () {
    // $.fn.dataTable.moment('DD-MM-YYYY');
    this.tableLista = $(this.$refs.tableLista).DataTable( {
      language: {
        url: '/js/dataTables/Italian.json',
        searchPlaceholder: 'Cerca...',
        buttons: { colvis: 'Colonne'}
      },
      dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
      buttons: ['colvis','excel','csv'], //Buttons
      "responsive": true,
      "ordering": true,
      "searching": true,
      "bLengthChange": false,
      "pageLength": 50
    });
    this.tableLista.on('draw', function () {
      this.updateOnClick();
    }.bind(this));
    this.updateNucleiFamiliari();
  },
  methods: {
    updateNucleiFamiliari () {
      console.log(this.nucleiFamiliari);
      this.nucleiFamiliari.forEach(function (nucleo) {
        var nameLink = `
        <a href="/nucleoFamiliare/${nucleo.id}/singolo" target="_blank">${nucleo.nome}</a>
        `;
        var uc = nucleo.unita_condominiale;
        if (uc !== null) {
          var uc_id = `
          <a href="/unitaCondominiale/${uc.id}/singolo" target="_blank">${uc.nome}</a>
          `;
          if (uc.condominio !== null){
            var condominio = nucleo.unita_condominiale.condominio;
            var c_id = `
            <a href="/condominio/${condominio.id}/singolo" target="_blank">${condominio.nome}</a>
            `;
          }
          if (uc.pivot.data_inizio != null)
            var uc_data_inizio = moment(String(uc.pivot.data_inizio)).format('DD-MM-YYYY');
          else
            var uc_data_inizio = '';
          if (uc.pivot.data_fine != null)
            var uc_data_fine = moment(String(uc.pivot.data_fine)).format('DD-MM-YYYY');
          else
            var uc_data_fine = '';
          var uc_in_corso = uc.pivot.in_corso == 1 ? 'In corso' : 'Non in corso';
        } else {
          var uc_id = '';
          var c_id = '';
          var uc_data_inizio = '';
          var uc_data_fine = '';
          var uc_in_corso = '';
        }
        var azioni = '';
        if (this.canDisable) {
          azioni = `
          <button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever disable-documento" data-id="${nucleo.id}"></button>
          `;
        }
        this.tableLista.row.add([
          // nucleo.nome,
          nameLink,
          nucleo.descrizione || '',
          nucleo.componenti.length,
          c_id,
          uc_id,
          uc_data_inizio,
          uc_data_fine,
          uc_in_corso,
          azioni,
        ]);
      }.bind(this));
      this.tableLista.draw();
      this.updateOnClick();
    },
    updateOnClick() {
      $('button.disable-documento').on('click', function (e) {
        this.disableNucleo($(e.target).data('id'));
      }.bind(this));
    },
    disableNucleo (id) {
      if (confirm("Sicuro di voler eliminare il nucleo familiare?")) {
        $.post(`/nucleoFamiliare/${id}/disabilita`, {_token: this.token}, function () {
          window.location.reload();
        });
      }
    },
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    canCreate () {
      return [1,6].includes(this.id_ruolo_utente);
    },
    canDisable () {
      return [1,6].includes(this.id_ruolo_utente);
    },
  },
}
</script>
