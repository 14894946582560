<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-4">
        <label for="scadenza">Scadenza</label>
        <select v-model="scadenza" class="form-control">
          <option v-for="d, s in scadenze" v-bind:key="d" :value="d">{{ s }}</option>
        </select>
      </div>

      <div class="col-12 col-sm-4">
        <label for="importo">Importo:</label>
        <input class="form-control" type="number" v-model="importo">
      </div>

      <div class="col-12 col-sm-4">
        <label for="email">Email:</label>
        <input class="form-control" type="email" v-model="email">
      </div>

      <div class="col-12 col-sm-12">
        <label for="descrizione">Descrizione:</label>
          <input class="form-control" type="text" v-model="descrizione">
      </div>

      <div class="col-12 col-sm-3">
        <button type="button" class="btn btn-secondary form-control mt-2" @click="invia()">Invia</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['token'],
  data() {
    var scadenze = {
      '30 giorni': 30,
      '60 giorni': 60,
      '90 giorni': 90,
    };
    return {
      scadenze: scadenze,
      scadenza: 30,
      descrizione: '',
      importo: 100,
      email: '',
    };
  },
  methods: {
    invia() {
      var data = {
        email: this.email,
        descrizione: this.descrizione,
        scadenza: this.scadenza,
        importo: this.importo,
        _token: this.token,
      };
      $.post('/test_email', data, function () {
        alert('Inviata');
      }).fail(function (response) {
        alert('Problema, guarda la console');
        console.log(response);
      }.bind(this));
    },
  },
}
</script>
