<template>
  <div class="offset-sm-8 col-sm-4 col-12 mt-2">
    <button type="button" class="btn btn-secondary w-100" @click="apri()">Gestisci immagini</button>

    <div class="modal fade" role="dialog" aria-hidden="true" ref="modale">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Gestisci immagini</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body row">
            <div v-for="foto in fotos" :key="foto.id" class="col-6 col-sm-3">
              <img :src="'/foto/'+foto.id" class="w-100">
              <button type="button" class="btn btn-secondary w-100 mt-1 mb-3" @click="cancella(foto.id)">Cancella</button>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['unita_condominiale', 'token'],
  data () {
    return {
      fotos: this.unita_condominiale.foto,
    };
  },
  methods: {
    apri () {
      $(this.$refs.modale).modal('show');
    },
    updateFoto () {
      $.get(`/unitaCondominiale/${this.unita_condominiale.id}/foto/json`, function (response) {
        this.fotos = response.foto;
      }.bind(this));
    },
    cancella (id) {
      $.post(`/unitaCondominiale/${this.unita_condominiale.id}/rimuoviFoto/${id}`, {_token: this.token}, function () {
        this.updateFoto();
      }.bind(this));
    },
  },
}
</script>
