<template>
<div class="container">
    <h1 class="m-0 text-dark mb-3" v-if="collaboratore">Collaboratore</h1>
    <h1 class="m-0 text-dark mb-3" v-else>{{ __('translation.utente_singolo') }}</h1>
    <!--Modale creazione nucleo familiare-->
    <div class="modal fade" role="dialog" aria-hidden="true" ref="modalNucleoFamiliare">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Crea nucleo familiare</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form ref="formNucleoFamiliare">
              <div class="row">
                <div class="col-12">
                  <label for="nome">Nome</label>
                  <input type="text" v-model="nucleoFamiliareNome" name="nome" :class="formInputClassNucleo('nome')" :disabled="!canModify" required>
                  <span class="invalid-feedback" role="alert" v-if="errorsNucleo['nome'] !== undefined">
                    <strong>{{ errorsNucleo['nome'][0] }}</strong>
                  </span>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
            <button type="button" class="btn btn-primary" @click="salvaNucleoFamiliare">Salva</button>
          </div>
        </div>
      </div>
    </div>
    <form action="" class="mb-4" ref="formInfo">
        <div class="row">
          <div class="col-12 col-sm-3" id="preview">
            <img alt="Profile Picture" :src="profileImgUrl" class="w-100">
          </div>
          <div class="col-12 col-sm-3" v-if="role == 'user'">
          </div>
          <div class="col-12 col-sm-3" v-if="role != 'user'">
              <label for="uploadPicture">Immagine profilo</label>
              <input id="uploadPicture" name="uploadPicture" :class="formInputClass('uploadPicture')" type="file" @change="onFileChange" ref="fileProfilo">
              <span class="invalid-feedback" role="alert" v-if="errors['uploadPicture'] !== undefined">
                <strong>{{ errors['uploadPicture'][0] }}</strong>
              </span>
          </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-3">
                <label for="nome">Nome</label>
                <input id="nome" name="nome" :class="formInputClass('name')" type="text" value="" placeholder="Nome" v-model="utente.name" :disabled="!canModify" >
                <span class="invalid-feedback" role="alert" v-if="errors['name'] !== undefined">
                  <strong>{{ errors['name'][0] }}</strong>
                </span>
            </div>
            <div class="col-12 col-sm-3">
                <label for="cognome">Cognome</label>
                <input id="cognome" name="cognome" :class="formInputClass('cognome')" type="text" value="" placeholder="Cognome" v-model="utente.cognome" :disabled="!canModify" >
                <span class="invalid-feedback" role="alert" v-if="errors['cognome'] !== undefined">
                  <strong>{{ errors['cognome'][0] }}</strong>
                </span>
            </div>
            <div class="col-12 col-sm-5">
                <label for="ragione_sociale">Ragione Sociale</label>
                <input id="ragione_sociale" name="ragione_sociale" :class="formInputClass('ragione_sociale')" type="text" placeholder="Ragione Sociale" :disabled="!canModify"
                  v-model="utente.ragione_sociale">
                <span class="invalid-feedback" role="alert" v-if="errors['ragione_sociale'] !== undefined">
                  <strong>{{ errors['ragione_sociale'][0] }}</strong>
                </span>
            </div>
            <!--
            <div class="col-6 col-sm-1">
                <label for="verificato">Verificato</label>
                <input id="verificato" name="verificato" class="form-control" type="checkbox" v-model="utente.verificato"
                :disabled="!canModify" >
            </div>
            -->
            <div class="col-6 col-sm-1">
                <label for="id">Id</label>
                <input id="id" name="id" class="form-control" type="text" placeholder="Id" :value="utente.id" disabled>
            </div>
            <div class="col-12 col-sm-3">
                <label for="email">Email</label>
                <!--<input id="email" name="email" :class="formInputClass('email')" type="text" placeholder="Email" v-model="utente.email" :disabled="!creazione">-->
                <input id="email" name="email" :class="formInputClass('email')" type="text" placeholder="Email" v-model="utente.email" :disabled="!canModify" >
                <span class="invalid-feedback" role="alert" v-if="errors['email'] !== undefined">
                  <strong>{{ errors['email'][0] }}</strong>
                </span>
            </div>
            <div class="col-12 col-sm-3">
                <label for="seconda_mail">Email Secondaria</label>
                <input id="seconda_mail" name="seconda_mail" :class="formInputClass('seconda_mail')" type="tel" value="" placeholder="Seconda Email" v-model="utente.seconda_mail" :disabled="!canModify" >
                <span class="invalid-feedback" role="alert" v-if="errors['seconda_mail'] !== undefined">
                  <strong>{{ errors['seconda_mail'][0] }}</strong>
                </span>
            </div>
            <!--
              -<div class="col-12 col-sm-4" v-if="collaboratore">
              -    <label for="condominio_collaboratore">Condominio</label>
              -    <div class="row">
              -      <div class="col-9">
              -        <select id="condominio_collaboratore" name="condominio_collaboratore" class="form-control" ref="selectCondominioCollaboratore" :disabled="!canModify" >
              -          <option></option>
              -        </select>
              -      </div>
              -    </div>
              -</div>
            -->
            <div class="col-12 col-sm-6" v-if="!collaboratore && !admin">
                <label for="nucleo_familiare">
                  <template v-if="utente.id_nucleo_familiare">
                    <a :href="'/nucleoFamiliare/'+utente.id_nucleo_familiare+'/singolo'" target="_blank">Nucleo Familiare</a>
                  </template>
                  <template v-else>
                    Nucleo Familiare
                  </template>
                </label>
                <div class="row">
                  <div class="col-9">
                    <select id="nucleo_familiare" name="nucleo_familiare" class="form-control" ref="selectNucleoFamiliare" :disabled="!canModify">
                      <option></option>
                    </select>
                  </div>
                  <div class="col-3">
                    <button id="crea" type="button" class="btn btn-primary btn-block text-uppercase" @click="apriCreaNucleoFamiliare" v-if="role != 'user'">Crea</button>
                  </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <label for="data_nascita">Data di Nascita</label>
                <!--<input id="data_nascita" name="data_nascita" class="form-control" type="date" value="" placeholder="Data Nascita" v-model="utente.data_nascita" :disabled="role == 'user'">-->
                <daterange-picker v-model="utente.data_nascita" :class="formInputClass('data_nascita')" :disabled="!canModify" ></daterange-picker>
                <span class="invalid-feedback" role="alert" v-if="errors['data_nascita'] !== undefined">
                  <strong>{{ errors['data_nascita'][0] }}</strong>
                </span>
            </div>
            <div class="col-12 col-sm-4">
                <label for="telefono">Telefono</label>
                <input id="telefono" name="telefono" :class="formInputClass('telefono')" type="tel" value="" placeholder="Telefono" v-model="utente.telefono" :disabled="!canModify" >
                <span class="invalid-feedback" role="alert" v-if="errors['telefono'] !== undefined">
                  <strong>{{ errors['telefono'][0] }}</strong>
                </span>
            </div>
            <div class="col-12 col-sm-4">
                <label for="cellulare">Cellulare</label>
                <input id="cellulare" name="cellulare" :class="formInputClass('cellulare')" type="tel" value="" placeholder="Cellulare" v-model="utente.cellulare" :disabled="!canModify" >
                <span class="invalid-feedback" role="alert" v-if="errors['cellulare'] !== undefined">
                  <strong>{{ errors['cellulare'][0] }}</strong>
                </span>
            </div>
            <!--
              -<div class="col-12 col-sm-4">
              -    <label for="fax">Fax</label>
              -    <input id="fax" name="fax" :class="formInputClass('fax')" type="tel" value="" placeholder="Fax" v-model="utente.fax">
              -    <span class="invalid-feedback" role="alert" v-if="errors['fax'] !== undefined">
              -      <strong>{{ errors['fax'][0] }}</strong>
              -    </span>
              -</div>
            -->
            <div class="col-12 col-sm-4">
                <label for="cittadinanza">Cittadinanza</label>
                <select id="cittadinanza" name="cittadinanza" :class="formInputClass('cittadinanza')" ref="selectCittadinanza" :disabled="!canModify">
                  <option></option>
                </select>
                <span class="invalid-feedback" role="alert" v-if="errors['cittadinanza'] !== undefined">
                  <strong>{{ errors['cittadinanza'][0] }}</strong>
                </span>
            </div>
            <div class="col-12 col-sm-6">
                <label for="note">Note</label>
                <input id="note" name="note" :class="formInputClass('note')" type="text" placeholder="Note" v-model="utente.note" :disabled="!canModify" >
                <span class="invalid-feedback" role="alert" v-if="errors['note'] !== undefined">
                  <strong>{{ errors['note'][0] }}</strong>
                </span>
            </div>
            <div :class="{ 'col-12 col-sm-3': true, 'd-none': collaboratore }" v-if="role != 'user'">
                <label for="id_ruolo_utente">Ruolo Utente</label>
                <select id="id_ruolo_utente" name="id_ruolo_utente" :class="formInputClass('id_ruolo_utente')" type="text" placeholder="Note" v-model="utente.id_ruolo_utente" :disabled="!canModify" >
                  <option v-for="r in ruoliUtente" :key="r.id" :value="r.id">{{ r.nome }}</option>
                </select>
                <span class="invalid-feedback" role="alert" v-if="errors['id_ruolo_utente'] !== undefined">
                  <strong>{{ errors['id_ruolo_utente'][0] }}</strong>
                </span>
            </div>
            <div :class="{ 'col-12 col-sm-3': true, 'd-none': collaboratore }" v-if="role != 'user'">
                <label for="saldo_locazione">Saldo Locazione</label>
                <input id="saldo_locazione" name="saldo_locazione" :class="formInputClass('saldo_locazione')" type="number" v-model="utente.saldo_locazione" :disabled="!canModify" >
                <span class="invalid-feedback" role="alert" v-if="errors['saldo_locazione'] !== undefined">
                  <strong>{{ errors['saldo_locazione'][0] }}</strong>
                </span>
            </div>
            <div :class="{ 'col-12 col-sm-3': true, 'd-none': collaboratore }" v-if="role != 'user'">
                <label for="data_saldo_locazione">Data Saldo Locazione</label>
                <daterange-picker id="data_saldo_locazione" name="data_saldo_locazione" :class="formInputClass('data_saldo_locazione')" v-model="utente.data_saldo_locazione" :disabled="!canModify" ></daterange-picker>
                <span class="invalid-feedback" role="alert" v-if="errors['data_saldo_locazione'] !== undefined">
                  <strong>{{ errors['data_saldo_locazione'][0] }}</strong>
                </span>
            </div>
            <div :class="{ 'col-12 col-sm-3': true, 'd-none': collaboratore }" v-if="role != 'user'">
                <label for="saldo_spese_condominiali">Saldo Spese Condominiali</label>
                <input id="saldo_spese_condominiali" name="saldo_spese_condominiali" :class="formInputClass('saldo_spese_condominiali')" type="number" v-model="utente.saldo_spese_condominiali" :disabled="!canModify" >
                <span class="invalid-feedback" role="alert" v-if="errors['saldo_spese_condominiali'] !== undefined">
                  <strong>{{ errors['saldo_spese_condominiali'][0] }}</strong>
                </span>
            </div>
            <div :class="{ 'col-12 col-sm-3': true, 'd-none': collaboratore }" v-if="role != 'user'">
                <label for="data_saldo_spese_condominiali">Data Saldo Spese Condominiali</label>
                <daterange-picker id="data_saldo_spese_condominiali" name="data_saldo_spese_condominiali" :class="formInputClass('data_saldo_spese_condominiali')" v-model="utente.data_saldo_spese_condominiali" :disabled="!canModify" ></daterange-picker>
                <span class="invalid-feedback" role="alert" v-if="errors['data_saldo_spese_condominiali'] !== undefined">
                  <strong>{{ errors['data_saldo_spese_condominiali'][0] }}</strong>
                </span>
            </div>
            <div class="col-12 col-sm-6" v-if="role != 'user'">
                <label for="note">Note per il Gestore Sociale</label>
                <textarea rows="5" id="note_gestore_sociale" name="note_gestore_sociale" :class="formInputClass('note_gestore_sociale')" type="text" placeholder="Note per il Gestore Sociale" v-model="utente.note_gestore_sociale" :disabled="!canModify"></textarea>
                <span class="invalid-feedback" role="alert" v-if="errors['note_gestore_sociale'] !== undefined">
                  <strong>{{ errors['note_gestore_sociale'][0] }}</strong>
                </span>
            </div>
            <div class="col-12 col-sm-4" v-if="collaboratore">
                <label for="professione">Professione (Solo se collaboratore)</label>
                <select name="professione" id="professione" class="form-control" v-model="utente.professione" :disabled="!canModify" >
                  <option value="0"></option>
                  <option v-for="p in professioni" :key="p.id" :value="p.id">{{ p.nome }}</option>
                </select>
            </div>
            <div class="col-12 col-sm-4" v-if="collaboratore">
                <label for="professione">Professione (Solo se collaboratore)</label>
                <input id="professione_text" name="professione_text" :class="formInputClass('professione_text')" type="text" placeholder="Professione" v-model="utente.professione_manuale" :disabled="!canModify" >
                <span class="invalid-feedback" role="alert" v-if="errors['professione_text'] !== undefined">
                  <strong>{{ errors['professione_text'][0] }}</strong>
                </span>
            </div>
        </div>
        <template v-if="!creazione && collaboratore">
          <hr>
          <h4>Condomini</h4>
              <!-- --------INSERIRE INDIRIZZO------------ -->
              <lista-condomini-collaboratore :token="token" ref="lista-condomini" :utente="utente"></lista-condomini-collaboratore>
        </template>
        <hr>
        <h4>Indirizzo Residenza</h4>
            <!-- --------INSERIRE INDIRIZZO------------ -->
            <indirizzo :token="token" :indirizzo="{ id: utente.id_indirizzo }" ref="indirizzo" :creazione="creazione" :disabled="!canModify" ></indirizzo>
        <hr>
        <h4>Dati Fiscali</h4>
        <div class="row">
          <!-- --------INSERIRE DATI FISCALI------------ -->
          <div class="col-12 col-sm-4">
            <label for="codice_fiscale">Codice Fiscale</label>
            <input id="codice_fiscale" name="codice_fiscale" :class="formInputClass('codice_fiscale')" pattern="[A-Za-z]{6}\d{2}[A-Za-z]\d{2}[A-Za-z]\d{3}[A-Za-z]" type="text" value="" placeholder="Codice Fiscale" v-model="utente.codice_fiscale" :disabled="!canModify" >
            <span class="invalid-feedback" role="alert" v-if="errors['codice_fiscale'] !== undefined">
              <strong>{{ errors['codice_fiscale'][0] }}</strong>
            </span>
          </div>
          <div class="col-12 col-sm-4">
              <label for="partita_iva">Partita Iva</label>
              <input id="partita_iva" name="partita_iva" :class="formInputClass('partita_iva')" type="text" value="" placeholder="Partita Iva" v-model="utente.partita_iva" :disabled="!canModify" >
              <span class="invalid-feedback" role="alert" v-if="errors['partita_iva'] !== undefined">
                <strong>{{ errors['partita_iva'][0] }}</strong>
              </span>
          </div>
          <div class="col-12 col-sm-4">
              <label for="pec">Pec</label>
              <input id="pec" name="pec" :class="formInputClass('pec')" type="email" value="" placeholder="Pec" v-model="utente.pec" :disabled="!canModify" >
              <span class="invalid-feedback" role="alert" v-if="errors['pec'] !== undefined">
                <strong>{{ errors['pec'][0] }}</strong>
              </span>
          </div>
          <div class="col-12 col-sm-4">
              <label for="sdi">SDI</label>
              <input id="sdi" name="sdi" :class="formInputClass('sdi')" type="text" value="" placeholder="SDI" v-model="utente.sdi" :disabled="!canModify" >
              <span class="invalid-feedback" role="alert" v-if="errors['sdi'] !== undefined">
                <strong>{{ errors['sdi'][0] }}</strong>
              </span>
          </div>
          <div class="col-12 col-sm-4">
              <label for="iban">IBAN</label>
              <input id="iban" name="iban" :class="formInputClass('iban')" type="text" value="" placeholder="IBAN" v-model="utente.iban" :disabled="!canModify" >
              <span class="invalid-feedback" role="alert" v-if="errors['iban'] !== undefined">
                <strong>{{ errors['iban'][0] }}</strong>
              </span>
          </div>
        </div>
        <hr>
        <h4>Luogo di Nascita</h4>
            <!-- --------INSERIRE INDIRIZZO------------ -->
            <!--<indirizzo :token="token" :indirizzo="{ id: utente.id_indirizzo_nascita }" ref="indirizzoNascita" :creazione="creazione" :disabled="!canModify" ></indirizzo>-->
            <luogo-nascita :token="token" :indirizzo="{ id: utente.id_indirizzo_nascita }" ref="indirizzoNascita" :creazione="creazione" :disabled="!canModify" ></luogo-nascita>
    </form>
    <!--<div class="row" v-if="role != 'user'">-->
    <div class="row">
        <div class="offset-sm-6 col-12 col-sm-3 mt-2" v-if="!creazione && role != 'user'">
            <button type="button" class="btn bg-primary w-100" @click="reset()">Reset password</button>
        </div>
        <div class="offset-sm-10" v-else>
        </div>
        <div class="col-12 col-sm-2 mt-2">
            <button type="button" class="btn bg-primary w-100 text-uppercase" @click="crea()" v-if="creazione">Crea</button>
            <button type="button" class="btn bg-primary w-100 text-uppercase" @click="salva()" v-else-if="canModify">Salva</button>
        </div>
    </div>
    <template v-if="!creazione">
      <hr>
      <template v-if="false">
      <h4>{{ __('translation.lista_tipi_pagamento') }}</h4>
      <!-- Datatable membri nucleo -->
      <table class="table table-striped dataTable" id="dataTable">
        <thead>
          <tr>
            <th>Id</th>
            <th>Master</th>
            <th>Nome</th>
            <th>Email</th>
            <th>Data Creazione</th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table>
      <!-- /Datatable membri nucleo -->
      <div class="row">
        <!-- Button trigger modal -->
        <div class="offset-sm-9 col-12 col-sm-3 mt-2">
          <button class="btn bg-primary w-100" data-toggle="modal" data-target="#inserisci_membro">Aggiungi nuovo tipo pagamento</button>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="inserisci_membro" role="dialog" aria-labelledby="inserisci_membro" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Aggiungi nuovo tipo pagamento</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form action="">
                  <label for="nome_documento">Ricerca per Nome Cognome / Email</label>
                  <input id="nome_documento" name="nome_documento" class="form-control" type="text" placeholder="Nome Cognome/Email" required>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Aggiungi</button>
                <button type="button" class="btn bg-primary">Salva</button>
              </div>
            </div>
          </div>
        </div>
        <!-- /Modal -->
      </div>
      </template>
      <documenti-utente :token="token" :utente="utente" :id_ruolo="id_ruolo"></documenti-utente>
      <tickets-collaboratore :collaboratore="utente" :token="token" v-if="collaboratore === true && ticket_perm"></tickets-collaboratore>
    </template>
</div>
</template>
<script>
export default {
  props: ['token', 'id_utente', 'role', 'creazione', 'collaboratore', 'ticket_perm', 'id_ruolo'],
  data () {
    return {
      errors: {},
      condomini: [],
      errorsNucleo: {},
      professioni: [],
      ruoliUtente: [],
      nucleoFamiliareNome: '',
      nucleiFamiliari: [],
      translations: null,
      utente: null,
      cittadinanze: [],
      profileImgUrl: "/img/profile_img.jpg",
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    $.get('/professione/json', function (response) {
      this.professioni = response.professioni;
    }.bind(this));
    if (this.role != 'user') {
      $.get('/ruoli/json', function (response) {
        this.ruoliUtente = response.ruoli_utente;
      }.bind(this));
    }
    if (this.creazione) {
      this.utente = {
        name: null,
        cognome: null,
        ragione_sociale: null,
        data_nascita: null,
        codice_fiscale: null,
        partita_iva: null,
        pec: null,
        email: null,
        telefono: null,
        cellulare: null,
        professione: 0,
        professione_manuale: null,
        fax: null,
        note: null,
        saldo_locazione: null,
        saldo_spese_condominiali: null,
        data_saldo_locazione: null,
        data_saldo_spese_condominiali: null,
        id_nucleo_familiare: null,
        id_ruolo_utente: 5,
        note_gestore_sociale: null,
      };
    } else {
      $.ajax({
        url: '/utente/' + this.id_utente + '/singolo/json',
        method: 'get',
        async: false,
        success: function (response) {
          this.utente = response.utente;
          this.utente.professione = this.utente.id_professione;
          this.profileImgUrl = this.utente.photo_url;
        }.bind(this),
      });
    }
  },
  mounted () {
    this.updateSelectNucleiFamiliari();
    // this.updateSelectCondomini();
    this.updateCittadinanze();
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.profileImgUrl = URL.createObjectURL(file);
    },

    updateCittadinanze () {
      $.get('/cittadinanze/json', function (response) {
        this.cittadinanze = response.cittadinanze;
        var d = [];
        this.cittadinanze.forEach(function (c) {
          var dd = {
            text: c.nome + ' ' + c.unicode,
            id: c.id,
          };
          if (c.id == this.utente.id_cittadinanza)
            dd['selected'] = true;
          d.push(dd);
        }.bind(this));
        $(this.$refs.selectCittadinanza).select2({
          data: d,
          placeholder: 'Selezionare una cittadinanza',
        });
      }.bind(this));
    },

    salvaNucleoFamiliare () {
      if (!$(this.$refs.formNucleoFamiliare.reportValidity()))
        return;
      var data = {
        _token: this.token,
        nome: this.nucleoFamiliareNome,
      };
      $.post('/nucleoFamiliare/crea', data, function (response) {
        this.updateSelectNucleiFamiliari();
        $(this.$refs.modalNucleoFamiliare).modal('hide');
        $(this.$refs.selectNucleoFamiliare).val(response.nucleoFamiliare.id).trigger('change');
      }.bind(this)).fail(function (response) {
        this.errorsNucleo = response.responseJSON.errors;
      }.bind(this));
    },

    crea () {
      if (!this.$refs.formInfo.reportValidity())
        return;
      var data = {
        _token: this.token,
        name: this.utente.name,
        cognome: this.utente.cognome,
        ragione_sociale: this.utente.ragione_sociale,
        data_nascita: this.utente.data_nascita,
        codice_fiscale: this.utente.codice_fiscale,
        partita_iva: this.utente.partita_iva,
        pec: this.utente.pec,
        sdi: this.utente.sdi,
        iban: this.utente.iban,
        email: this.utente.email,
        telefono: this.utente.telefono,
        cellulare: this.utente.cellulare,
        saldo_locazione: this.utente.saldo_locazione,
        saldo_spese_condominiali: this.utente.saldo_spese_condominiali,
        data_saldo_locazione: this.utente.data_saldo_locazione,
        data_saldo_spese_condominiali: this.utente.data_saldo_spese_condominiali,
        // fax: this.utente.fax,
        seconda_mail: this.utente.seconda_mail,
        note: this.utente.note,
        id_ruolo_utente: this.utente.id_ruolo_utente,
        id_cittadinanza: this.$refs.selectCittadinanza.value,
        professione_manuale: this.utente.professione_manuale,
        note_gestore_sociale: this.utente.note_gestore_sociale
      };

      if (this.collaboratore) {
        data['collaboratore'] = '1';
        if (this.utente.professione != 0)
          data['id_professione'] = this.utente.professione;
        /*
        var cond = $(this.$refs.selectCondominioCollaboratore).val();
        if (cond)
          data['id_condominio_collaboratore'] = cond;
        */
      } else if (!this.admin) {
        data['id_nucleo_familiare'] = this.$refs.selectNucleoFamiliare.value;
      }

      var newData = new FormData();
      Object.keys(data).forEach(function (key) {
        if (data[key] != null)
          newData.append(key, data[key]);
      });
      if (this.$refs != null && this.$refs.fileProfilo != null && this.$refs.fileProfilo.files.length > 0)
        newData.append('foto', this.$refs.fileProfilo.files[0]);

      var id_indirizzo = this.$refs.indirizzo.salva();
      if (id_indirizzo !== null) {
        if (id_indirizzo !== 0)
        {
          data['id_indirizzo'] = id_indirizzo;
          newData.append('id_indirizzo', id_indirizzo);
        }
        var id_indirizzo_nascita = this.$refs.indirizzoNascita.salva();
        if (id_indirizzo_nascita !== null) {
          if (id_indirizzo_nascita !== 0)
          {
            data['id_indirizzo_nascita'] = id_indirizzo_nascita;
            newData.append('id_indirizzo_nascita', id_indirizzo_nascita);
          }
          /*
          $.post('/utente/crea', data, function (response) {
            alert('Utente Salvato');
            if (this.collaboratore)
              window.location.replace('/collaboratore/'+response.utente.id+'/singolo');
            else
              window.location.replace('/utente/'+response.utente.id+'/singolo');
          }.bind(this)).fail(function (response) {
            this.errors = response.responseJSON.errors;
          }.bind(this));
          */
          axios.post('/utente/crea',
            newData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(function (response) {
              alert('Utente Salvato');
              if (this.collaboratore)
                window.location.replace('/collaboratore/'+response.data.utente.id+'/singolo');
              else
                window.location.replace('/utente/'+response.data.utente.id+'/singolo');
            }.bind(this)).catch(function (error) {
              this.errors = error.response.data.errors;
            }.bind(this));
        }
      }
    },
    salva () {
      if (!this.$refs.formInfo.reportValidity())
        return;
      var data = {
        _token: this.token,
        name: this.utente.name,
        cognome: this.utente.cognome,
        ragione_sociale: this.utente.ragione_sociale,
        data_nascita: this.utente.data_nascita,
        codice_fiscale: this.utente.codice_fiscale,
        partita_iva: this.utente.partita_iva,
        pec: this.utente.pec,
        sdi: this.utente.sdi,
        iban: this.utente.iban,
        email: this.utente.email,
        telefono: this.utente.telefono,
        cellulare: this.utente.cellulare,
        saldo_locazione: this.utente.saldo_locazione,
        saldo_spese_condominiali: this.utente.saldo_spese_condominiali,
        data_saldo_locazione: this.utente.data_saldo_locazione,
        data_saldo_spese_condominiali: this.utente.data_saldo_spese_condominiali,
        // fax: this.utente.fax,
        seconda_mail: this.utente.seconda_mail,
        note: this.utente.note,
        id_ruolo_utente: this.utente.id_ruolo_utente,
        id_cittadinanza: this.$refs.selectCittadinanza.value,
        professione_manuale: this.utente.professione_manuale,
        note_gestore_sociale: this.utente.note_gestore_sociale
      };

      if (this.collaboratore) {
        if (this.utente.professione != 0)
          data['id_professione'] = this.utente.professione;
        /*
        var cond = $(this.$refs.selectCondominioCollaboratore).val();
        if (cond)
        {
          data['id_condominio_collaboratore'] = cond;
        }
        */
      } else if (!this.admin){
        data['id_nucleo_familiare'] = this.$refs.selectNucleoFamiliare.value;
      }

      var newData = new FormData();
      Object.keys(data).forEach(function (key) {
        if (data[key] != null)
          newData.append(key, data[key]);
      });
      if (this.$refs.fileProfilo.files.length > 0)
        newData.append('foto', this.$refs.fileProfilo.files[0]);

      var id_indirizzo = this.$refs.indirizzo.salva();
      var id_indirizzo_nascita = this.$refs.indirizzoNascita.salva();
      if (id_indirizzo && id_indirizzo_nascita) {
        // Se l'indirizzo era null e ho fatto qualcosa
        if (typeof (id_indirizzo) != 'boolean') // Ho creato un nuovo indirizzo che devo associare
        {
          data['id_indirizzo'] = id_indirizzo;
          newData.append('id_indirizzo', id_indirizzo);
        }
        if (typeof (id_indirizzo_nascita) != 'boolean') // Ho creato un nuovo indirizzo che devo associare
        {
          data['id_indirizzo_nascita'] = id_indirizzo_nascita;
          newData.append('id_indirizzo_nascita', id_indirizzo_nascita);
        }
        /*
        $.post('/utente/' + this.utente.id + '/modifica', data, function () {
          alert('Salvataggio effettuato con successo');
          this.errors = {};
        }.bind(this)).fail(function (response) {
          alert('Errore');
          this.errors = response.responseJSON.errors;
        }.bind(this));
        */
        axios.post('/utente/'+this.utente.id+'/modifica',
          newData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function () {
            alert('Salvataggio effettuato con successo');
            window.location.reload();
            this.errors = {};
          }.bind(this)).catch(function (error) {
            console.log(error);
            alert('Errore');
            this.errors = error.response.data.errors;
          }.bind(this));
      }
    },
    reset () {
      $.post(`/utente/${this.id_utente}/reset`, {_token: this.token}, function () {
        alert('Reset effettuato.');
      }).fail(function () {
        alert('Problemi durante il reset');
      });
    },
    apriCreaNucleoFamiliare () {
      $(this.$refs.modalNucleoFamiliare).modal('show');
    },
    updateSelectNucleiFamiliari () {
      $(this.$refs.selectNucleoFamiliare).select2({
	placeholder: 'Caricamento...',
      });
      $.ajax({
        url: '/nucleoFamiliare/lista/safejson',
        method: 'get',
        async: true,
        success: function (response) {
          this.nucleiFamiliari = response.nucleiFamiliari;
          var d = [];
          this.nucleiFamiliari.forEach(function (nucleo) {
            var dd = {
              id: nucleo.id,
              text: nucleo.nome,
            };
            if (nucleo.id == this.utente.id_nucleo_familiare)
              dd['selected'] = true;
            d.push(dd);
          }.bind(this));
          $(this.$refs.selectNucleoFamiliare).select2({
            data: d,
            placeholder: 'Selezionare un nucleo familiare',
          });
        }.bind(this)
      });
    },
    updateSelectCondomini () {
      $.ajax({
        url: '/condominio/lista/json',
        method: 'get',
        async: true,
        success: function (response) {
          this.condomini = response.condomini;
          var d = [];
          this.condomini.forEach(function (c) {
            var dd = {
              id: c.id,
              text: c.nome,
            };
            if (c.id == this.utente.id_condominio_collaboratore)
              dd['selected'] = true;
            d.push(dd);
          }.bind(this));
          $(this.$refs.selectCondominioCollaboratore).select2({
            data: d,
            placeholder: 'Selezionare un condominio',
          });
        }.bind(this)
      });
      var d = [];
      this.nucleiFamiliari.forEach(function (nucleo) {
        var dd = {
          id: nucleo.id,
          text: nucleo.nome,
        };
        if (nucleo.id == this.utente.id_nucleo_familiare)
          dd['selected'] = true;
        d.push(dd);
      }.bind(this));
      $(this.$refs.selectNucleoFamiliare).select2({
        data: d,
        placeholder: 'Selezionare un nucleo familiare',
      });
    },
    formInputClassNucleo (attr) {
      return { 'form-control': true, 'is-invalid': this.errorsNucleo[attr] !== undefined };
    },
    formInputClass (attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
    __(word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    canModify () {
      if ([1,8,6].includes(this.id_ruolo))
        return true;
      if ([2,3].includes(this.id_ruolo) && this.collaboratore)
        return true;
    },
    admin () {
      if (this.utente)
        return [1,6].includes(this.utente.id_ruolo_utente);
      else
        return false;
    },
  },
}
</script>
