<template>
  <div class="container">
    <div class="row annuncio">
      <h3 class="col-12">{{ annuncio.titolo }}</h3>
      <div class="main-carousel col-12 mb-4">
        <img v-for="foto in annuncio.foto" :key="foto.id" :alt="foto.nome" :data-flickity-lazyload="'/foto/'+foto.id" class="carousel-image">
      </div>

      <div class="col-12 mb-4 d-none d-sm-block">
        <h3 class="dettagli d-inline-flex">
          <div class="prezzo">€ {{ thousandsSeparator(annuncio.prezzo) }}</div>
          <div class="superficie">
            <p>{{ thousandsSeparator(Math.floor(annuncio.unita_condominiale.superficie)) }} m²</p>
            <p>Superficie</p>
          </div>
          <div class="numero_locali">
            <p>{{ annuncio.unita_condominiale.numero_locali }} <i class="mdi mdi-floor-plan"></i></p>
            <p>Locali</p>
          </div>
          <div class="numero_bagni">
            <p>{{ annuncio.unita_condominiale.numero_bagni }} <i class="mdi mdi-paper-roll"></i></p>
            <p>Bagni</p>
          </div>
          <div class="piano">
            <p>{{ annuncio.unita_condominiale.piano }}° <i class="mdi mdi-stairs"></i></p>
            <p>Piano</p>
          </div>
          <div class="tipologia">
            <p><i class="mdi mdi-sign-real-estate"></i></p>
            <p>{{ annuncio.tipologia.nome }}</p>
          </div>

        </h3>
      </div>

      <h3 class="col-12">Unità Condominiale</h3>
      <div class="col-12 mb-4">{{annuncio.unita_condominiale.nome}} - {{ annuncio.unita_condominiale.condominio.indirizzo.summary }}</div>

      <h3 class="col-12">Descrizione</h3>
      <div class="col-12 mb-4">{{ annuncio.descrizione }}</div>

      <h3 class="col-12">Caratteristiche</h3>
      <dl class="col-12 row mb-4">
        <dt class="col-12 col-sm-3">Tipologia</dt>
        <dd class="col-12 col-sm-9">{{ annuncio.unita_condominiale.tipologia.nome }}</dd>
        <dt class="col-12 col-sm-3">Tipo Proprietà</dt>
        <dd class="col-12 col-sm-9">{{ annuncio.unita_condominiale.tipo_proprieta }}</dd>
        <dt class="col-12 col-sm-3">Superficie</dt>
        <dd class="col-12 col-sm-9">{{ thousandsSeparator(Math.floor(annuncio.unita_condominiale.superficie)) }} m²</dd>
        <dt class="col-12 col-sm-3">Locali</dt>
        <dd class="col-12 col-sm-9">{{ annuncio.unita_condominiale.numero_locali }} ({{ annuncio.unita_condominiale.numero_bagni }} Bagni)</dd>
        <dt class="col-12 col-sm-3">Piano</dt>
        <dd class="col-12 col-sm-9">{{ annuncio.unita_condominiale.piano }}</dd>
        <dt class="col-12 col-sm-3">Efficienza Energetica</dt>
        <dd class="col-12 col-sm-9">{{ classe_energetica }}</dd>
      </dl>

      <h3 class="col-12">Costi</h3>
      <dl class="col-12 row mb-4">
        <dt class="col-12 col-sm-3">Prezzo {{annuncio.tipologia.nome}}</dt>
        <dd class="col-12 col-sm-9">€ {{ thousandsSeparator(annuncio.prezzo) }}<span v-if="annuncio.id_tipologia=1">/mese</span></dd>
        <dt class="col-12 col-sm-3">Spese Condominio</dt>
        <dd class="col-12 col-sm-9">€ {{ thousandsSeparator(Number(annuncio.unita_condominiale.spese_condominiali).toFixed(2)) }}/mese</dd>
      </dl>


      <h2 class="col-12" v-if="!interessato">Sei interessato a questo annuncio?</h2>
      <div class="col-12 col-sm-5">
      <template v-if="logged && !interessato">
        <button class="form-control btn btn-secondary" @click="setInteressatoInterno()">Aggiungimi alla coda</button>
      </template>
      <template v-else-if="!logged">
        <form action="">
          <label for="nome">Nome</label>
          <input id="nome" name="nome" :class="formInputClass('name')" type="text" placeholder="Nome" v-model="name">
          <span class="invalid-feedback" role="alert" v-if="errors['name'] !== undefined">
            <strong>{{ errors['name'][0] }}</strong>
          </span>
          <label for="cognome">Cognome</label>
          <input id="cognome" name="cognome" :class="formInputClass('cognome')" type="text" placeholder="Cognome" v-model="cognome">
          <span class="invalid-feedback" role="alert" v-if="errors['cognome'] !== undefined">
            <strong>{{ errors['cognome'][0] }}</strong>
          </span>
          <label for="cellulare">Cellulare</label>
          <input id="cellulare" name="cellulare" :class="formInputClass('cellulare')" type="tel" placeholder="Cellulare" v-model="cellulare">
          <span class="invalid-feedback" role="alert" v-if="errors['cellulare'] !== undefined">
            <strong>{{ errors['cellulare'][0] }}</strong>
          </span>
          <label for="telefono">Telefono</label>
          <input id="telefono" name="telefono" :class="formInputClass('telefono')" type="tel" placeholder="Telefono" v-model="telefono">
          <span class="invalid-feedback" role="alert" v-if="errors['telefono'] !== undefined">
            <strong>{{ errors['telefono'][0] }}</strong>
          </span>
          <label for="email">Email</label>
          <input id="email" name="email" :class="formInputClass('email')" type="email" placeholder="Email" v-model="email">
          <span class="invalid-feedback" role="alert" v-if="errors['email'] !== undefined">
            <strong>{{ errors['email'][0] }}</strong>
          </span>
          <button type="button" class="form-control btn btn-secondary mt-2" @click="setInteressatoEsterno()">Aggiungimi alla coda</button>
        </form>
      </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['token', 'id_annuncio', 'logged', 'interessato'],
  data () {
    return {
      annuncio: null,
      name: '',
      cognome: '',
      email: '',
      telefono: '',
      cellulare: '',
      errors: {},
    };
  },
  beforeMount () {
    $.ajax({
      url: '/annunci/'+this.id_annuncio+'/json',
      method: 'get',
      async: false,
      success: function (response) {
        this.annuncio = response.annuncio;
      }.bind(this),
    });
  },
  methods: {
    thousandsSeparator(x) {
      var f = function (val) {
        if (val.length > 3)
          return f(val.slice(0,-3))+','+val.slice(-3);
        return val;
      }
      var int_part = Math.trunc(x);
      var float_part = x-Math.trunc(x);
      float_part = float_part.toFixed(2).split('.')[1]
      var s_int_part = f(String(int_part));
      return s_int_part + '.' + float_part;
     //  return x.toString().replace(".",",").replace(/\B(?<!,\d*)(?=(\d{3})+(?!\d))/g, ".");
    },
    setInteressatoInterno () {
      $.post(`/annuncio/${this.id_annuncio}/interessato`, { _token: this.token }, function () {
        alert('Sei stato aggiunto alla coda e sarai contattato prima possibile');
        window.location.reload();
      }.bind(this));
    },
    setInteressatoEsterno () {
      var data = {
        _token: this.token,
        name: this.name,
        cognome: this.cognome,
        email: this.email,
        telefono: this.telefono,
        cellulare: this.cellulare,
      };
      $.ajax({
        url: `/annuncio/${this.id_annuncio}/interessatoEsterno`,
        method: 'post',
        data: data,
        async: true,
        success: function () {
          alert('Dati inviati correttamente, sarai contattato prima possibile');
          window.location.reload();
        },
        error: function (response) {
          this.errors = response.responseJSON.errors;
        }.bind(this),
      });
    },
    formInputClass (attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
  },
  computed: {
    classe_energetica () {
      if (this.annuncio.unita_condominiale.classe_energetica !== null)
        return this.annuncio.unita_condominiale.classe_energetica.nome;
      return "";
    },
  },
}
</script>
