<template>
<div class="container">
  <h1 class="m-0 text-dark mb-3">{{ __('translation.condominio_singolo') }}</h1>

  <form action="" class="mb-4">
    <div class="row">
        <div class="col-12 col-sm-4">
            <label for="nome">Nome</label>
            <input id="nome" name="nome" :class="formInputClass('nome')" type="text" value="" placeholder="Nome" v-model="condominio.nome" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['nome'] !== undefined">
              <strong>{{ errors['nome'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="descrizione">Descrizione</label>
            <input id="descrizione" name="descrizione" :class="formInputClass('descrizione')" type="text" value="" placeholder="Descrizione" v-model="condominio.descrizione" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['descrizione'] !== undefined">
              <strong>{{ errors['descrizione'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="id_proprietario">Proprietario</label>
            <select id="id_proprietario" name="id_proprietario" :class="formInputClass('id_proprietario')" v-model="proprietario" :disabled="role == 'user'">
              <option v-for="proprietario in proprietari" :key="proprietario.id" :value="proprietario.id">{{ proprietario.name ? proprietario.name : proprietario.ragione_sociale }}</option>
            </select>
            <span class="invalid-feedback" role="alert" v-if="errors['id_proprietario'] !== undefined">
              <strong>{{ errors['id_proprietario'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="amministratore">Amministratore</label>
            <select id="amministratore" name="amministratore" :class="formInputClass('id_amministratore')" v-model="amministratore" :disabled="role == 'user'">
              <option v-for="amministratore in amministratori" :key="amministratore.id" :value="amministratore.id">{{ amministratore.name }}</option>
            </select>
            <span class="invalid-feedback" role="alert" v-if="errors['id_amministratore'] !== undefined">
              <strong>{{ errors['id_amministratore'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="communityManager">Community Manager</label>
            <select id="communityManager" name="communityManager" :class="formInputClass('id_community_manager')" v-model="communityManager" :disabled="role == 'user'">
              <option v-for="cm in communityManagers" :key="cm.id" :value="cm.id">{{ cm.name }}</option>
            </select>
            <span class="invalid-feedback" role="alert" v-if="errors['id_community_manager'] !== undefined">
              <strong>{{ errors['id_community_manager'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="facilityManager">Facility Manager</label>
            <select id="facilityManager" name="facilityManager" :class="formInputClass('id_facility_manager')" v-model="facilityManager" :disabled="role == 'user'">
              <option v-for="fm in facilityManagers" :key="fm.id" :value="fm.id">{{ fm.name }}</option>
            </select>
            <span class="invalid-feedback" role="alert" v-if="errors['id_facility_manager'] !== undefined">
              <strong>{{ errors['id_facility_manager'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="numero_unita_condominiali">Numero Unità condominiali</label>
            <!--<input id="numero_unita_condominiali" name="numero_unita_condominiali" class="form-control" type="number" placeholder="Numero Unità condominiali" :value="unitaCondominiali ? unitaCondominiali.length : 0" disabled>-->
            <input id="numero_unita_condominiali" name="numero_unita_condominiali" class="form-control" type="number" placeholder="Numero Unità condominiali" :value="condominio.n_unita_condominiali" disabled>
        </div>
        <div class="col-12 col-sm-4">
            <label for="created_at">Data Creazione</label>
            <input id="created_at" name="created_at" class="form-control" type="text" placeholder="Data Creazione" :value="createdAt" disabled>
        </div>
        <!--
          -<div class="col-12 col-sm-4">
          -    <label for="foglio">Foglio</label>
          -    <input id="foglio" name="foglio" :class="formInputClass('foglio')" type="text" value="" placeholder="Foglio" v-model="condominio.foglio" :disabled="role == 'user'">
          -    <span class="invalid-feedback" role="alert" v-if="errors['foglio'] !== undefined">
          -      <strong>{{ errors['foglio'][0] }}</strong>
          -    </span>
          -</div>
          -<div class="col-12 col-sm-4">
          -    <label for="particella">Particella</label>
          -    <input id="particella" name="particella" :class="formInputClass('particella')" type="text" value="" placeholder="Particella" v-model="condominio.particella" :disabled="role == 'user'">
          -    <span class="invalid-feedback" role="alert" v-if="errors['particella'] !== undefined">
          -      <strong>{{ errors['particella'][0] }}</strong>
          -    </span>
          -</div>
        -->
        <div class="col-12 col-sm-4">
            <label for="created_at">Data Costruzione</label>
            <daterange-picker v-model="condominio.data_costruzione" class="form-control"></daterange-picker>
        </div>
        <div class="col-12  col-sm-4">
            <label for="iban">Iban Locazione</label>
            <input id="iban" name="iban" :class="formInputClass('iban')" type="text" v-model="condominio.iban" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['iban'] !== undefined">
                <strong>{{ errors['iban'][0] }}</strong>
            </span>
        </div>
        <div class="col-12  col-sm-4">
            <label for="iban_generico">Iban Condominio</label>
            <input id="iban_generico" name="iban_generico" :class="formInputClass('iban_generico')" type="text" v-model="condominio.iban_generico" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['iban_generico'] !== undefined">
                <strong>{{ errors['iban_generico'][0] }}</strong>
            </span>
        </div>
        <div class="col-12">
            <label for="note">Note</label>
            <input id="note" name="note" :class="formInputClass('note')" type="text" value="" placeholder="Note" v-model="condominio.note" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['note'] !== undefined">
              <strong>{{ errors['note'][0] }}</strong>
            </span>
        </div>
      </div>
      <indirizzo :token="token" :indirizzo="indirizzo" :creazione="creazione" ref="indirizzo" :disabled="role == 'user'"></indirizzo>
  </form>
    <div class="row" v-if="role !== 'user'">
        <div class="offset-sm-6 col-6 col-sm-3" v-if="!creazione">
          <a :href="'/condominio/'+id_condominio+'/rate'" class="btn bg-primary w-100" target="_blank">Visualizza Rate</a>
        </div>
        <div :class="{'offset-sm-9': creazione, 'offset-6': creazione, 'col-6': true, 'col-sm-3': true}">
            <button type="button" class="btn bg-primary w-100" @click="salva()">Salva</button>
        </div>
    </div>
    <template v-if="!creazione">
      <hr>
      <template v-if="canSeeUnitaCondominiali">
        <h4>{{ __('translation.lista_unita_condominiali') }}</h4>

        <!-- /Datatable unità condominiali -->
        <table class="table table-striped dataTable" id="dataTable" ref="tableUnita">
          <thead>
            <tr>
              <th>Id</th>
              <th>Nome</th>
              <th>Interno</th>
              <th>Piano</th>
              <th>Tipologia</th>
              <th>Stato</th>
              <th>Data Creazione</th>
              <th>Azioni</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
        <!-- Datatable unità condominiali -->

        <div class="row" v-if="role != 'user'">
          <div class="offset-sm-9 col-12 col-sm-3 mt-2">
            <a :href="'/unitaCondominiale/crea?id_condominio='+id_condominio" class="btn bg-primary w-100" target="_blank">Aggiungi nuova unità</a>
          </div>
        </div>

        <hr>
      </template>
      <h4>{{ __('translation.lista_documenti_condominio') }}</h4>

      <!-- Datatable documenti -->
      <table class="table table-striped dataTable" id="dataTable2" ref="tableDocumenti">
          <thead>
              <tr>
                  <th>Id</th>
                  <th>Nome</th>
                  <th>Download</th>
                  <th>Tipologia</th>
                  <th>Data Caricamento</th>
                  <th>Azioni</th>
              </tr>
          </thead>
          <tbody>
          </tbody>
      </table>
      <!-- /Datatable documenti -->

      <div class="row">
          <!-- Button trigger modal -->
          <div class="offset-sm-9 col-12 col-sm-3 mt-2" v-if="can_upload">
              <button class="btn bg-primary w-100" data-toggle="modal" data-target="#carica_documento">Carica nuovo documento</button>
          </div>
          <!-- Modal -->
          <div class="modal fade" id="carica_documento" role="dialog" aria-labelledby="carica_documento" aria-hidden="true" ref="modalDocumento">
              <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title">Carica nuovo documento</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <div class="modal-body">
                          <form action="">
                          <!--
                            -<label for="nome_documento">Nome Documento</label>
                            -<input id="nome_documento" name="nome_documento" class="form-control" type="text" placeholder="Nome Documento" v-model="nomeDocumento" required>
                            -<label for="tipo_documento" class="mt-4">Tipo Documento</label>
                            -<select id="tipo_documento" name="tipo_documento" class="form-control" type="file" v-model="tipoDocumentoSelezionato">
                            -  <option v-for="tipo in tipologieDocumentoImmobiliare" :key="tipo.id" :value="tipo.id">{{ tipo.nome }}</option>
                            -</select>
                            -<label for="file_documento" class="mt-4">Carica File</label>
                            -<input id="file_documento" name="file_documento" type="file" ref="fileDocumento" required>
                            -->
                            <label for="nome_documento">Nome Documento</label>
                            <input id="nome_documento" name="nome_documento" :class="formInputClassDoc('nome')" type="text" placeholder="Nome Documento" v-model="nomeDocumento" required>
                            <span class="invalid-feedback" role="alert" v-if="docErrors['nome'] !== undefined">
                              <strong>{{ docErrors['nome'][0] }}</strong>
                            </span>

                            <label for="tipo_documento" class="mt-4">Tipo Documento</label>
                            <select id="tipo_documento" name="tipo_documento" :class="formInputClassDoc('tipologia')" v-model="tipoDocumentoSelezionato">
                              <option v-for="tipo in tipologieDocumentoImmobiliare" :key="tipo.id" :value="tipo.id">{{ tipo.nome }}</option>
                            </select>
                            <span class="invalid-feedback" role="alert" v-if="docErrors['tipologia'] !== undefined">
                              <strong>{{ docErrors['tipologia'][0] }}</strong>
                            </span>

                            <label for="privato" class="mt-4">Visibilità</label>
                            <select id="privato" name="privato" :class="formInputClassDoc('privato')" type="file" v-model="documentoPrivato">
                              <option :value="false">visibile</option>
                              <option :value="true">non visibile</option>
                            </select>
                            <span class="invalid-feedback" role="alert" v-if="docErrors['privato'] !== undefined">
                              <strong>{{ docErrors['privato'][0] }}</strong>
                            </span>

                            <label for="file_documento" class="mt-4">Carica File</label>
                            <input id="file_documento" name="file_documento" :class="formInputClassDoc('file')" type="file" ref="fileDocumento" required>
                            <span class="invalid-feedback" role="alert" v-if="docErrors['file'] !== undefined">
                              <strong>{{ docErrors['file'][0] }}</strong>
                            </span>
                          </form>
                      </div>
                      <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                          <button type="button" class="btn bg-primary" @click="salvaDocumento">Salva</button>
                      </div>
                  </div>
              </div>
          </div>
          <!-- /Modal -->
      </div>
    </template>
</div>
</template>

<script>
export default {
  props: ['token', 'id_condominio', 'creazione', 'role', 'can_upload', 'id_ruolo_utente'],
  data () {
    return {
      errors: {},
      docErrors: {},
      amministratore: null,
      amministratori: null,
      proprietari: null,
      proprietario: null,
      communityManagers: null,
      communityManager: null,
      facilityManagers: null,
      facilityManager: null,
      condominio: null,
      documenti: null,
      documentoPrivato: false,
      fileDocumento: null,
      indirizzo: null,
      nomeDocumento: '',
      tableDocumenti: null,
      tableUnita: null,
      tipoDocumentoSelezionato: null,
      tipologieDocumentoImmobiliare: null,
      translations: null,
      unitaCondominiali: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    if (!this.creazione) {// Stiamo visualizzando un condominio
      $.ajax({
        method: 'get',
        async: false,
        url: '/condominio/'+this.id_condominio+'/json',
        success: function (response) {
          this.condominio = response.condominio;
          this.amministratore = response.condominio.amministratore.id;
          if (response.condominio.community_manager !== null)
            this.communityManager = response.condominio.community_manager.id;
          if (response.condominio.facility_manager !== null)
            this.facilityManager = response.condominio.facility_manager.id;
          if (response.condominio.proprietario !== null)
            this.proprietario = response.condominio.proprietario.id;
          this.indirizzo = this.condominio.indirizzo;
          console.log(this.proprietario);
        }.bind(this),
      });
      $.get('/condominio/'+this.id_condominio+'/unitaCondominiali/json', function (response) {
        this.unitaCondominiali = response.unitaCondominiali;
        this.updateUnitaCondominiali();
      }.bind(this));
      this.requestDocumenti();
      $.get('/tipologieDocumentoImmobiliare/json', function (response) {
        this.tipologieDocumentoImmobiliare = response.tipologieDocumentoImmobiliare;
      }.bind(this));
    } else {
      this.condominio = {
        nome: '',
        descrizione: '',
        foglio: '',
        particella: '',
      };
    }
    if (this.role == 'user') { // Per gli utenti, dato che non devono avere la lista di tutti gli utenti mi faccio dare soltanto la lista degli utenti collegati al condominio
      $.get('/condominio/'+this.id_condominio+'/amministratori/json', function (response) {
        this.amministratori = response.utenti.filter((x) => x !== null);
        this.communityManagers = response.utenti.filter((x) => x !== null);
        this.facilityManagers = response.utenti.filter((x) => x !== null);
      }.bind(this));
    } else {
      $.get('/amministratori/json', function (response) {
        this.amministratori = response.amministratori;
        if (this.creazione)
          this.amministratore = this.amministratori[0].id;
      }.bind(this));
      $.get('/communitymanager/json', function (response) {
        this.communityManagers = response.community_managers;
      }.bind(this));
      $.get('/facilitymanager/json', function (response) {
        this.facilityManagers = response.facility_managers;
      }.bind(this));
      $.get('/proprietari/json', function (response) {
        this.proprietari = response.proprietari;
      }.bind(this));
    }
  },
  mounted () {
    // $.fn.dataTable.moment('DD/MM/YYYY HH:mm');
  this.tableUnita = $(this.$refs.tableUnita).DataTable( {
      language: {
        url: '/js/dataTables/Italian.json',
        searchPlaceholder: 'Cerca...',
        buttons: { colvis: 'Colonne'}
      },
      dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
      buttons: ['colvis','excel','csv'], //Buttons
      "responsive": true,
      "ordering": true,
      "searching": true,
      "bLengthChange": false,
      "pageLength": 50
  });

  this.tableDocumenti = $(this.$refs.tableDocumenti).DataTable( {
      language: {
        url: '/js/dataTables/Italian.json',
        searchPlaceholder: 'Cerca...',
        buttons: { colvis: 'Colonne'}
      },
      dom: '<".row"<"#buttons2.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter2.col-sm-4.col-12"f><"#pagination2.col-sm-4 col-12"p><"#table2.col-12"t><"#length2"l><"#information2"i>>',
      buttons: ['colvis','excel','csv'], //Buttons
      "responsive": true,
      "ordering": true,
      "searching": true,
      "bLengthChange": false,
      "pageLength": 50
  });
  $(this.$refs.modalDocumento).on('hide.bs.modal', function (e) {
    this.modalDocumentoClosed();
  }.bind(this));
},
  methods: {
    requestDocumenti (async = true) {
      $.ajax({
        url: `/condominio/${this.id_condominio}/documenti/json`,
        method: 'get',
        async: async,
        success: function (response) {
          this.documenti = response.documenti;
          this.updateDocumenti();
        }.bind(this)
      });
    },
    modalDocumentoClosed () {
      this.$refs.fileDocumento.value = "";
      this.nomeDocumento = "";
      this.tipoDocumentoSelezionato = null;
    },
    chiudiDocumento () {
      $(this.$refs.modalDocumento).modal('hide');
      this.nomeDocumento = '';
      this.tipoDocumentoSelezionato = null;
      $(this.$refs.fileDocumento).val('');
    },
    salvaDocumento () {
      var formData = new FormData();
      formData.append('file', this.$refs.fileDocumento.files[0]);
      formData.append('nome', this.nomeDocumento);
      formData.append('tipologia', this.tipoDocumentoSelezionato);
      formData.append('privato', this.documentoPrivato ? 1 : 0);
      axios.post('/condominio/'+this.id_condominio+'/uploadDocumento',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function () {
          this.chiudiDocumento();
          alert('Documento salvato');
          this.requestDocumenti(false);
          this.updateDocumenti();
          this.docErrors = {};
        }.bind(this)).catch(function (error) {
          if (error.response.status == 413)
            this.docErrors = {'file': ['File troppo grande.']};
          else
            this.docErrors = error.response.data.errors;
        }.bind(this));
    },
    updateUnitaCondominiali () {
      this.tableUnita.clear();
      this.unitaCondominiali.forEach(function (unita) {
        if (unita.disabilitato == 1)
          return;
        var unitaLink = `
        <a href="/unitaCondominiale/${unita.id}/singolo" target="_blank">${unita.nome}</a>
        `;
        var azioni = '';
        if (this.canDisableUnita)
          azioni = `<button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-circle-off-outline disable-unita" data-id="${unita.id}"></button>`;
        this.tableUnita.row.add([
          unita.id,
          unitaLink,
          unita.interno,
          unita.piano,
          unita.tipologia.nome,
          unita.stato.nome,
          moment(String(unita.created_at)).format('DD/MM/YYYY HH:mm'),
          azioni,
        ]);
      }.bind(this));
      this.tableUnita.draw();
      $('button.disable-unita').on('click', function (e) {
        this.disableUnita($(e.target).data('id'));
      }.bind(this));
    },
    updateDocumenti () {
      this.tableDocumenti.clear();
      this.documenti.forEach(function (doc) {
        var path = '/documenti/condominio/'+doc.id;
        var buttonDelete =  "";
        if (this.role !== 'user')
          buttonDelete = `<button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever disable-documento" data-toggle="tooltip" title="Elimina Documento" data-id="${doc.id}"></button>`;
        this.tableDocumenti.row.add([
          doc.id,
          doc.nome,
          `
          <a href="${path}" target="_blank">Download</a>
          `,
          doc.tipologia.nome,
          moment(String(doc.created_at)).format('DD/MM/YYYY HH:mm'),
          buttonDelete,
        ]);
      }.bind(this));
      this.tableDocumenti.draw();
      $('button.disable-documento').on('click', function (e) {
        this.disableDocumento($(e.target).data('id'));
      }.bind(this));
    },
    disableDocumento (id) {
      if (confirm('Sei sicuro di voler procedere?')) {
        $.post(`/documenti/condominio/${id}/disabilita`, {_token: this.token}, function (response) {
          this.requestDocumenti();
          this.updateDocumenti();
        }.bind(this));
      }
    },
    disableUnita (id) {
      if (confirm('Sei sicuro di voler procedere?')) {
        $.post(`/unitaCondominiale/${id}/disabilita`, {_token: this.token}, function () {
          $.get('/condominio/'+this.id_condominio+'/unitaCondominiali/json', function (response) {
            this.unitaCondominiali = response.unitaCondominiali;
            this.updateUnitaCondominiali();
          }.bind(this));
        }.bind(this)).fail(function () {
          alert('Permessi non sufficienti');
        });
        // Ricarico la lista di unità condominiali
      }
    },
    salva () {
      console.log('salvataggio');
      var data = {
        _token: this.token,
        nome: this.condominio.nome,
        descrizione: this.condominio.descrizione,
        foglio: this.condominio.foglio,
        note: this.condominio.note,
        iban: this.condominio.iban,
        iban_generico: this.condominio.iban_generico,
        data_costruzione: this.condominio.data_costruzione,
        particella: this.condominio.particella,
        id_amministratore: this.amministratore,
        id_community_manager: this.communityManager,
        id_facility_manager: this.facilityManager,
        id_proprietario: this.proprietario,
      };
      if (this.creazione) {
        var id_indirizzo = this.$refs.indirizzo.salva();
        if (id_indirizzo !== null) {
          if (id_indirizzo == 0) {
            alert('L\'indirizzo è obbligatorio');
            return;
          }
          data['id_indirizzo'] = id_indirizzo;
          $.post('/condominio/crea', data, function (response) {
            alert('Condominio Salvato');
            window.location.replace('/condominio/'+response.id+'/singolo');
          }).fail(function (response) {
            this.errors = response.responseJSON.errors;
          }.bind(this));
        }
      } else {
        this.$refs.indirizzo.salva();
        $.post('/condominio/'+this.condominio.id+'/modifica', data, function () {
          alert('Condominio Salvato');
          window.location.reload();
        }).fail(function(response) {
          this.errors = response.responseJSON.errors;
        }.bind(this));
      }
    },
    formInputClass (attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
    formInputClassDoc (attr) {
      return { 'form-control': true, 'is-invalid': this.docErrors[attr] !== undefined };
    },
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    createdAt () {
      if (this.condominio.created_at != null)
        return moment(String(this.condominio.created_at)).format('DD/MM/YYYY');
      else
        return 'Data Creazione';
    },
    canDisableUnita () {
      return [1,6,8].includes(this.id_ruolo_utente);
    },
    canSeeUnitaCondominiali () {
      return this.id_ruolo_utente != 5;
    },
  },
  watch: {
  },
}
</script>
