<template>
  <div class="container">
    <div class="modal fade" role="dialog" aria-hidden="true" ref="modalCaricamento">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Caricamento</h5>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center">
              <div class="spinner-grow text-primary ml-auto mr-auto" role="status">
                <span class="sr-only">Caricamento</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <h1 class="col-12">Import Spese di Condominio</h1>
      <div class="col-12 col-sm-3">
        <label for="file">Seleziona un file</label>
        <input type="file" ref="fileImport" >
      </div>
      <div class="col-12 col-sm-2">
        <label for="importbutton">Conferma importazione</label>
        <button class="form-control btn btn-primary" :class="{ 'bg-success': importOk }" @click="importa()">Importa</button>
      </div>
      <div class="col-12 col-sm-2">
        <label for="">Crea associazioni</label>
        <input type="checkbox" class="form-control w-auto" v-model="creaAssociazioni">
      </div>
      <div class="col-12 col-sm-3">
        <label>Condominio</label>
        <select v-model="condominio" class="form-control">
          <option :value="null"></option>
          <option v-for="c in condomini" :key="c.id" :value="c">{{ c.nome }}</option>
        </select>
      </div>
      <div class="col-12 col-sm-2">
        <label for="send">Invia al server</label>
        <button name="send" type="button" class="form-control btn btn-primary" @click="invia()" :disabled="!inviaPronto">Invia</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2" v-if="errors.length > 0">
      <h2>Errori riscontrati:</h2>
        <table class="table table-striped dataTable">
          <thead>
            <tr>
              <th>Colonna</th>
              <th>Cognome Nome</th>
              <th>Subalterno</th>
              <th>Descrizione Errore</th>
            </tr>
          </thead>

                        <!--
                          -'row' => $row,
                          -'nomecognome' => $d['nomecognome'],
                          -'subalterno' => $d['subalterno'],
                          -'error' => "L'utente non ha un contratto in corso con l'unità condominiale",
                          -'code' => 4,
                          -->
          <tbody>
            <tr v-for="error, i in errors" :key="i">
              <td>{{ error.row+1 }}</td>
              <td class="text-left">{{ error.nomecognome}}</td>
              <td>{{ error.subalterno}}</td>
              <td class="text-left">{{ error.error }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['token'],
  data () {
    return {
      creaAssociazioni: false,
      errors: [],
      fr: null,
      condomini: [],
      condominio: null,
      privFileData: null,
      date: [],
      piano: [],
      importOk: false,
    };
  },
  beforeMount () {
    $.get('/condominio/lista/json', function (response) {
      this.condomini = response.condomini;
    }.bind(this));
  },
  methods: {
    importa () {
      var file = $(this.$refs.fileImport)[0].files[0];
      this.fr = new FileReader();
      this.fr.onload = this.fileLoaded;
      this.fr.readAsBinaryString(file);
    },
    fileLoaded () {
      var table = XLSX.read(this.fr.result, {type:'binary'});
      var sheet = table.Sheets[table.SheetNames[0]];
      this.privFileData = XLSX.utils.sheet_to_json(sheet, {header:1});
      this.parse();
    },
    parse () {
      // Estraggo le date dal piano
      var head = this.privFileData[6];
      var rate = head.slice(6,18);
      this.date = [];
      rate.forEach(function (rata) {
        var data = rata.split(' ').pop();
        this.date.push(moment(data, 'DD/MM/YYYY'));
      }.bind(this));

      // Estraggo le informazioni sui singoli inquilini
      this.piano = [];
      var data = this.privFileData.slice(7);
      var BreakException = {};
      try {
        data.forEach(function (row) {
          if (row[0] == 'TOTALE COMPLESSIVO')
            throw BreakException;
          var nomecognome = row[0];
          if (nomecognome.startsWith('Spett.le'))
            nomecognome = nomecognome.substr(9);
          if (nomecognome.startsWith('Spett.li'))
            nomecognome = nomecognome.substr(9);
          if (nomecognome.startsWith('Coniugi'))
            nomecognome = nomecognome.substr(8);
          if (nomecognome.startsWith('Famiglia'))
            nomecognome = nomecognome.substr(9);
          var subalterno = row[1];
          var rate = row.slice(6,18);
          this.piano.push({
            'nomecognome': nomecognome,
            'subalterno': subalterno,
            'rate': rate,
          });
        }.bind(this));
      } catch (e) {
        if (e !== BreakException) throw e;
      }
      this.importOk = true;
      alert('File caricato');
    },
    invia () {
      var data = {
        id_condominio: this.condominio.id,
        date: this.date.map((x) => x.format('YYYY-MM-DD')),
        data: JSON.stringify(this.piano),
        _token: this.token,
      };
      if (this.creaAssociazioni)
        data['crea_associazioni'] = 1;
      $(this.$refs.modalCaricamento).modal({backdrop: 'static', keyboard: false});
      $.post('/import/danea', data, function (response) {
        alert('Import Completato');
        window.location.href = '/condominio/'+this.condominio.id+'/rate';
        $(this.$refs.modalCaricamento).modal('hide');
      }.bind(this)).fail(function (response) {
        this.errors = response.responseJSON.errors;
        setTimeout(function () {
          $(this.$refs.modalCaricamento).modal('hide');
        }.bind(this), 500);
      }.bind(this));
    },
  },
  computed: {
    inviaPronto () {
      return this.importOk && this.condominio !== null;
    },
  },
}
</script>
