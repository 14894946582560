<template>
<div class="container">
    <h1 class="m-0 text-dark mb-3">{{ __('translation.unita_condominiale_singola') }}</h1>

    <form action="" class="mb-4">
    <div class="row">
        <div class="col-12 col-sm-4">
            <label for="nome">Nome</label>
            <input id="nome" name="nome" :class="formInputClass('nome')" type="text" v-model="unitaCondominiale.nome"
                :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['nome'] !== undefined">
                <strong>{{ errors['nome'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="piano">Piano</label>
            <input id="piano" name="piano" :class="formInputClass('piano')" type="number"
                v-model="unitaCondominiale.piano" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['piano'] !== undefined">
                <strong>{{ errors['piano'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="interno">Interno</label>
            <input id="interno" name="interno" :class="formInputClass('interno')" type="text"
                v-model="unitaCondominiale.interno" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['interno'] !== undefined">
                <strong>{{ errors['interno'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="tipoProprieta">Tipologia</label>
            <select id="tipoProprieta" name="tipoProprieta" :class="formInputClass('id_tipologia_unita_condominiale')"
                v-model="unitaCondominiale.id_tipologia_unita_condominiale" :disabled="role == 'user'">
                <option v-for="tipo in tipologie" :key="tipo.id" :value="tipo.id">{{ tipo.nome }}</option>
            </select>
            <span class="invalid-feedback" role="alert" v-if="errors['id_tipologia_unita_condominiale'] !== undefined">
                <strong>{{ errors['id_tipologia_unita_condominiale'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="stato">Stato</label>
            <select id="stato" name="stato" :class="formInputClass('id_stato_unita_condominiale')"
                v-model="unitaCondominiale.id_stato_unita_condominiale" :disabled="role == 'user'">
                <option v-for="stato in stati" :key="stato.id" :value="stato.id">{{ stato.nome }}</option>
            </select>
            <span class="invalid-feedback" role="alert" v-if="errors['id_stato_unita_condominiale'] !== undefined">
                <strong>{{ errors['id_stato_unita_condominiale'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="foglio">Foglio</label>
            <input id="foglio" name="foglio" :class="formInputClass('foglio')" type="text"
                v-model="unitaCondominiale.foglio" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['foglio'] !== undefined">
                <strong>{{ errors['foglio'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="particella">Particella</label>
            <input id="particella" name="particella" :class="formInputClass('particella')" type="text"
                v-model="unitaCondominiale.particella" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['particella'] !== undefined">
                <strong>{{ errors['particella'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="subalterno">Subalterno</label>
            <input id="subalterno" name="subalterno" :class="formInputClass('subalterno')" type="text"
                v-model="unitaCondominiale.subalterno" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['subalterno'] !== undefined">
                <strong>{{ errors['subalterno'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="posizione">Posizione Appartamento</label>
            <input id="posizione" name="posizione"
                :class="formInputClass('posizione')" type="text"
                v-model="unitaCondominiale.posizione" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['posizione'] !== undefined">
                <strong>{{ errors['posizione'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="prot">Prot. Regist. Agenzia Entrate</label>
            <input id="prot" name="regist_agenzia_entrate"
                :class="formInputClass('prot')" type="text"
                v-model="unitaCondominiale.prot" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['prot'] !== undefined">
                <strong>{{ errors['prot'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="created_at">Data Creazione</label>

            <input id="created_at" name="created_at" class="form-control" type="text" placeholder="Data Creazione"
                disabled v-if="creazione">

            <input id="created_at" name="created_at" class="form-control" type="text" placeholder="Data Creazione"
                :value="createdAt" disabled v-else>
        </div>
        <div class="col-12 col-sm-4">
          <label for="proprietario">Proprietario</label>
            <select id="proprietario" name="proprietario" class="form-control" type="text" ref="selectProprietario"
                :disabled="role == 'user'">
              <option></option>
                <!--<option v-for="utente in utenti" :key="utente.key" :value="utente.id">{{ utente.name }}</option>-->
            </select>

            <span class="invalid-feedback d-block" role="alert" v-if="errors['id_proprietario'] !== undefined">
                <strong>{{ errors['id_proprietario'][0] }}</strong>
            </span>
        </div>
        <!--
          -<div class="col-12 col-sm-4">
          -    <label for="community_manager">Community Manager</label>
          -    <select id="community_manager" name="community_manager" class="form-control" type="text"
          -        ref="selectCommunityManager" :disabled="role == 'user'">
          -        <option></option>
          -    </select>
          -</div>
        -->
        <div class="col-12 col-sm-4 d-none">
            <label for="nucleo_familiare" v-if="unitaCondominiale.nucleo_familiare == null">Nucleo Familiare Associato</label>
            <label for="nucleo_familiare" v-else><a :href="nucleoFamiliareURL" target="_blank">Nucleo Familiare Associato</a></label>
            <select id="nucleo_familiare" name="nucleo_familiare" class="form-control" type="text" ref="selectNucleoFamiliare" :disabled="role == 'user'">
                <option></option>
            </select>
        </div>
        <div class="col-12 col-sm-4" v-if="!creazione">
            <label for="condominio"><a :href="condominioURL" target="_blank">Condominio</a></label>
            <input class="form-control" type="text" name="condominio" :value="unitaCondominiale.condominio.nome" id="condominio" disabled>
        </div>
        <div class="col-12 col-sm-2">
            <label for="procedura_hard">Procedura Hard</label>
            <input id="procedura_hard" name="procedura_hard" class="form-control" type="checkbox" v-model="unitaCondominiale.procedura_hard" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['procedura_hard'] !== undefined">
                <strong>{{ errors['procedura_hard'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-2">
            <label for="vuoto_per_pieno">Vuoto per Pieno</label>
            <input id="vuoto_per_pieno" name="vuoto_per_pieno" class="form-control" type="checkbox" v-model="unitaCondominiale.vuoto_per_pieno" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['vuoto_per_pieno'] !== undefined">
                <strong>{{ errors['vuoto_per_pieno'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-2">
            <label for="garanzia_locativa">Garanzia Locativa</label>
            <input id="garanzia_locativa" name="garanzia_locativa" class="form-control" type="checkbox" v-model="unitaCondominiale.garanzia_locativa" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['garanzia_locativa'] !== undefined">
                <strong>{{ errors['garanzia_locativa'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-10">
            <label for="note">Note</label>
            <input id="note" name="note" :class="formInputClass('note')" type="text" v-model="unitaCondominiale.note" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['note'] !== undefined">
                <strong>{{ errors['note'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="versato">Deposito Cauzionale</label>
            <input id="deposito_cauzionale" name="deposito_cauzionale" :class="formInputClass('deposito_cauzionale')" type="text" v-model="unitaCondominiale.deposito_cauzionale" :disabled="role == 'user'">
            <span class="invalid-feedback" role="alert" v-if="errors['deposito_cauzionale'] !== undefined">
                <strong>{{ errors['deposito_cauzionale'][0] }}</strong>
            </span>
        </div>
        <div class="col-12 col-sm-4">
            <label for="versato">Versato</label>
            <input id="versato" name="versato" :class="formInputClass('versato')" type="checkbox" v-model="unitaCondominiale.versato"  :checked="unitaCondominiale.versato" :disabled="role == 'user'">
        </div>

        <div id="datiAggiuntivi" role="tablist" aria-multiselectable="true" class="col-12 mt-2">
          <div class="card">
            <div class="card-header" role="tab" id="section1HeaderId">
              <h5 class="mb-0">
                <a data-toggle="collapse" data-parent="#datiAggiuntivi" href="#section1ContentId" aria-expanded="true" aria-controls="section1ContentId">Mostra dati aggiuntivi</a>
              </h5>
            </div>
            <div id="section1ContentId" class="collapse in p-3 p-sm-0" role="tabpanel" aria-labelledby="section1HeaderId">
              <div class="card-body row">
                <div class="col-12 col-sm-4">
                  <label for="prezzo_vendita">Prezzo vendita</label>
                  <input id="prezzo_vendita" name="prezzo_vendita" :class="formInputClass('prezzo_vendita')" type="number" v-model="unitaCondominiale.prezzo_vendita" :disabled="role == 'user'">
                  <span class="invalid-feedback" role="alert" v-if="errors['prezzo_vendita'] !== undefined">
                      <strong>{{ errors['prezzo_vendita'][0] }}</strong>
                  </span>
                </div>
                <div class="col-12 col-sm-4">
                  <label for="prezzo_affitto">Prezzo affitto</label>
                  <input id="prezzo_affitto" name="prezzo_affitto" :class="formInputClass('prezzo_affitto')" type="number" v-model="unitaCondominiale.prezzo_affitto" :disabled="role == 'user'">
                  <span class="invalid-feedback" role="alert" v-if="errors['prezzo_affitto'] !== undefined">
                      <strong>{{ errors['prezzo_affitto'][0] }}</strong>
                  </span>
                </div>
                <div class="col-12 col-sm-4">
                  <label for="prezzo_patto_riscatto">Prezzo patto e riscatto</label>
                  <input id="prezzo_patto_riscatto" name="prezzo_patto_riscatto" :class="formInputClass('prezzo_patto_riscatto')" type="number" v-model="unitaCondominiale.prezzo_patto_riscatto" :disabled="role == 'user'">
                  <span class="invalid-feedback" role="alert" v-if="errors['prezzo_patto_riscatto'] !== undefined">
                      <strong>{{ errors['prezzo_patto_riscatto'][0] }}</strong>
                  </span>
                </div>
                <div class="col-12 col-sm-4">
                  <label for="penale">Penale</label>
                  <input id="penale" name="penale" :class="formInputClass('penale')" type="number" v-model="unitaCondominiale.penale" :disabled="role == 'user'">
                  <span class="invalid-feedback" role="alert" v-if="errors['penale'] !== undefined">
                      <strong>{{ errors['penale'][0] }}</strong>
                  </span>
                </div>

                <div class="col-12 col-sm-4">
                  <label for="tipo_proprieta">Tipo Proprietà</label>
                  <input id="tipo_proprieta" name="tipo_proprieta" :class="formInputClass('tipo_proprieta')" type="text" v-model="unitaCondominiale.tipo_proprieta" :disabled="role == 'user'">
                  <span class="invalid-feedback" role="alert" v-if="errors['tipo_proprieta'] !== undefined">
                      <strong>{{ errors['tipo_proprieta'][0] }}</strong>
                  </span>
                </div>
                <div class="col-12 col-sm-4">
                  <label for="classe_energetica">Classe Energetica</label>
                  <select id="classe_energetica" name="classe_energetica" :class="formInputClass('id_classe_energetica')" v-model="unitaCondominiale.id_classe_energetica" :disabled="role == 'user'"> <option v-for="classe_energetica in classi_energetiche" :key="classe_energetica.id" :value="classe_energetica.id">{{ classe_energetica.nome }}</option>
                  </select>
                  <span class="invalid-feedback" role="alert" v-if="errors['id_classe_energetica'] !== undefined">
                      <strong>{{ errors['id_classe_energetica'][0] }}</strong>
                  </span>
                </div>
                <div class="col-12 col-sm-4">
                  <label for="superficie">Superficie (m²)</label>
                  <input id="superficie" name="superficie" :class="formInputClass('superficie')" type="number" v-model="unitaCondominiale.superficie" :disabled="role == 'user'">
                  <span class="invalid-feedback" role="alert" v-if="errors['superficie'] !== undefined">
                      <strong>{{ errors['superficie'][0] }}</strong>
                  </span>
                </div>
                <div class="col-12 col-sm-4">
                  <label for="spese_condominiali">Spese Condominiali (€)</label>
                  <input id="spese_condominiali" name="spese_condominiali" :class="formInputClass('spese_condominiali')" type="number" v-model="unitaCondominiale.spese_condominiali" :disabled="role == 'user'">
                  <span class="invalid-feedback" role="alert" v-if="errors['spese_condominiali'] !== undefined">
                      <strong>{{ errors['spese_condominiali'][0] }}</strong>
                  </span>
                </div>
                <div class="col-12 col-sm-4">
                  <label for="numero_locali">Numero Locali</label>
                  <input id="numero_locali" name="numero_locali" :class="formInputClass('numero_locali')" type="number" v-model="unitaCondominiale.numero_locali" :disabled="role == 'user'">
                  <span class="invalid-feedback" role="alert" v-if="errors['numero_locali'] !== undefined">
                      <strong>{{ errors['numero_locali'][0] }}</strong>
                  </span>
                </div>
                <div class="col-12 col-sm-4">
                  <label for="numero_bagni">Numero Bagni</label>
                  <input id="numero_bagni" name="numero_bagni" :class="formInputClass('numero_bagni')" type="number" v-model="unitaCondominiale.numero_bagni" :disabled="role == 'user'">
                  <span class="invalid-feedback" role="alert" v-if="errors['numero_bagni'] !== undefined">
                      <strong>{{ errors['numero_bagni'][0] }}</strong>
                  </span>
                </div>
                <div class="col-12 col-sm-2">
                    <label for="is_affitto_invoice">Affitto da fatturare</label>
                    <input id="is_affitto_invoice" name="is_affitto_invoice" class="form-control" type="checkbox" v-model="unitaCondominiale.is_affitto_invoice" :disabled="role == 'user'">
                    <span class="invalid-feedback" role="alert" v-if="errors['is_affitto_invoice'] !== undefined">
                        <strong>{{ errors['is_affitto_invoice'][0] }}</strong>
                    </span>
                </div>
                <div class="col-12 col-sm-2">
                    <label for="is_spese_invoice">Spese da Fatturare</label>
                    <input id="is_spese_invoice" name="is_spese_invoice" class="form-control" type="checkbox" v-model="unitaCondominiale.is_spese_invoice" :disabled="role == 'user'">
                    <span class="invalid-feedback" role="alert" v-if="errors['is_spese_invoice'] !== undefined">
                        <strong>{{ errors['is_spese_invoice'][0] }}</strong>
                    </span>
                </div>
                <div class="col-12 col-sm-2">
                    <label for="is_double_invoice">Fattura cointestatari</label>
                    <input id="is_double_invoice" name="is_double_invoice" class="form-control" type="checkbox" v-model="unitaCondominiale.is_double_invoice" :disabled="role == 'user'">
                    <span class="invalid-feedback" role="alert" v-if="errors['is_double_invoice'] !== undefined">
                        <strong>{{ errors['is_double_invoice'][0] }}</strong>
                    </span>
                </div>
                <gestore-immagini-unita-condominiale :unita_condominiale="unitaCondominiale" :token="token" v-if="role != 'user'"></gestore-immagini-unita-condominiale>
                <div id="uppy-upload" :class="uppyClass">
                </div>
              </div>
            </div>
          </div>
        </div>


        <!--<selettore-custom :lista="utenti" titolo="Seleziona Proprietario"></selettore-custom>-->
        <!--
        <div class="col-12 col-sm-4">
            <label for="proprietario">Proprietario</label>
            <input id="proprietario" name="proprietario" class="form-control" type="text" value="" placeholder="Proprietario">
        </div>
          <div class="col-12 col-sm-4">
              <label for="locatario">Locatario</label>
              <input id="locatario" name="locatario" class="form-control" type="text" value="" placeholder="Locatario">
          </div>
          <div class="col-12 col-sm-4">
              <label for="nucleo_familiare">Nucleo Familiare</label>
              <input id="nucleo_familiare" name="nucleo_familiare" class="form-control" type="text" value="" placeholder="Nucleo Familiare">
          </div>
        -->
    </div>
    </form>
    <div class="row" v-if="role !== 'user'">
      <template v-if="!creazione">
        <div class="offset-sm-4 col-sm-2 col-12">
          <a :href="'/annuncio/lista?unita_condominiale='+id_unita_condominiale" class="btn bg-primary w-100" target="_blank">Visualizza Inserzioni</a>
        </div>
        <div class="col-sm-2 col-12 mt-2 mt-sm-0">
          <a :href="'/annuncio/crea?unita_condominiale='+id_unita_condominiale" class="btn bg-primary w-100" target="_blank">Crea Inserzione</a>
        </div>
        <div class="col-sm-2 col-12 mt-2 mt-sm-0">
          <a :href="'/condominio/'+unitaCondominiale.id_condominio+'/rate?unita='+id_unita_condominiale" class="btn bg-primary w-100" target="_blank">Visualizza Rate</a>
        </div>
        <div class="col-sm-2 col-12 mt-2 mt-sm-0">
            <button type="button" class="btn bg-primary w-100" @click="salva">Salva</button>
        </div>
      </template>
      <template v-else>
        <div class="offset-sm-9 col-12 col-sm-3 mt-2">
            <button type="button" class="btn bg-primary w-100" @click="salva">Salva</button>
        </div>
      </template>
    </div>
    <template v-if="!creazione">

      <h4 class="mt-2">{{ __('translation.lista_documenti_unita_condominiale') }}</h4>

      <!-- Datatable documenti -->
      <table class="table table-striped dataTable" id="dataTable" ref="tableDocumenti">
          <thead>
              <tr>
                  <th>Tipologia</th>
                  <th>Nome</th>
                  <th>Download</th>
                  <th>Data Caricamento</th>
                  <th>Azioni</th>
              </tr>
          </thead>
          <tbody>
          </tbody>
      </table>
      <!-- /Datatable documenti -->

        <div class="row">
            <!-- Button trigger modal -->
            <div class="offset-sm-9 col-12 col-sm-3 mt-2" v-if="can_upload">
                <button class="btn bg-primary w-100" data-toggle="modal" data-target="#carica_documento">Carica nuovo documento</button>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="carica_documento" role="dialog" aria-labelledby="carica_documento" aria-hidden="true" ref="modalDocumento">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Carica nuovo documento</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form action="">
                            <label for="nome_documento">Nome Documento</label>
                            <input id="nome_documento" name="nome_documento" :class="formInputClassDoc('nome')" type="text" placeholder="Nome Documento" v-model="nomeDocumento" required>
                            <span class="invalid-feedback" role="alert" v-if="docErrors['nome'] !== undefined">
                              <strong>{{ docErrors['nome'][0] }}</strong>
                            </span>

                            <label for="tipo_documento" class="mt-4">Tipo Documento</label>
                            <select id="tipo_documento" name="tipo_documento" :class="formInputClassDoc('tipologia')" type="file" v-model="tipoDocumentoSelezionato">
                              <option v-for="tipo in tipologieDocumentoImmobiliare" :key="tipo.id" :value="tipo.id">{{ tipo.nome }}</option>
                            </select>
                            <span class="invalid-feedback" role="alert" v-if="docErrors['tipologia'] !== undefined">
                              <strong>{{ docErrors['tipologia'][0] }}</strong>
                            </span>

                            <label for="file_documento" class="mt-4">Carica File</label>
                            <input id="file_documento" name="file_documento" :class="formInputClassDoc('file')" type="file" ref="fileDocumento" required>
                            <span class="invalid-feedback" role="alert" v-if="docErrors['file'] !== undefined">
                              <strong>{{ docErrors['file'][0] }}</strong>
                            </span>

                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                            <button type="button" class="btn bg-primary" @click="salvaDocumento">Salva</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /Modal -->
        </div>

      <affitti-unita-condominiale :token="token" :unita_condominiale="unitaCondominiale" v-if="can_view_affitti"></affitti-unita-condominiale>
    </template>

</div>
</template>
<script>
const Uppy = require('@uppy/core');
const Dashboard = require('@uppy/dashboard');
const Italiano = require('@uppy/locales/lib/it_IT');

export default {
  props: ['token', 'id_unita_condominiale','creazione', 'id_condominio', 'role', 'can_view_affitti', 'can_upload', 'id_ruolo'],
  data () {
    return {
      proprietario: null,
      errors: {},
      docErrors: {},
      classi_energetiche: [],
      tableDocumenti: null,
      nomeDocumento: '',
      tipoDocumentoSelezionato: null,
      tipologieDocumentoImmobiliare: null,
      tableLetture: null,
      documenti: null,
      tipologie: null,
      stati: null,
      translations: null,
      unitaCondominiale: null,
      nucleiFamiliari: [],
      utenti: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    $.get('/tipologieDocumentoImmobiliare/json', function (response) {
      this.tipologieDocumentoImmobiliare = response.tipologieDocumentoImmobiliare;
    }.bind(this));
    $.get('/tipologieUnitaCondominiali/json', null, function (response) {
      this.tipologie = response.tipologieUnitaCondominiali;
    }.bind(this));
    $.get('/classiEnergetiche/json', null, function (response) {
      this.classi_energetiche = response.classi_energetiche;
    }.bind(this));
    $.get('/statiUnitaCondominiali/json', null, function (response) {
      this.stati = response.statiUnitaCondominiali;
    }.bind(this));
    if (this.role != 'user') {
      /*$.get('/nucleoFamiliare/lista/safejson', function (response) {
        this.nucleiFamiliari = response.nucleiFamiliari;
        this.updateSelectNucleoFamiliare();
      }.bind(this));*/
      $.ajax({
        url: '/utente/lista/json',
        method: 'get',
        async: false,
        success: function (response) {
          this.utenti = response.utenti;
        }.bind(this),
      });
    } else {
      $.get('/nucleoFamiliare/corrente/json', function (response) {
        this.nucleiFamiliari = [response.nucleo_familiare];
        this.updateSelectNucleoFamiliare();
      }.bind(this));
      $.ajax({
        url: '/unitaCondominiale/'+this.id_unita_condominiale+'/amministratori/json',
        method: 'get',
        async: false,
        success: function (response) {
          this.utenti = response.utenti.filter((x) => x !== null);
        }.bind(this),
      });
    }
    if (this.creazione) {
      this.unitaCondominiale = {
        nome: '',
        interno: '',
        piano: '',
        posizione: '',
        prot: '',
        note: '',
        foglio: '',
        particella: '',
        subalterno: '',
        id_condominio: this.id_condominio,
        id_tipologia_unita_condominiale: null,
        id_stato_unita_condominiale: null,
        deposito_cauzionale: '',
        versato: false,
        vuoto_per_pieno: true,
        garanzia_locativa: true,
        is_affitto_invoice: true,
        is_spese_invoice: true,
        is_double_invoice: true,
      };
    } else {
      $.ajax({
        url: '/unitaCondominiale/'+this.id_unita_condominiale+'/json',
        method: 'get',
        async: false,
        success: function (response) {
          this.unitaCondominiale = response.unitaCondominiale;
        }.bind(this),
      });
    }
  },
  mounted () {
    $.fn.dataTable.moment('DD-MM-YYYY');
    this.tableDocumenti = $(this.$refs.tableDocumenti).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });

    this.tableLetture = $(this.$refs.tableLetture).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row"<"#buttons2.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter2.col-sm-4.col-12"f><"#pagination2.col-sm-4 col-12"p><"#table2.col-12"t><"#length2"l><"#information2"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });

    this.updateSelectProprietario();
    // this.updateSelectCommunityManager();
    // console.log(this.$refs.selectProprietario);

    if (!this.creazione)
      this.updateDocumenti();

    this.uppy = Uppy({
      autoProceed: false,
      locale: Italiano,
      restrictions: {
        allowedFileTypes: ['image/*'],
      }
    }).use(Dashboard, {
      hideUploadButton: true,
      inline: true,
      height: 300,
      target: '#uppy-upload',
      trigger: '#aggiungi-immagine',
    });
    $(this.$refs.modalDocumento).on('hide.bs.modal', function (e) {
      this.modalDocumentoClosed();
    }.bind(this));
  },
  methods: {
    modalDocumentoClosed () {
      this.$refs.fileDocumento.value = "";
      this.nomeDocumento = "";
      this.tipoDocumentoSelezionato = null;
    },
    proprietarioChanged () {
      this.proprietario = this.$refs.selectProprietario.value;
    },
    updateSelectProprietario () {
      var d = [];
      this.admins.forEach(function (utente) {
        var data = {
          id: utente.id,
          text: utente.name ? '' + utente.name + ' ' + utente.cognome : utente.ragione_sociale,
        };
        if (utente.id == this.unitaCondominiale.id_proprietario)
          data['selected'] = true;
        d.push(data);
      }.bind(this));
      $(this.$refs.selectProprietario).select2({
        data: d,
        placeholder: 'Selezionare un proprietario',
      });
      $(this.$refs.selectProprietario).on('select2:select', this.proprietarioChanged);
    },
    updateSelectNucleoFamiliare () {
      var d = [];
      var nucleo = this.unitaCondominiale.nucleo_familiare;
      this.nucleiFamiliari.forEach(function (n) {
        var data = {
          id: n.id,
          text: n.nome,
        };
        if (nucleo && nucleo.id == n.id)
          data['selected'] = true;
        d.push(data);
      }.bind(this));
      $(this.$refs.selectNucleoFamiliare).select2({
        data: d,
        placeholder: 'Selezionare un nucleo familiare',
      });
    },
    updateSelectCommunityManager () {
      var d = [];
      this.cms.forEach(function (u) {
        var dd = {
          id: u.id,
          text: u.name + ' ' + u.cognome,
        };
        if (u.id == this.unitaCondominiale.id_community_manager)
          dd['selected'] = true;
        d.push(dd);
      }.bind(this));
      $(this.$refs.selectCommunityManager).select2({
        data: d,
        placeholder: 'Selezionare un community manager',
      });
    },
    updateDocumenti () {
      $.ajax({
        url: '/unitaCondominiale/'+this.id_unita_condominiale+'/documenti/json',
        async: false,
        method: 'get',
        success: function (response) {
          this.documenti = response.documenti;
        }.bind(this),
      });
      this.tableDocumenti.clear();
      this.documenti.forEach(function (doc) {
        var pathLink = `
        <a href="/documenti/condominio/${doc.id}" target="_blank">Download</a>
        `;
        var azioni = '';
        if (doc.id_tipologia_documento_immobiliare == 1) // catastale
        {
          if ([1,6].includes(this.id_ruolo))
            azioni = `<button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever disable-documento" title="Elimina Documento" data-id="${doc.id}"></button>`;
        }
        else
        {
          if ([1,6,8].includes(this.id_ruolo))
            azioni = `<button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever disable-documento" title="Elimina Documento" data-id="${doc.id}"></button>`;
        }
        this.tableDocumenti.row.add([
          // doc.id,
          doc.tipologia.nome,
          doc.nome,
          // doc.path,
          pathLink,
          moment(String(doc.created_at)).format('DD-MM-YYYY'),
          azioni,
        ]);
      }.bind(this));
      this.tableDocumenti.draw();
      $('button.disable-documento').on('click', function (e) {
        this.disabilita($(e.target).data('id'));
      }.bind(this));
    },
    disabilita (id) {
      if (confirm('Sei sicuro di voler procedere?')) {
        $.post(`/documenti/condominio/${id}/disabilita`, {_token: this.token}, function (response) {
          this.updateDocumenti();
        }.bind(this));
      }
    },
    salva () {
      var data = {
        _token: this.token,
        nome: this.unitaCondominiale.nome,
        interno: this.unitaCondominiale.interno,
        piano: this.unitaCondominiale.piano,
        posizione: this.unitaCondominiale.posizione,
        prot: this.unitaCondominiale.prot,
        note: this.unitaCondominiale.note,
        procedura_hard: this.unitaCondominiale.procedura_hard ? 1 : 0,
        garanzia_locativa: this.unitaCondominiale.garanzia_locativa ? 1 : 0,
        vuoto_per_pieno: this.unitaCondominiale.vuoto_per_pieno ? 1 : 0,
        is_affitto_invoice: this.unitaCondominiale.is_affitto_invoice ? 1 : 0,
        is_spese_invoice: this.unitaCondominiale.is_spese_invoice ? 1 : 0,
        is_double_invoice: this.unitaCondominiale.is_double_invoice ? 1 : 0,
        foglio: this.unitaCondominiale.foglio,
        particella: this.unitaCondominiale.particella,
        subalterno: this.unitaCondominiale.subalterno,
        'id_tipologia_unita_condominiale': this.unitaCondominiale.id_tipologia_unita_condominiale,
        'id_stato_unita_condominiale': this.unitaCondominiale.id_stato_unita_condominiale,
        id_proprietario: this.$refs.selectProprietario.value,
        // id_community_manager: this.$refs.selectCommunityManager.value,
        id_classe_energetica: this.unitaCondominiale.id_classe_energetica,
        tipo_proprieta: this.unitaCondominiale.tipo_proprieta,
        prezzo_vendita: this.unitaCondominiale.prezzo_vendita,
        prezzo_affitto: this.unitaCondominiale.prezzo_affitto,
        prezzo_patto_riscatto: this.unitaCondominiale.prezzo_patto_riscatto,
        penale: this.unitaCondominiale.penale,
        superficie: this.unitaCondominiale.superficie,
        spese_condominiali: this.unitaCondominiale.spese_condominiali,
        numero_locali: this.unitaCondominiale.numero_locali,
        numero_bagni: this.unitaCondominiale.numero_bagni,
        deposito_cauzionale: this.unitaCondominiale.deposito_cauzionale,
        versato: this.unitaCondominiale.versato ? 1 : 0,
      };
      var formData = new FormData();
      Object.keys(data).forEach(function (key) {
        var d = data[key];
        if (d !== null && d !== undefined && d !== '')
          formData.append(key, d);
      });
      var foto = this.uppy.getFiles().map((x) => x.data).filter((x) => x.type.startsWith('image'));
      foto.forEach(function (f, i) {
        formData.append(`foto[${i}]`, f);
      });
      if (this.creazione) {
        data['id_condominio'] = this.id_condominio;
        formData.append('id_condominio', this.id_condominio);
        axios.post('/unitaCondominiale/crea',
          formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
          window.location.href = "/unitaCondominiale/"+response.data.id+'/singolo';
        }.bind(this)).catch(function (error) {
          if (error.response.status == 413) {
            this.errors = {'file': ['File troppo grande.']};
            alert('File troppo grande.');
          }
          else
            this.errors = error.response.data.errors;
        }.bind(this));
      } else {
        axios.post('/unitaCondominiale/'+this.id_unita_condominiale+'/modifica',
          formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
          window.location.reload();
        }.bind(this)).catch(function (error) {
          if (error.response.status == 413) {
            this.errors = {'file': ['File troppo grande.']};
            alert('File troppo grande.');
          }
          else
            this.errors = error.response.data.errors;
        }.bind(this));
      }
    },
    salvaDocumento () {
      var formData = new FormData();
      formData.append('file', this.$refs.fileDocumento.files[0]);
      formData.append('nome', this.nomeDocumento);
      formData.append('tipologia', this.tipoDocumentoSelezionato);
      axios.post('/unitaCondominiale/'+this.id_unita_condominiale+'/uploadDocumento',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function () {
          $(this.$refs.modalDocumento).modal('hide');
          alert('Documento salvato');
          this.updateDocumenti();
          this.docErrors = {};
          // window.location.reload();
        }.bind(this)).catch(function (error) {
          if (error.response.status == 413) {
            this.docErrors = {'file': ['File troppo grande.']};
            alert('File troppo grande.');
          }
          else {
            var errors = error.response.data.errors;
            if (errors !== undefined)
              this.docErrors = errors;
            else {
              this.docErrors = {};
              console.log(error);
            }
          }
        }.bind(this));
    },
    formInputClass (attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
    formInputClassDoc (attr) {
      return { 'form-control': true, 'is-invalid': this.docErrors[attr] !== undefined };
    },
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    admins () {
      return this.utenti.filter(function (u) {
        return u.id_ruolo_utente == 1 || u.id_ruolo_utente == 4 || u.id_ruolo_utente == 7;
      });
    },
    cms () {
      return this.utenti.filter(function (u) {
        return u.id_ruolo_utente == 1 || u.id_ruolo_utente == 3;
      });
    },
    createdAt () {
      if (this.unitaCondominiale.created_at !== null)
        return moment(String(this.unitaCondominiale.created_at)).format('DD-MM-YYYY');
      else
        return 'Data Creazione';
    },
    condominioURL () {
      var condominio = this.unitaCondominiale.condominio;
      if (condominio)
        return `/condominio/${condominio.id}/singolo`;
      return '#';
    },
    nucleoFamiliareURL () {
      var nucleo = this.unitaCondominiale.nucleo_familiare;
      if (nucleo != null)
        return `/nucleoFamiliare/${nucleo.id}/singolo`;
      return '#';
    },
    uppyClass () {
      return { 'col-12': true, 'd-none': this.role == 'user'};
    },
  },
}
</script>
