<template>
  <div class="container">
    <div class="row">
      <label class="col-12 col-sm-4" for="destinatario">Destinatario
        <input id="destinatario" name="destinatario" class="form-control" type="text" :value="pagamento.destinatario.preferred_name" disabled>
      </label>

      <label class="col-12 col-sm-4" for="nucleoFamiliare">Nucleo Familiare
        <input id="nucleoFamiliare" name="nucleoFamiliare" class="form-control" type="text" :value="pagamento.nucleo_familiare.nome" disabled>
      </label>

      <label class="col-12 col-sm-4" for="amministratore">Amministratore
        <input id="amministratore" name="amministratore" class="form-control" type="text" :value="amministratoreNome" disabled>
      </label>

      <label class="col-12 col-sm-4" for="unità_condominiale">Unità Condominiale
        <input id="unità_condominiale" name="unità_condominiale" class="form-control" type="text" :value="unitaCondominialeNome" disabled>
      </label>

      <label class="col-12 col-sm-4" for="data">Data
        <input id="data" name="data" class="form-control" type="text" :value="pagamento.data" disabled>
      </label>

      <label class="col-12 col-sm-4" for="scadenza">Scadenza
        <input id="scadenza" name="scadenza" class="form-control" type="text" :value="pagamento.scadenza" disabled>
      </label>

      <label class="col-12 col-sm-4" for="descrizione">Numero Documento
        <input id="descrizione" name="descrizione" class="form-control" type="text" :value="pagamento.numero_documento" disabled>
      </label>


      <label class="col-12 col-sm-4" for="importo">Importo
        <input id="importo" name="importo" class="form-control" type="text" :value="Number(pagamento.importo).toFixed(2)" disabled>
      </label>

      <label class="col-12 col-sm-4" for="pagato">Pagato
        <input id="pagato" name="pagato" class="form-control" type="text" :value="pagamento.pagato == 1 ? 'Sì' : 'No'" disabled>
      </label>

    <template v-if="pagamento.pagato == 1">
      <label class="col-12 col-sm-4" for="dataPagamento">Data Pagamento
        <input id="dataPagamento" name="dataPagamento" class="form-control" type="text" :value="dataPagamentoComp" disabled>
      </label>
    </template>
    <template v-else-if="role != 'user' && canSetPagato">
      <label class="col-12 col-sm-4" for="dataPagamento">Data Pagamento
        <daterange-picker v-model="dataPagamento"></daterange-picker>
      </label>
    </template>

    <template v-if="role != 'user' && canSetPagato">
      <!--
        -<label for="" class="col-12 col-sm-4" v-if="pagamento.pagato != 1">&nbsp;
        -  <button type="button" class="form-control btn btn-secondary" @click="setPagato()">Segna come pagato</button>
        -</label>
        -<label for="" class="col-12 col-sm-4" v-else>&nbsp;
        -  <button type="button" class="form-control btn btn-secondary" @click="setNonPagato()" >Segna come non pagato</button>
        -</label>
      -->
      <label for="stati" class="col-12 col-sm-4">Stato Pagamento
        <select name="stati" class="form-control" v-model="pagamento.id_stato_pagamento">
          <option v-for="s in stati_pagamento" :key="s.id" :value="s.id">{{ s.nome }}</option>
        </select>
      </label>
      <label for="" class="col-12 col-sm-4">&nbsp;
        <button type="button" class="form-control btn btn-secondary" @click="setStatoPagamento()">Imposta stato</button>
      </label>
    </template>
      <label class="col-12 col-sm-4" for="descrizione">Descrizione
        <textarea id="descrizione" name="descrizione" class="form-control" type="text" :value="pagamento.descrizione" disabled>
        </textarea>
      </label>
    <!--<h3 class="col-12 mt-3">Acconti</h3>-->
    <h3 class="col-12 mt-3">Acconto</h3>
    <!--<label class="col-12 col-sm-3" for="acconto1">Acconto 1-->
    <label class="col-12 col-sm-3" for="acconto1">Acconto
      <input :class="formInputClass('acconto1')" type="number" min="0" step="1" name="acconto1" value="" id="acconto1" v-model="pagamento.acconto1" :disabled="role == 'user'">
      <span class="invalid-feedback" role="alert" v-if="errors['acconto1'] !== undefined">
        <strong>{{ errors['acconto1'][0] }}</strong>
      </span>
    </label>
    <label class="col-12 col-sm-3 d-none" for="acconto2">Acconto 2
      <input :class="formInputClass('acconto2')" type="number" min="0" step="1" name="acconto2" value="" id="acconto2" v-model="pagamento.acconto2" :disabled="role == 'user'">
      <span class="invalid-feedback" role="alert" v-if="errors['acconto2'] !== undefined">
        <strong>{{ errors['acconto2'][0] }}</strong>
      </span>
    </label>
    <label class="col-12 col-sm-3 d-none" for="acconto3">Acconto 3
      <input :class="formInputClass('acconto3')" type="number" min="0" step="1" name="acconto3" value="" id="acconto3" v-model="pagamento.acconto3" :disabled="role == 'user'">
      <span class="invalid-feedback" role="alert" v-if="errors['acconto3'] !== undefined">
        <strong>{{ errors['acconto3'][0] }}</strong>
      </span>
    </label>
    <label class="col-12 col-sm-3 d-none" for="acconto4">Acconto 4
      <input :class="formInputClass('acconto4')" type="number" min="0" step="1" name="acconto4" value="" id="acconto4" v-model="pagamento.acconto4" :disabled="role == 'user'">
      <span class="invalid-feedback" role="alert" v-if="errors['acconto4'] !== undefined">
        <strong>{{ errors['acconto4'][0] }}</strong>
      </span>
    </label>

    <label class="col-12 col-sm-3" for="data_acconto1">Data Acconto 1
      <daterange-picker :class="formInputClass('data_acconto1')" v-model="pagamento.data_acconto1" :disabled="role === 'user'"></daterange-picker>
      <span class="invalid-feedback" role="alert" v-if="errors['data_acconto1'] !== undefined">
        <strong>{{ errors['data_acconto1'][0] }}</strong>
      </span>
    </label>
    <label class="col-12 col-sm-3 d-none" for="data_acconto2">Data Acconto 2
      <daterange-picker :class="formInputClass('data_acconto2')" v-model="pagamento.data_acconto2" :disabled="role === 'user'"></daterange-picker>
      <span class="invalid-feedback" role="alert" v-if="errors['data_acconto2'] !== undefined">
        <strong>{{ errors['data_acconto2'][0] }}</strong>
      </span>
    </label>
    <label class="col-12 col-sm-3 d-none" for="data_acconto3">Data Acconto 3
      <daterange-picker :class="formInputClass('data_acconto3')" v-model="pagamento.data_acconto3" :disabled="role === 'user'"></daterange-picker>
      <span class="invalid-feedback" role="alert" v-if="errors['data_acconto3'] !== undefined">
        <strong>{{ errors['data_acconto3'][0] }}</strong>
      </span>
    </label>
    <label class="col-12 col-sm-3 d-none" for="data_acconto4">Data Acconto 4
      <daterange-picker :class="formInputClass('data_acconto4')" v-model="pagamento.data_acconto4" :disabled="role === 'user'"></daterange-picker>
      <span class="invalid-feedback" role="alert" v-if="errors['data_acconto4'] !== undefined">
        <strong>{{ errors['data_acconto4'][0] }}</strong>
      </span>
    </label>
    <label class="col-12 col-sm-3" for="saveButton">
      <!--<button type="button" class="form-control btn btn-secondary" @click="setAcconti()" v-if="role != 'user'">Salva gli acconti</button>-->
      <button type="button" class="form-control btn btn-secondary" @click="setAcconti()" v-if="role != 'user'">Salva l'acconto</button>
    </label>
    </div>
    <dettaglio-pagato :dettagli="pagamento.dettagli_pagati"></dettaglio-pagato>
  </div>
</template>

<script>
export default {
  props: ['token', 'id_pagamento', 'role', 'id_ruolo'],
  data () {
    return {
      pagamento: {dettagli_pagati: null},
      dataPagamento: null,
      stati_pagamento: [],
      errors: {},
    };
  },
  beforeMount () {
    $.ajax({
      url: `/pagamento/${this.id_pagamento}/json`,
      method: 'get',
      async: false,
      success: function (response) {
        this.pagamento = response.pagamento;
      }.bind(this),
    });
    $.get('/stati_pagamento/json', function (response) {
      this.stati_pagamento = response.stati_pagamento;
    }.bind(this));
  },
  methods: {
    setPagato () {
      var data = {
        _token: this.token,
      };
      if (this.dataPagamento != null)
        data['data'] = this.dataPagamento;
      $.post(`/pagamento/${this.id_pagamento}/setPagato`, data, function () {
        window.location.reload();
      }).fail(function (response) {
        alert('Errore durante l\'impostazione del pagamento');
        window.location.reload();
      });
    },
    setStatoPagamento () {
      var data = {
        _token: this.token,
        id_stato_pagamento: this.pagamento.id_stato_pagamento,
      }
      if (this.dataPagamento != null)
        data['data'] = this.dataPagamento;
      $.post(`/pagamento/${this.id_pagamento}/setStatoPagamento`, data, function () {
        window.location.reload();
      }).fail(function (response) {
        alert('Errore durante l\'impostazione del pagamento');
        window.location.reload();
      });
    },
    setNonPagato () {
      $.post(`/pagamento/${this.id_pagamento}/setNonPagato`, {_token: this.token}, function () {
        window.location.reload();
      }).fail(function (response) {
        alert('Errore durante l\'impostazione del pagamento');
        window.location.reload();
      });
    },
    setAcconti () {
      var data = {
        _token: this.token,
        acconto1: this.pagamento.acconto1,
        acconto2: this.pagamento.acconto2,
        acconto3: this.pagamento.acconto3,
        acconto4: this.pagamento.acconto4,
      };
      for (var i = 1; i <= 4; i++)
        if (this.pagamento['data_acconto'+i] != null)
          data['data_acconto'+i] = this.pagamento['data_acconto'+i];

      $.post(`/pagamento/${this.id_pagamento}/setAcconti`, data, function () {
        window.location.reload();
      }).fail(function (response) {
        this.errors = response.responseJSON.errors;
      }.bind(this));
    },
    formInputClass (attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
  },
  computed: {
    dataPagamentoComp () {
      return moment(String(this.pagamento.data_pagato)).format('DD-MM-YYYY');
    },
    amministratoreNome () {
      return this.pagamento.amministratore ? this.pagamento.amministratore.preferred_name : '';
    },
    unitaCondominialeNome () {
      return this.pagamento.unita_condominiale ? this.pagamento.unita_condominiale.nome : '';
    },
    canSetPagato () {
      return [1,6].includes(this.id_ruolo);
    },
  },
}
</script>
