<template>
  <div class="col-4">

    <label for="proprietario">Proprietario</label>
    <!--<input id="proprietario" name="proprietario" class="form-control" type="text" value="" placeholder="Proprietario">-->
    <select id="proprietario" name="proprietario" class="form-control" type="text" value="" placeholder="Proprietario" @click="selectClick($event)">
    </select>
    <input type="hidden" v-bind:value="value" v-on:input="$emit('input', $event.target.value)">

    <!-- Modal -->
    <div class="modal fade" role="dialog" ref="modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ titolo }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
            <button type="button" class="btn btn-primary">Conferma</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['lista', 'titolo'],
  /*
  props: {
    'lista': {
      type: 'Array',
      required: true,
    },
    'titolo': {
      type: 'String',
      required: true,
    },
  },
  */
  data () {
    return {
    };
  },
  methods: {
    selectClick (e) {
      e.preventDefault();
      $(this.$refs.modal).modal('show');
      console.log(e);
    },
  },
}
</script>
