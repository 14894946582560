<template>
  <div class="container">
    <div class="row">
      <h1 class="m-0 text-dark my-3">Documenti Unita Condominiale</h1>
    </div>

    <!-- Datatable documenti condomini -->
    <table class="table table-striped dataTable" id="dataTable1" ref="tableDocumenti">
      <thead>
        <tr>
          <th>Id</th>
          <th>Nome</th>
          <th>Condominio</th>
          <th>Download</th>
          <th>Tipologia</th>
          <th>Data Caricamento</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <!-- /Datatable documenti condomini -->
  </div>
</template>
<script>
export default {
  props: ['token'],
  data () {
    return {
      tableDocumenti: null,
      documenti: null,
      translations: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    $.ajax({
      url: '/documento/lista/unitaCondominiale/json',
      method: 'get',
      async: false,
      success: function (response) {
        this.documenti = response.documenti;
      }.bind(this),
    });
  },
  mounted () {
    $.fn.dataTable.moment('DD-MM-YYYY');
    this.tableDocumenti = $(this.$refs.tableDocumenti).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.updateTableDocumenti();
  },
  methods: {
    updateTableDocumenti () {
      this.tableDocumenti.clear();
      this.documenti.forEach(function (struct) {
        var doc = struct.documento;
        var unita = struct.unita_condominiale;
        var linkDownload = `
        <a href="/documenti/condominio/${doc.id}" target="_blank">Download</a>
        `;
        var linkCondominio = `
        <a href="/unitaCondominiale/${unita.id}/singolo" target="_blank">${unita.nome}</a>
        `;
        this.tableDocumenti.row.add([
          doc.id,
          doc.nome,
          linkCondominio,
          linkDownload,
          doc.tipologia.nome,
          moment(String(doc.created_at)).format('DD-MM-YYYY'),
        ]);
      }.bind(this));
      this.tableDocumenti.draw();
    },
    __(word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
}
</script>
