<template>
  <div class="container">
    <h1 class="m-0 mb-3">{{ __('translation.lista_condomini') }}</h1>

    <div class="row">
      <div class="col-md-4 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-domain"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Condomini Gestiti:</span>
            <span class="info-box-number">{{ condomini.length }}</span>
            <!--
              -<div class="progress">
              -  <div class="progress-bar" style="width: 70%"></div>
              -</div>
              -<span class="progress-description">
              -  New Users in last month: {{ $count }}
              -</span>
              -->
          </div>
        </div>
      </div>
      <div class="col-md-4 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-home-group"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Unità Condominiali</span>
            <span class="info-box-number">{{ unitaCondominialiLength }}</span>
            <!--
              -<div class="progress">
              -  <div class="progress-bar" style="width: 30%"></div>
              -</div>
              -<span class="progress-description">
              -  30% of Total
              -</span>
              -->
          </div>
        </div>
      </div>
      <div class="col-md-4 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-home-variant-outline"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Unità Condominiali Libere</span>
            <span class="info-box-number">{{ unitaCondominialiLibereLength }}</span>
            <!--
              -<div class="progress">
              -  <div class="progress-bar" style="width:50%"></div>
              -</div>
              -<span class="progress-description">
              -  Deleted Users in last month: {{ $count }}
              -</span>
              -->
          </div>
        </div>
      </div>
    </div>
    <table class="table table-striped dataTable" id="dataTable" ref="dataTable">
      <thead>
        <tr>
          <th>Nome</th>
          <th>Descrizione</th>
          <th>Indirizzo</th>
          <th>Amministratore</th>
          <th>Numero Unità Condominiali</th>
          <th>Creato il</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <div class="row" v-if="canCreate">
        <div class="offset-sm-9 col-12 col-sm-3 mt-2">
            <a href="/condominio/crea" class="btn bg-primary w-100">Aggiungi nuovo condominio</a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id_ruolo_utente', 'token'],
  data () {
    return {
      dataTable: null,
      condomini: null,
      translations: null,
      info: {
        totale: 1,
        da_affittare: 0,
      },
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    $.ajax({
      method: 'get',
      url: '/condominio/lista/json',
      async: false,
      success: function (response) {
        this.condomini = response.condomini;
      }.bind(this)
    });
    $.get('/unitaCondominiale/info/json', function (response) {
      this.info = response.info;
    }.bind(this));
  },
  mounted () {
    // $.fn.dataTable.moment('DD-MM-YYYY');
    this.dataTable = $(this.$refs.dataTable).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.dataTable.on('draw', function () {
      this.updateOnClick();
    }.bind(this));
    this.updateCondomini();
  },
  methods: {
    updateCondomini () {
      this.condomini.forEach(function (condominio) {
        var condominioLink = `
          <a href="/condominio/${condominio.id}/singolo" target="_blank">${condominio.nome}</a>
        `;
        var azioni = '';
        if (this.canDisable)
          azioni = `<button class="btn btn-secondary px-1 py-0 mdi mdi-domain-off disable-condominio" data-id="${condominio.id}"></button>`;
        this.dataTable.row.add([
          condominioLink,
          condominio.descrizione,
          condominio.indirizzo.indirizzo,
          condominio.amministratore.name,
          condominio.n_unita_condominiali,
          moment(String(condominio.created_at)).format('DD-MM-YYYY'),
          azioni,
        ]);
      }.bind(this));
      this.dataTable.draw();
      // this.updateOnClick();
    },
    updateOnClick () {
      $('button.disable-condominio').off('click');
      $('button.disable-condominio').on('click', function (e) {
        this.disable($(e.target).data('id'));
      }.bind(this));
    },
    disable (id) {
      if (confirm('Sei sicuro?')) {
        $.post(`/condominio/${id}/disable`, {_token: this.token}, function () {
          window.location.reload();
        }.bind(this));
      }
    },
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    unitaCondominialiLength () {
      var tot = 0;
      this.condomini.forEach(function (c) {
        tot += c.unita_condominiali.length;
      });
      return tot;
    },
    unitaCondominialiLibereLength () {
      return this.info.da_affittare;
    },
    canCreate () {
      return [1,6].includes(this.id_ruolo_utente);
    },
    canDisable () {
      return [1,6].includes(this.id_ruolo_utente);
    },
  },
}
</script>
