<template>
  <div>
    <h3 class="col-12 mt-3">Dettaglio Pagato</h3>
    <table class="table table-striped" ref="tableDettagli">
      <thead>
        <tr>
          <th>Importo</th>
          <th>Data</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="dettaglio in dettagli" :key="dettaglio.id">
          <td>{{ parseFloat(dettaglio.importo).toFixed(2) }}</td>
          <td>{{ formatData(dettaglio.data) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['dettagli'],
  data () {
    return {
      tableDettagli: null,
    };
  },
  mounted () {
  },
  methods: {
    formatData (data) {
      return moment(String(data)).format('DD-MM-YYYY');
    },
  },
}
</script>
