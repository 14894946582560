<template>
<div class="container">
  <h1 class="m-0 text-dark mb-3">Crea Inserzione per: {{ unitaCondominiale.nome }}</h1>

    <div class="row">
        <!--
          -<div class="col-3 img-container">
          -<img src="http://placehold.it/300x300/BBB/fff?text=Slideshow" class="w-100" alt="Immagine Ticket">
          -</div>
        -->
        <div class="col-12 col-sm-3">
          <div class="main-carousel" v-if="unitaCondominiale.foto.length > 0">
            <img v-for="foto in unitaCondominiale.foto" :key="foto.id" :alt="foto.nome" :data-flickity-lazyload="'/foto/'+foto.id" class="carousel-image">
          </div>
          <div v-else>
            <img src="/img/img_missing.jpg" class="thumbnail w-100">
          </div>
        </div>

        <div class="col.12 col-sm-9">
            <form action="" class="row">
                <div class="col-12 col-sm-8">
                    <label for="titolo">Titolo</label>
                    <input id="titolo" name="titolo" class="form-control" :class="formInputClass('titolo')" type="text" value="" placeholder="Titolo"  v-model="titolo" >
                    <span class="invalid-feedback" role="alert" v-if="errors['titolo'] !== undefined">
                      <strong>{{ errors['titolo'][0] }}</strong>
                    </span>
                </div>
                <div class="col-12 col-sm-6">
                  <label for="prezzo">Prezzo</label>
                  <input id="prezzo" name="prezzo" class="form-control" :class="formInputClass('prezzo')" type="number" value="" placeholder="Prezzo"  v-model="prezzo" >
                    <span class="invalid-feedback" role="alert" v-if="errors['prezzo'] !== undefined">
                      <strong>{{ errors['prezzo'][0] }}</strong>
                    </span>
                </div>
                <div class="col-12 col-sm-6">
                  <label for="tipologia">Tipologia</label>
                  <select id="tipologia" name="tipologia" class="form-control" :class="formInputClass('id_tipologia_annuncio')" type="number" value="" placeholder="Tipologia"  v-model="tipologia" >
                    <option v-for="t in tipologie" :key="t.id" :value="t">{{ t.nome }}</option>
                  </select>
                  <span class="invalid-feedback" role="alert" v-if="errors['id_tipologia_annuncio'] !== undefined">
                    <strong>{{ errors['id_tipologia_annuncio'][0] }}</strong>
                  </span>
                </div>
                <div class="col-12 col-sm-12">
                    <label for="descrizione">Descrizione</label>
                    <textarea name="descrizione" :class="formInputClass('descrizione')" v-model="descrizione" cols="30" rows="10"></textarea>
                    <!--<input :class="formInputClass('descrizione')" type="text" value="" placeholder="Descrizione" v-model="descrizione">-->
                    <span class="invalid-feedback" role="alert" v-if="errors['descrizione'] !== undefined">
                        <strong>{{ errors['descrizione'][0] }}</strong>
                    </span>
                </div>
                <div class="col-12 col-sm-12 row mt-2">
                    <div class="offset-sm-9 col-12 col-sm-3 mt-2">
                        <button type="button" class="btn bg-primary w-100" @click="crea">Crea Inserzione</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>

export default {
  props: ['token', 'id_unita_condominiale'],
  data () {
    return {
      unitaCondominiale: null,
      titolo: '',
      descrizione: '',
      prezzo: 0,
      errors: {},
      tipologia: null,
      tipologie: null,
      translations: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    $.get('/annuncio/tipologie/json', function (response) {
      this.tipologie = response.tipologie;
      this.tipologia = this.tipologie[0];
    }.bind(this));
    $.ajax({
      url: '/unitaCondominiale/'+this.id_unita_condominiale+'/json',
      method: 'get',
      async: false,
      success: function (response) {
        this.unitaCondominiale = response.unitaCondominiale;
      }.bind(this),
    });
  },
  methods: {
    /*
    crea () {
      var data = {
        titolo: this.titolo,
        descrizione: this.descrizione,
        prezzo: this.prezzo,
        prezzo_visibile: 1,
        id_tipologia_annuncio: this.tipologia.id,
        _token: this.token,
      };
      $.post(`/unitaCondominiale/${this.id_unita_condominiale}/creaAnnuncio`, data, function (response) {
        alert('Annuncio creato');
        window.location.href = `/annuncio/${response.annuncio.id}/singolo`;
      }).fail(function (response) {
        this.errors = response.responseJSON.errors;
      }.bind(this));
    },
    */
    crea () {
      var formData = new FormData();
      formData.append('titolo', this.titolo);
      formData.append('descrizione', this.descrizione);
      formData.append('prezzo', this.prezzo);
      formData.append('prezzo_visibile', 1);
      formData.append('id_tipologia_annuncio', this.tipologia.id);
      formData.append('_token', this.token);
      axios.post(`/unitaCondominiale/${this.id_unita_condominiale}/creaAnnuncio`,
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      }).then(function (response) {
        alert('Annuncio creato');
        window.location.href = `/annuncio/${response.data.annuncio.id}/singolo`;
      }.bind(this)).catch(function (error) {
        if (error.response.status == 413)
          this.errors = {'file': ['File troppo grande.']};
        else
          this.errors = error.response.data.errors;
      }.bind(this));
    },
    formInputClass (attr) {
      return { 'form-control': true, 'is-invalid': this.errors[attr] !== undefined };
    },
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  watch: {
    tipologia () {
      switch (this.tipologia.id) {
        case 1: // Vendita
          if (this.unitaCondominiale.prezzo_vendita !== null)
            this.prezzo = this.unitaCondominiale.prezzo_vendita;
          break;
        case 2: // Affitto
          if (this.unitaCondominiale.prezzo_affitto !== null)
            this.prezzo = this.unitaCondominiale.prezzo_affitto;
          break;
        case 3: // Patto e Riscatto
          if (this.unitaCondominiale.prezzo_patto_riscatto !== null)
            this.prezzo = this.unitaCondominiale.prezzo_patto_riscatto;
          break;
      }
    },
  },
}
</script>
