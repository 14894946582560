<template>
  <div class="container">
    <div class="row">
      <h1 class="col-12">Import Anagrafica Alyante</h1>
      <div class="col-12 col-sm-3">
        <label for="file">Seleziona un file</label>
        <input type="file" ref="fileImport" >
      </div>
      <div class="col-12 col-sm-3">
        <label for="importbutton">Conferma importazione</label>
        <button class="form-control btn btn-primary" @click="importa()">Importa</button>
      </div>
      <div class="col-12 col-sm-2">
        <label for="send">Invia al server</label>
        <button type="button" class="form-control btn btn-primary" @click="invia()">Invia</button>
      </div>
      <div class="col-12 col-sm-2">
        <label for="">Intestazione</label>
        <input class="form-control" type="number" v-model="colIntestazione">
      </div>
      <div class="col-12 col-sm-2">
        <label for="">Inizio Dati</label>
        <input class="form-control" type="number" v-model="colInizioDati">
      </div>

      <div class="col-12">
        <table class="table table-striped dataTable mt-2">
          <thead>
            <tr>
              <!--<th v-for="int in intestazioni" :key="int.id">{{ int.text}}</th>-->
              <th v-for="int, j in contenutiCols" :key="j"><select v-model="selectsIntestazione[j]">
                  <option :value="0"></option>
                  <option v-for="att, i in attributi" :value="i+1">{{ att }}</option>
              </select></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cont in contenuti" :key="cont.id">
              <td v-for="col, i in cont.cols" :key="i">{{col}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['token'],
  data () {
    var attributi = [
      "Nome",
      "Cognome",
      "Ragione Sociale",
      "E-mail",
      "Codice Fiscale",
      "Partita Iva",
      "Telefono 1",
      "Cell.",
      "Indirizzo",
      "Cap",
      "Citta",
      "Prov.",
      "Note",
    ];

    return {
      privFileData: null,
      attributi: attributi,
      colIntestazione: 3,
      colInizioDati: 4,
      selectsIntestazione: [],
    };
  },
  mounted () {
  },
  methods: {
    importa () {
      var file = $(this.$refs.fileImport)[0].files[0];
      this.fr = new FileReader();
      this.fr.onload = this.fileLoaded;
      this.fr.readAsBinaryString(file);
    },
    fileLoaded () {
      var table = XLSX.read(this.fr.result, {type:'binary'});
      var sheet = table.Sheets[table.SheetNames[0]];
      this.privFileData = XLSX.utils.sheet_to_json(sheet, {header:1});
      console.log('File importato');
      this.refreshIntestazione();
    },
    refreshIntestazione () {
      this.$nextTick(function () {
        this.selectsIntestazione = [];
        this.intestazioni.forEach(function (con) {
          var filt = Object.keys(this.attributi).filter(function (x) {
            var comp = this.attributi[x].localeCompare(con.text, 'it', {sensitivity: 'base'}) == 0; // Ignora maiuscolo e accenti
            return comp;
          }.bind(this));
          if (filt.length > 0)
            this.selectsIntestazione.push(parseInt(filt[0])+1);
          else
            this.selectsIntestazione.push(0);
        }.bind(this));
      }.bind(this));
    },
    invia () {
      if (this.contenuti.lenght == 0) {
        alert('Non ci sono dati');
        return;
      }

      var dati = [];
      this.contenuti.forEach(function (con) {
        var data = {};
        con.cols.forEach(function (content, i) {
          var s = this.selectsIntestazione[i];
          if (s == 0)
            return;
          data[this.attributi[s-1]] = content;
        }.bind(this));
        dati.push(data);
      }.bind(this));
      // console.log(dati);
      $.post('/import/alyante', {
        _token: this.token,
        utenti: JSON.stringify(dati),
      }, function (response) {
        alert('ok');
        window.location.href = "/utente/lista";
      }).fail(function (response) {
        alert('errore');
        console.log(response.responseJSON);
      });
    },
  },
  watch: {
    colIntestazione () {
      this.refreshIntestazione();
    },
  },
  computed: {
    intestazioni () {
      if (this.privFileData == null)
        return [];
      var ints = this.privFileData[this.colIntestazione];
      var res = [];
      ints.forEach(function (content, i) {
        res.push({
          id: i,
          text: content,
        });
      });
      return res;
    },
    contenuti () {
      if (this.privFileData == null)
        return [];
      var data = this.privFileData.slice(this.colInizioDati);
      var res = [];
      data.forEach(function (content, i) {
        if (content.length > 0)
          res.push({
            id: i,
            cols: content,
          });
      });
      return res;
    },
    contenutiCols () {
      if (this.contenuti.length > 0)
        return this.contenuti[0]['cols'];
      return [];
    },
  },
}
</script>
