<template>
  <div>
    <!--
      Avvisi di pagamento
      Quanti 30, 60, 90
      Le righe sono per pagamento
      Mostro il numero di avvisi e le date dei vari
      Mostro conduttore
      Mostro importo
    -->
    <modale-caricamento ref="modaleCaricamento"></modale-caricamento>

    <table class="table dataTable" id="dataTable" ref="tableAvvisi">
      <thead>
        <tr>
          <th>Pagamento</th>
          <th>Conduttore</th>
          <th>Importo</th>
          <th>Numero avvisi inviati</th>
          <th>Data primo avviso</th>
          <th>Data secondo avviso</th>
          <th>Data terzo avviso</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>

  </div>
</template>
<script>
export default {
  data () {
    return {
      tableAvvisi: null
    };
  },
  mounted () {
    // $.fn.dataTable.moment('DD-MM-YYYY');
    this.tableAvvisi = $(this.$refs.tableAvvisi).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        // dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        // buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });

    this.updateAvvisi();
  },
  methods: {
    updateAvvisi () {
      this.$refs.modaleCaricamento.apri();
      $.get('/avvisi/json', function (response) {
        this.avvisi = response.avvisi;
        this.updateTableAvvisi();
        this.$refs.modaleCaricamento.chiudi();
      }.bind(this));
    },
    updateTableAvvisi () {
      this.tableAvvisi.clear();
      this.avvisi.forEach(function (avviso) {
        var linkPagamento = `
        <a href="/pagamento/${avviso.pagamento.id}/singolo" target="_blank">${avviso.pagamento.descrizione}</a>
        `;
        var linkConduttore = `
        <a href="/utente/${avviso.pagamento.id_utente_destinatario}/singolo" target="_blank">${avviso.pagamento.destinatario.preferred_name}</a>
        `;
        var data1 = avviso.date[0] === undefined ? '' : moment(avviso.date[0]).format('DD-MM-YYYY');
        var data2 = avviso.date[1] === undefined ? '' : moment(avviso.date[1]).format('DD-MM-YYYY');
        var data3 = avviso.date[2] === undefined ? '' : moment(avviso.date[2]).format('DD-MM-YYYY');
        this.tableAvvisi.row.add([
          linkPagamento,
          linkConduttore,
          Number(avviso.pagamento.importo).toFixed(2)+"€",
          avviso.numero_avvisi,
          data1,
          data2,
          data3,
        ]);
        /*
          <th>Pagamento</th>
          <th>Conduttore</th>
          <th>Importo</th>
          <th>Numero avvisi inviati</th>
          <th>Data primo avviso</th>
          <th>Data secondo avviso</th>
          <th>Data terzo avviso</th>
        */
      }.bind(this));
      this.tableAvvisi.draw();
    },
  },
}
</script>
