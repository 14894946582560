<template>
  <div class="container">
    <modale-caricamento ref="modaleCaricamento"></modale-caricamento>
    <template v-if="id_condominio != null">
      <template v-if="filter_unita == null">
        <h1 class="m-0 text-dark mb-3">Pagamenti Spese di Condominio<span class="placeholder">- {{ condominio.nome }}</span></h1>
        <h4>
          <!--<span class="placeholder">Indirizzo condominio, civici, cap, città (PROV)</span>-->
          <span class="placeholder">{{ (condominio.indirizzo || {summary: ''}).summary }}</span>
        </h4>
      </template>
      <template v-else>
        <h1 class="m-0 text-dark mb-3">Rate Unita Condominiale<span class="placeholder">- {{ condominio.nome }} - {{ unita.nome }}</span></h1>
        <h4>
          <!--<span class="placeholder">Indirizzo condominio, civici, cap, città (PROV)</span>-->
          <!--<span class="placeholder">{{ condominio.indirizzo.summary }}</span>-->
          <span class="placeholder">{{ (condominio.indirizzo || {summary: ''}).summary }}</span>
        </h4>
      </template>
    </template>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-home"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Spese condominiali pagate</span>
            <!--<span class="info-box-number">0</span>-->
            <span class="info-box-number">{{ (totale_pagato).toFixed(2) }}€</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-home-variant-outline"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Spese condominiali non pagate</span>
            <!--<span class="info-box-number">0</span>-->
            <span class="info-box-number">{{ (totale - totale_pagato).toFixed(2) }}€</span>
          </div>
        </div>
      </div>
    </div>

    <form action="" class="mb-4">
      <div class="row">
        <div class="col-12 col-sm-4">
          <label for="anno">Anno</label>
          <select name="anno" v-model="anno" class="form-control">
            <option v-for="a in anni" :key="a" :value="a">{{ a }}</option>
          </select>
        </div>
        <!--
          -<div class="col-12 col-sm-4">
          -  <label for="inizioPeriodo">Inizio Periodo</label>
          -  <daterange-picker class="form-control" v-model="dataInizioPeriodo"></daterange-picker>
          -  [><input id="inizioPeriodo" name="inizioPeriodo"  type="date" value="DEFAULT 12 MESI PRIMA" placeholder="InizioPeriodo"  class="form-control"><]
          -</div>
          -<div class="col-12 col-sm-4">
          -  <label for="finePeriodo">Fine Periodo</label>
          -  <daterange-picker class="form-control" v-model="dataFinePeriodo"></daterange-picker>
          -  [><input id="finePeriodo" name="finePeriodo"  type="date" value="DEFAULT NOW" placeholder="finePeriodo"  class="form-control"><]
          -</div>
          -->
      </div>
    </form>
    <hr>

    <!-- /Datatable unità condominiali -->
    <table class="table dataTable" id="dataTable" ref="tablePagamenti">
      <thead>
        <tr>
          <th>Nucleo</th>
          <th>Condominio</th>
          <th>Unità</th>

          <th v-for="d,i in date" :key="i">Rata del <br class="d-none d-sm-block">{{ d }}</th>

          <th>Surplus versamenti</th>
          <th>Totale quote annue</th>
          <th>Totale versamenti</th>
          <th>Totale da versare</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Totale</th>
            <th v-for="d,i in date" :key="i">Totale del <br class="d-none d-sm-block">{{ d }}</th>
            <th>Totale surplus versamenti</th>
            <th>Totale quote annue</th>
            <th>Totale versamenti</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ totale.toFixed(2) }}€</td>
            <th v-for="d,i in totaleDate" :key="i">{{ d.toFixed(2) }}€</th>
            <td>{{ totale_surplus.toFixed(2) }}€</td>
            <td>{{ totale.toFixed(2) }}€</td>
            <td>{{ totale_pagato.toFixed(2) }}€</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Datatable unità condominiali -->
    <!--
      -<hr class="mt-4">
      -<h6 class="mt-4">
      -  <span class="placeholder">I versamenti delle rate dovranno essere eseguiti su: BCC Fano -  Banca di Credico Cooperativo di Fano (Sede Di Via Flaminia, 346), IBAN: IT91 P 08519 24309 000160101303</span>
      -</h6>
      -->
  </div>
</template>

<script>
export default {
  props: ['id_condominio', 'filter_unita', 'role'],
  data () {
    var date = [];
    var totaleDate = [];
    for (var i = 0; i < 12; i++)
    {
      date.push('');
      totaleDate.push(0);
    }
    var anni = [];
    for (var i = moment().year()+2; i >= 2000; i--)
      anni.push(i);
    return {
      anni: anni,
      anno: anni[2],
      dataInizioPeriodo: moment().dayOfYear(1).format('Y-M-D'),
      dataFinePeriodo: moment().dayOfYear(365).date(1).format('Y-M-D'),
      condominio: {},
      pagamenti: [],
      date: date,
      unita: {},
      tablePagamenti: null,
      totale: 0,
      totaleDate: totaleDate,
      totale_pagato: 0,
      totale_surplus: 0,
    };
  },
  beforeMount () {
    if (this.id_condominio) {
      $.ajax({
        url: '/condominio/'+this.id_condominio+'/json',
        method: 'get',
        async: false,
        success: function (response) {
          this.condominio = response.condominio;
        }.bind(this)
      });
    }
    if (this.filter_unita != null) {
      $.ajax({
        url: '/unitaCondominiale/'+this.filter_unita+'/json',
        method: 'get',
        async: false,
        success: function (response) {
          this.unita = response.unitaCondominiale;
        }.bind(this)
      });
    }
  },
  mounted () {
    this.tablePagamenti = $(this.$refs.tablePagamenti).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row"<"#buttons.col-lg-4 mt-2 col-md-6 col-12 mt-sm-0"B><"#filter.col-lg-4 col-md-6 col-12 pr-sm-4"f><"#pagination.col-lg-4 col-md-12 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.tablePagamenti.on('search.dt', function () {
      this.updateTotali();
    }.bind(this));
    this.loadRateCondomini();
  },
  methods: {
    loadRateCondomini () {
      var data = {
        //dataInizio: this.dataInizioPeriodo,
        //dataFine: this.dataFinePeriodo,
        anno: this.anno,
      };
      this.$refs.modaleCaricamento.apri();
      if (this.role == 'user' && false) {
        $.get(`/unitaCondominiale/${this.filter_unita}/rate/json`, data, function (response) {
          this.pagamenti = response.pagamenti;
          this.updateTablePagamenti();
        }.bind(this)).always(function () {
          this.$refs.modaleCaricamento.chiudi();
          setTimeout(function () {
            this.$refs.modaleCaricamento.chiudi();
            console.log('chiudi');
          }.bind(this), 1000);
        }.bind(this));
      } else {
        if (this.id_condominio != null)
          var url = `/condominio/${this.id_condominio}/rate/json`;
        else
          var url = `/condominio/rate/tutti/json`;
        $.get(url, data, function (response) {
          this.pagamenti = response.pagamenti;
          this.updateTablePagamenti();
        }.bind(this)).always(function () {
          this.$refs.modaleCaricamento.chiudi();
          setTimeout(function () {
            this.$refs.modaleCaricamento.chiudi();
            console.log('chiudi');
          }.bind(this), 1000);
        }.bind(this));
      }
    },
    updateTablePagamenti () {
      this.tablePagamenti.clear();
      var newDate = this.pagamenti.date.slice(0,12);
      while (newDate.length < 12)
        newDate.push('');
      this.date = [];
      newDate.forEach(function (d) {
        if (d)
          this.date.push(moment(String(d)).format('DD-MM-YYYY'));
        else
          this.date.push("");
      }.bind(this));
      // this.date = newDate;
      this.pagamenti.nuclei.forEach(function (pagamento) {
        if(pagamento.nucleo != null && pagamento.unita_condominiale != null)
        {
          // Prima guardo se devo mostrare solo questa unita
          if (this.filter_unita != null) {
            if (pagamento.unita_condominiale.id != this.filter_unita)
              return;
          }

          var linkNucleo = `
          <a href="/nucleoFamiliare/${pagamento.nucleo.id}/singolo" target="_blank">${pagamento.nucleo.nome}</a>
          `;
          var linkCondominio = `
          <a href="/condominio/${pagamento.unita_condominiale.condominio.id}/singolo" target="_blank">${pagamento.unita_condominiale.condominio.nome}</a>
          `;
          var linkUnita = `
          <a href="/unitaCondominiale/${pagamento.unita_condominiale.id}/singolo" target="_blank">${pagamento.unita_condominiale.nome}</a>
          `;
          var row = [
            linkNucleo,
            linkCondominio,
            linkUnita,
          ];

          var count = 0;
          Object.keys(pagamento.rate).forEach(function (key) {

            //al primo giro guardo se il la data della prima rata è relativa al mese di gennaio. Se non lo è inserisco celle vuote fino al mese della prima rata
            if(count == 0){
              if(pagamento.rate[key].data != null && pagamento.rate[key].data != "")
              {
                var firstMnonth = parseInt(pagamento.rate[key].data.split('-')[1]);
                if(firstMnonth != 1)
                {
                    for (var i = 1; i < firstMnonth; i++)
                    {
                      row.push('');
                      count++;
                    }
                }
              }
            }

            var icon = '';
            var icon = pagamento.rate[key].pagato ? 'mdi-check' : 'mdi-window-close';
            if (pagamento.rate[key].pagato)
              icon = 'mdi-check';
            else {
              if (pagamento.rate[key].acconto1 > 0)
                  icon = 'mdi-minus';
              else
                  icon = 'mdi-window-close';
            }

            var sym= `<b class="mdi ${icon}"></b>`;
            // var sym = pagamento.rate[key].pagato ? '<b class="mdi mdi-check"></b>' : '<b class="mdi mdi-window-close"></b>';
            if (this.role == 'user')
              // var link = `${Number(pagamento.rate[key].importo).toFixed(2)} ${sym}`;
              var link = `<a href="#" target="_blank">${Number(pagamento.rate[key].importo).toFixed(2)}€ ${sym}</a>`;
            else
              var link = `<a href="/pagamento/${pagamento.rate[key].id}/singolo" target="_blank">${Number(pagamento.rate[key].importo).toFixed(2)}€ ${sym}</a>`;
            // row.push(Number(pagamento.rate[key].importo).toFixed(2));
            row.push(link);
            count++;
          }.bind(this));
          for (var i = 0; i < 12-count; i++)
            row.push('');
          row.push(Number(pagamento.surplus).toFixed(2)+"€");
          row.push(Number(pagamento.totale).toFixed(2)+"€");
          row.push(Number(pagamento.totale_pagato).toFixed(2)+"€");
          row.push(Number(pagamento.totale-pagamento.totale_pagato).toFixed(2)+"€");
          this.tablePagamenti.row.add(row);
        }
      }.bind(this));
      this.tablePagamenti.draw();
    },
    updateTotali () {
      this.totale = 0;
      this.totale_pagato = 0;
      this.totale_surplus = 0;
      for (var i = 0; i < 12; i++)
      {
        this.totaleDate[i] = 0;
      }
      var rows = this.tablePagamenti.rows({filter:'applied'}).data();
      rows.each(function (row) {
        for (var i = 3; i <= 14; i++)
        {
          var content = $(row[i]);
          if (content.text() != "")
          {
            var amount = parseFloat(content.text().substring(0, content.text().length-1));
            this.totaleDate[i-3] += amount;
          }
        }
        this.totale += parseFloat(row[16].substring(0, row[16].length-1));
        this.totale_pagato += parseFloat(row[17].substring(0, row[17].length-1));
        this.totale_surplus += parseFloat(row[15].substring(0, row[15].length-1));
      }.bind(this));
    },
  },
  watch: {
    anno () {
      this.loadRateCondomini();
    },
  },
}
</script>
