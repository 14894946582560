<template>
  <div class="w-100">
    <div class="row">
      <hr class="mt-4">
      <h4 class="mt-4">{{ titolo }}</h4>
      <!-- Datatable documenti -->
      <table class="table table-striped dataTable" ref="tableDocumenti">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Tipologia</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table>
      <!-- /Datatable documenti -->
    </div>

    <div class="row">
      <!-- Button trigger modal -->
      <div class="offset-sm-9 col-12 col-sm-3 mt-2">
        <button class="btn bg-primary w-100" data-toggle="modal" data-target="#carica_documento">Carica nuovo documento</button>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="carica_documento" role="dialog" aria-labelledby="carica_documento" aria-hidden="true" ref="modalDocumento">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Carica nuovo documento</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form action="">
                <label for="nome_documento">Nome Documento</label>
                <input id="nome_documento" name="nome_documento" :class="formInputClassDoc('nome')" type="text" placeholder="Nome Documento" v-model="nomeDocumento" required>
                <span class="invalid-feedback" role="alert" v-if="docErrors['nome'] !== undefined">
                  <strong>{{ docErrors['nome'][0] }}</strong>
                </span>

                <label for="tipo_documento" class="mt-4">Tipo Documento</label>
                <select id="tipo_documento" name="tipo_documento" :class="formInputClassDoc('tipologia')" v-model="tipoDocumentoSelezionato">
                  <option v-for="tipo in tipologieDocumentoImmobiliare" :key="tipo.id" :value="tipo.id">{{ tipo.nome }}</option>
                </select>
                <span class="invalid-feedback" role="alert" v-if="docErrors['tipologia'] !== undefined">
                  <strong>{{ docErrors['tipologia'][0] }}</strong>
                </span>

                <label for="file_documento" class="mt-4">Carica File</label>
                <input id="file_documento" name="file_documento" :class="formInputClassDoc('file')" type="file" ref="fileDocumento" required>
                <span class="invalid-feedback" role="alert" v-if="docErrors['file'] !== undefined">
                  <strong>{{ docErrors['file'][0] }}</strong>
                </span>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
              <button type="button" class="btn bg-primary" @click="salvaDocumento">Salva</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: ['title'],
  data () {
    return {
      nomeDocumento: '',
      tipoDocumentoSelezionato: null,
      docErrors: {},
      translations: null,
      files: [],
      tableDocumenti: null,
      tipologieDocumentoImmobiliare: [],
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    $.get('/tipologieDocumentoImmobiliare/json', function (response) {
      this.tipologieDocumentoImmobiliare = response.tipologieDocumentoImmobiliare;
    }.bind(this));
  },
  mounted () {
    this.tableDocumenti = $(this.$refs.tableDocumenti).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row"<"#buttons2.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter2.col-sm-4.col-12"f><"#pagination2.col-sm-4 col-12"p><"#table2.col-12"t><"#length2"l><"#information2"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    $(this.$refs.modalDocumento).on('hide.bs.modal', function (e) {
      this.modalDocumentoClosed();
    }.bind(this));
  },
  methods: {
    salvaDocumento () {
      var file = this.$refs.fileDocumento.files[0];
      var type = '';
      if (file)
        type = file.type;
      var ers = {};
      if (type !== 'application/pdf')
        ers['file'] = ['Il formato non è pdf'];
      if (file == undefined)
        ers['file'] = ['Selezionare un file'];
      if (this.tipoDocumentoSelezionato == null)
        ers['tipologia'] = ['Selezionare una tipologia documento'];
      this.docErrors = ers;
      if (Object.keys(ers).length > 0)
        return;
      var nome = file.name;
      if (this.nomeDocumento != '')
        nome = this.nomeDocumento;
      this.files.push({
        nome: nome,
        file: file,
        tipologia: this.tipologieDocumentoImmobiliare.filter((x) => x.id == this.tipoDocumentoSelezionato)[0].nome,
      });
      $(this.$refs.modalDocumento).modal('hide');
      this.refreshTable();
    },
    refreshTable () {
      this.tableDocumenti.clear();
      this.files.forEach(function (file) {
        this.tableDocumenti.row.add([
          file.nome,
          file.tipologia,
          '',
        ]);
      }.bind(this));
      this.tableDocumenti.draw();
    },
    modalDocumentoClosed () {
      this.$refs.fileDocumento.value = "";
      this.tipoDocumentoSelezionato = null;
      this.nomeDocumento = "";
    },
    formInputClassDoc (attr) {
      return { 'form-control': true, 'is-invalid': this.docErrors[attr] !== undefined };
    },
    __(word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    titolo () {
      if (this.title)
        return this.title;
      else
        return this.__('translation.lista_documenti_ticket') ;
    },
  },
}
</script>
