<template>
  <div class="container">
    <div class="row">
      <h2 class="col-12 col-sm-3">
        <a class="btn btn-secondary w-100 w-sm-auto mb-2" :class="{ 'selected': !filtraVendita&&!filtraAffitto&&!filtraPr }" :href="linkVenditaAffitto">Tutte le inserzioni</a>
      </h2>
      <h2 class="col-12 col-sm-3">
        <a class="btn btn-secondary w-100 w-sm-auto mb-2" :class="{ 'selected': filtraVendita }" :href="linkVendita">Inserzioni di Vendita</a>
      </h2>
      <h2 class="col-12 col-sm-3">
        <a class="btn btn-secondary w-100 w-sm-auto mb-2" :class="{ 'selected': filtraAffitto }" :href="linkAffitto">Inserzioni di Affitto</a>
      </h2>
      <h2 class="col-12 col-sm-3">
        <a class="btn btn-secondary w-100 w-sm-auto mb-2" :class="{ 'selected': filtraPr }" :href="linkPr">Inserzioni Patto e Riscatto</a>
      </h2>
      <div class="col-12 col-sm-6">
        <filtro-citta ref="filtroCitta"></filtro-citta>
      </div>
      <div class="col-12 col-sm-2 mb-3">
        <label for="filtra" class="d-none d-sm-flex">&nbsp;</label>
        <button class="btn btn-secondary form-control" @click="filtra">Filtra</button>
      </div>
      <div v-for="ann in annunci" :key="ann.id" class="col-12 mb-2 annuncio">
        <a class="row link" :href="'/annunci/'+ann.id+'/singolo'">
          <div class="col-12 col-sm-3 px-0 imgAnnuncio text-center">
            <div class="ribbon-wrapper ribbon-lg">
              <div class="ribbon bg-primary" v-if="ann.tipologia.nome == 'Affitto'">AFFITTASI</div>
              <div class="ribbon bg-secondary" v-if="ann.tipologia.nome == 'Vendita'">VENDESI</div>
              <div class="ribbon bg-secondary" v-if="ann.tipologia.nome == 'Patto e Riscatto'">PATTO E RISCATTO</div>
            </div>
            <img :src="'/foto/'+ann.unita_condominiale.foto[0].id" class="thumbnail" v-if="ann.unita_condominiale.foto.length > 0">
            <img src="/img/img_missing.jpg" class="thumbnail" v-else>
          </div>
          <!-- Grafica Desktop -->
          <div class="col-12 col-sm-9 d-none d-sm-block">
            <h4 class="titolo">{{ann.titolo}}</h4>
            <h3 class="dettagli d-inline-flex">
              <div class="prezzo">€ {{ thousandsSeparator(ann.prezzo) }}</div>
              <div class="superficie">
                <p>{{ Math.floor(Number(ann.unita_condominiale.superficie))}} m²</p>
                <p>Superficie</p>
              </div>
              <div class="numero_locali">
                <p>{{ann.unita_condominiale.numero_locali}} <i class="mdi mdi-floor-plan"></i></p>
                <p>Locali</p>
              </div>
              <div class="numero_bagni">
                <p>{{ann.unita_condominiale.numero_bagni}} <i class="mdi mdi-paper-roll"></i></p>
                <p>Bagni</p>
              </div>
              <div class="piano">
                <p>{{ann.unita_condominiale.piano}}° <i class="mdi mdi-stairs"></i></p>
                <p>Piano</p>
              </div>
            </h3>
            <h4 class="nome">{{ann.unita_condominiale.nome}}</h4>
            <h5 class="descrizione">{{ann.descrizione.substring(0, 200)}}... <a class="titolo" :href="'/annunci/'+ann.id+'/singolo'">Mostra inserzione completa</a></h5>
          </div>
          <!-- Grafica Mobile -->
          <div class="col-12 d-sm-none row">
            <h4 class="titolo col-12 p-2 mb-0">{{ann.titolo}}</h4>
            <h4 class="prezzo col-12 p-2 mb-0">€ {{ann.prezzo}}</h4>
            <div class="col-3 superficie">
              <p>{{ Math.floor(ann.unita_condominiale.superficie)}} m²</p>
              <p>Superficie</p>
            </div>
            <div class="col-3 numero_locali">
              <p>{{ann.unita_condominiale.numero_locali}} <i class="mdi mdi-floor-plan"></i></p>
              <p>Locali</p>
            </div>
            <div class="col-3 numero_bagni">
              <p>{{ann.unita_condominiale.numero_bagni}} <i class="mdi mdi-paper-roll"></i></p>
              <p>Bagni</p>
            </div>
            <div class="col-3 piano">
              <p>{{ann.unita_condominiale.piano}}° <i class="mdi mdi-stairs"></i></p>
              <p>Piano</p>
            </div>
          </div>
        </a>
      </div>
    </div>
    <nav>
      <ul class="pagination">
        <li :class="{'page-item': true, 'disabled': current_page == 0}"><a class="page-link" :href="linkPage(Number(current_page)-1)">Precedente</a></li>
        <li v-for="i in pages" :key="i" :class="{'page-item': true, 'active': i == current_page}">
          <a class="page-link" :href="linkPage(i)">{{ i+1 }}</a>
        </li>
        <li :class="{'page-item': true, 'disabled': current_page == last_page}"><a class="page-link" :href="linkPage(Number(current_page)+1)">Seguente</a></li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: ['filtraVendita', 'filtraAffitto', 'filtraPr', 'filtraCitta', 'filtraProvincia', 'page'],
  data () {
    return {
      annunci: [],
      total_pages: 0,
      last_page: 0,
      current_page: 0,
    };
  },
  mounted () {
    this.updateAnnunci();
  },
  methods: {
    thousandsSeparator(x) {
      var f = function (val) {
        if (val.length > 3)
          return f(val.slice(0,-3))+','+val.slice(-3);
        return val;
      }
      var int_part = Math.trunc(x);
      var float_part = x-Math.trunc(x);
      float_part = float_part.toFixed(2).split('.')[1]
      var s_int_part = f(String(int_part));
      return s_int_part + '.' + float_part;
     //  return x.toString().replace(".",",").replace(/\B(?<!,\d*)(?=(\d{3})+(?!\d))/g, ".");
    },
    updateAnnunci () {
      $.get('/annunci/lista/json', this.urlData, function (response) {
        this.annunci = response.annunci;
        this.total_pages = response.total_pages;
        this.last_page = response.last_page;
        this.current_page = response.current_page;
      }.bind(this));
    },
    linkPage (i) {
      var data = {page: i};
      if (this.filtraVendita)
        data['vendita'] = 1;
      if (this.filtraAffitto)
        data['affitto'] = 1;
      if (this.filtraPr)
        data['pr'] = 1;
      if (this.filtraCitta != '')
        data['citta'] = this.filtraCitta;
      if (this.filtraProvincia != '')
        data['provincia'] = this.filtraProvincia;
      return this.creaUrl(data);
    },
    filtra () {
      var data = {};
      var provincia = this.$refs.filtroCitta.provincia;
      var citta = this.$refs.filtroCitta.citta;
      if (this.filtraVendita)
        data['vendita'] = 1;
      if (this.filtraAffitto)
        data['affitto'] = 1;
      if (this.filtraPr)
        data['pr'] = 1;
      if (provincia != '')
        data['provincia'] = provincia;
      if (citta != '')
        data['citta'] = citta;
      window.location.href = this.creaUrl(data);
    },
    creaUrl (parametri) {
      var p = [];
      Object.keys(parametri).forEach(function (key) {
        p.push(encodeURIComponent(key) + '=' + encodeURIComponent(parametri[key]));
      });
      var str = p.join('&');
      if (p.length > 0)
        return '/annunci?' + str;
      else
        return '/annunci';
    },
  },
  computed: {
    urlData () {
      var data = {page: this.page};
      if (this.filtraVendita)
        data['vendita'] = 1;
      if (this.filtraAffitto)
        data['affitto'] = 1;
      if (this.filtraPr)
        data['pr'] = 1;
      if (this.filtraCitta != '')
        data['citta'] = this.filtraCitta;
      if (this.filtraProvincia != '')
        data['provincia'] = this.filtraProvincia;

      return data;
    },
    pages () {
      var ps = [];
      for (var i = 0; i < this.total_pages; i++)
        ps.push(i);
      return ps;
    },
    linkVendita () {
      var data = {};
      data['vendita'] = 1;
      if (this.filtraCitta != '')
        data['citta'] = this.filtraCitta;
      if (this.filtraProvincia != '')
        data['provincia'] = this.filtraProvincia;
      return this.creaUrl(data);
    },
    linkAffitto () {
      var data = {};
      data['affitto'] = 1;
      if (this.filtraCitta != '')
        data['citta'] = this.filtraCitta;
      if (this.filtraProvincia != '')
        data['provincia'] = this.filtraProvincia;
      return this.creaUrl(data);
    },
    linkPr () {
      var data = {};
      data['pr'] = 1;
      if (this.filtraCitta != '')
        data['citta'] = this.filtraCitta;
      if (this.filtraProvincia != '')
        data['provincia'] = this.filtraProvincia;
      return this.creaUrl(data);
    },
    linkVenditaAffitto () {
      var data = {};
      if (this.filtraCitta != '')
        data['citta'] = this.filtraCitta;
      if (this.filtraProvincia != '')
        data['provincia'] = this.filtraProvincia;
      return this.creaUrl(data);
    },
  },
}
</script>
