<template>
  <div class="container">
    <h1 class="m-0 mb-3">Lista Ticket</h1>
    <div class="row">
      <div class="col-md-4 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-alert-circle"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Ticket Aperti</span>
            <span class="info-box-number">{{ aperti }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-check-circle"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Ticket risolti</span>
            <span class="info-box-number">{{ chiusi }}</span>
          </div>
        </div>
      </div>
    </div>

    <table class="table table-striped dataTable" id="dataTable" ref="dataTable">
      <thead>
        <tr>
          <th>Numero Ticket</th>
          <th>Condominio</th>
          <th>Unita Condominiale</th>
          <th>Creatore</th>
          <th>Titolo</th>
          <th>Facility</th>
          <th>Stato</th>
          <th>Collaboratore</th>
          <th>Creato il</th>
          <th>Di Condominio</th>
          <th>Risposte</th>
          <th>Note</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      aperti: 0,
      chiusi: 0,
      dataTable: null,
      tickets: null,
      translations: null,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
    $.ajax({
      url: '/ticket/lista/json',
      method: 'get',
      async: false,
      success: function (response) {
        this.tickets = response.tickets;
      }.bind(this),
    });
  },
  mounted () {
    // $.fn.dataTable.moment('DD-MM-YYYY');
    this.dataTable = $(this.$refs.dataTable).DataTable( {
      language: {
        url: '/js/dataTables/Italian.json',
        searchPlaceholder: 'Cerca...',
        buttons: { colvis: 'Colonne'},
      },
      dom: '<".row"<"#buttons.col-sm-4 mt-2 col-12 mt-sm-0"B><"#filter.col-sm-4 col-12 pr-sm-4"f><"#pagination.col-sm-4 col-12 mt-sm-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
      buttons: ['colvis','excel','csv'], //Buttons
      "responsive": true,
      "ordering": true,
      "searching": true,
      "bLengthChange": false,
      "pageLength": 50
    });
    this.dataTable.on('search.dt', function () {
      this.updateTotali();
    }.bind(this));
    this.updateTicket();
  },
  methods: {
    updateTicket () {
      this.tickets.forEach(function (ticket) {
        var titoloLink = `
        <a href="/ticket/${ticket.id}/singolo" target="_blank"><i class="mdi mdi-arrow-right-thick"></i> ${ticket.titolo}</a>
        `;
        var collaboratore = '';
        if (ticket.collaboratore)
        {
          collaboratore = `
          <a href="/collaboratore/${ticket.collaboratore.id}/singolo" target="_blank">${ticket.collaboratore.preferred_name}</a>
          `;
        }
        var linkCreatore = `
        <a href="/utente/${ticket.creatore.id}/singolo" target="_blank">${ticket.creatore.preferred_name}</a>
        `;
        var linkDestinatario = `
        <a href="/utente/${ticket.destinatario.id}/singolo" target="_blank">${ticket.destinatario.preferred_name}</a>
        `;
        var linkCondominio = `
        <a href="/condominio/${ticket.unita_condominiale.condominio.id}/singolo" target="_blank">${ticket.unita_condominiale.condominio.nome}</a>
        `;
        var linkUnita = `
        <a href="/unitaCondominiale/${ticket.unita_condominiale.id}/singolo" target="_blank">${ticket.unita_condominiale.nome}</a>
        `;
        this.dataTable.row.add([
          ticket.id,
          linkCondominio,
          linkUnita,
          linkCreatore,
          titoloLink,
          linkDestinatario,
          ticket.stato.nome,
          collaboratore,
          moment(String(ticket.created_at)).format('DD-MM-YYYY'),
          ticket.di_condominio == 0 ? 'No' : 'Sì',
          ticket.posts.length,
          ticket.note,
        ]);
      }.bind(this));
    },
    updateTotali () {
      var rows = this.dataTable.rows({filter:'applied'}).data();
      var aperti = 0;
      var chiusi = 0;
      rows.each(function (row) {
        if (row[6] == 'Aperta')
          aperti += 1;
        if (row[6] == 'Chiusa')
          chiusi += 1;
      });
      this.aperti = aperti;
      this.chiusi = chiusi;
    },
    __ (word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
}
</script>
