<template>
  <div class="container">
    <modale-caricamento ref="modaleCaricamento"></modale-caricamento>
    <div class="row">
      <h1 class="m-0 text-dark my-3">Pagamenti - Affitti</h1>
    </div>
    <div class="row">
      <div class="col-md-4 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-home"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Affitti Pagati</span>
            <span class="info-box-number">{{ (totale_pagato).toFixed(2) }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4 p-1">
        <div class="info-box bg-primary">
          <span class="info-box-icon"><i class="mdi mdi-home-variant-outline"></i></span>
          <div class="info-box-content">
            <span class="info-box-text">Affitti non pagati</span>
            <span class="info-box-number">{{ (totale - totale_pagato).toFixed(2) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div>
    <!-- Datatable documenti condomini -->
    <table class="table table-striped dataTable" ref="tablePagamenti">
      <thead>
        <tr>
          <th>Destinatario</th>
          <th>Progetto</th>
          <th>Numero Documento</th>
          <th>Data</th>
          <th>Scadenza</th>
          <th>Imponibile IVA</th>
          <th>Imposta IVA</th>
          <th>Importo</th>
          <th>Pagato</th>
          <th>Data Pagamento</th>
          <th>Importo Pagato</th>
          <th>Azioni</th>
          <!--<td>Descrizione</td>-->
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th>Totale</th>
          <th>Totale Imponibile IVA</th>
          <th>Totale Imposta IVA</th>
          <th>Totale Pagato</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ totale.toFixed(2) }}€</td>
          <td>{{ totale_imponibile.toFixed(2) }}€</td>
          <td>{{ totale_imposta.toFixed(2) }}€</td>
          <td>{{ totale_pagato.toFixed(2) }}€</td>
        </tr>
      </tbody>
    </table>
    <!--
      -totale: {{ totale.toFixed(2) }}
      -<br>
      -totale_imponibile: {{ totale_imponibile.toFixed(2) }}
      -<br>
      -totale_imposta: {{ totale_imposta.toFixed(2) }}
      -<br>
      -totale_pagato: {{ totale_pagato.toFixed(2) }}
      -->
    <!-- /Datatable documenti condomini -->
  </div>
</template>
<script>
export default {
  props: ['token', 'role', 'filter', 'id_ruolo_utente'],
  data () {
    return {
      tablePagamenti: null,
      pagamenti: [],
      translations: null,
      totale: 0,
      totale_imponibile: 0,
      totale_imposta: 0,
      totale_pagato: 0,
    };
  },
  beforeMount () {
    $.get('/translations', function (response) {
      this.translations = response;
    }.bind(this));
  },
  mounted () {
    $.fn.dataTable.moment('DD-MM-YYYY');
    $.fn.dataTable.ext.pager.numbers_length = 5;
    this.tablePagamenti = $(this.$refs.tablePagamenti).DataTable( {
        language: {
          url: '/js/dataTables/Italian.json',
          searchPlaceholder: 'Cerca...',
          buttons: { colvis: 'Colonne'}
        },
        dom: '<".row"<"#buttons.col-lg-3 mt-2 col-md-6 col-12 mt-sm-0"B><"#filter.col-lg-4 col-md-6 col-12 pr-sm-4"f><"#pagination.col-sm-12 col-12 col-lg-5 mt-md-3 mt-lg-0"p><"#table.col-12"t><"#length"l><"#information"i>>',
        buttons: ['colvis','excel','csv'], //Buttons
        "responsive": true,
        "ordering": true,
        "searching": true,
        "bLengthChange": false,
        "pageLength": 50
    });
    this.tablePagamenti.on('search.dt', function () {
      $('button.disable-pagamento').on('click', function (e) {
        this.disablePagamento($(e.target).data('id'));
      }.bind(this));
      this.updateTotali();
    }.bind(this));
    if (this.role == 'user')
      var url = '/pagamento/lista/safejson';
    else if (this.filter !== undefined)
      var url = '/pagamento/lista/json/'+this.filter;
    else
      var url = '/pagamento/lista/json';
    this.$refs.modaleCaricamento.apri();
    $.get(url, function (response) {
      this.pagamenti = response.pagamenti;
      this.updateTablePagamenti();
    }.bind(this)).always(function () {
      setTimeout(function () {
        this.$refs.modaleCaricamento.chiudi();
        console.log('chiudi');
      }.bind(this), 1000);
    }.bind(this));
  },
  methods: {
    updateTotali () {
      console.log('update');
      var rows = this.tablePagamenti.rows({filter:'applied'}).data();
      var totale = 0;
      var totale_imponibile = 0;
      var totale_imposta = 0;
      var totale_pagato = 0;
      rows.each(function (row) {
        totale_imponibile += Number(row[5].substring(0, row[5].length-1));
        totale_imposta += Number(row[6].substring(0, row[6].length-1));
        totale += Number(row[7].substring(0, row[7].length-1));
        totale_pagato += Number(row[10].substring(0, row[10].length-1));
      }.bind(this));

      this.totale_imponibile = totale_imponibile;
      this.totale_imposta = totale_imposta;
      this.totale = totale;
      this.totale_pagato = totale_pagato;
    },
    updateTablePagamenti () {
      this.tablePagamenti.clear();
      this.pagamenti.forEach(function (pagamento) {
        if (!pagamento.destinatario)
          return;
        var linkNumeroDocumento = `
        <a href="/pagamento/${pagamento.id}/singolo" target="_blank">${pagamento.numero_documento}</a>
        `;
        var linkDestinatario = `
        <a href="/utente/${pagamento.destinatario.id}/singolo" target="_blank">${pagamento.destinatario.preferred_name}</a>
        `;
        var data_pagato = '';
        if (pagamento.data_pagato)
          data_pagato = moment(pagamento.data_pagato).format('DD-MM-YYYY');
        var progetto = '';
        if (pagamento.progetto)
          progetto = pagamento.progetto;

        var pagato = '';
        if (pagamento.pagato)
          pagato = 'Sì';
        else {
          if (pagamento.acconto1 > 0)
            pagato = 'Parziale';
          else
            pagato = 'No';
        }
        var importo_pagato = '';
        if (pagamento.pagato)
          importo_pagato = Number(pagamento.importo).toFixed(2);
        else {
          importo_pagato = Number(pagamento.acconto1)+Number(pagamento.acconto2)+Number(pagamento.acconto3)+Number(pagamento.acconto4);
          importo_pagato = importo_pagato.toFixed(2);
        }

        var azioni = '';
        if (this.canDelete) {
          azioni = `
          <button type="button" class="btn btn-secondary px-1 py-0 mdi mdi-delete-forever disable-pagamento" data-id="${pagamento.id}"></button>
          `;
        }
        this.tablePagamenti.row.add([
          linkDestinatario,
          progetto,
          linkNumeroDocumento,
          moment(pagamento.data).format('DD-MM-YYYY'),
          moment(pagamento.scadenza).format('DD-MM-YYYY'),
          Number(pagamento.imponibile_iva).toFixed(2)+"€",
          Number(pagamento.imposta_iva).toFixed(2)+"€",
          Number(pagamento.importo).toFixed(2)+"€",
          pagato,
          data_pagato,
          importo_pagato+"€",
          azioni,
        ]);
        /*
          <td>Destinatario</td>
          <td>Progetto</td>
          <td>Numero Documento</td>
          <td>Data</td>
          <td>Scadenza</td>
          <td>Imponibile IVA</td>
          <td>Imposta IVA</td>
          <td>Importo</td>
          <td>Pagato</td>
          <td>Data Pagamento</td>
          <td>Importo Pagato</td>
          <!--<td>Descrizione</td>-->
         */
      }.bind(this));
      this.tablePagamenti.draw();
      $('button.disable-pagamento').on('click', function (e) {
        this.disablePagamento($(e.target).data('id'));
      }.bind(this));
    },
    disablePagamento (id) {
      if (confirm("Sicuro di voler eliminare il pagamento?")) {
        $.post(`/pagamento/${id}/disabilita`, {_token: this.token}, function () {
          window.location.reload();
        });
      }
    },
    __(word) {
      if (!this.translations)
        return;
      // Divido la parola nel gruppo e nella parola cercata
      var spl = word.split('.');
      var group = spl[0];
      var item = spl.slice(1).join('.');
      var group = this.translations[group];
      if (group) {
        var tr = group[item]
        if (tr)
          return tr;
      }
      return word;
    },
  },
  computed: {
    canDelete () {
      return [1,6].includes(this.id_ruolo_utente);
    },
  },
  watch: {
  },
}
</script>
